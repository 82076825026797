import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import controlPanelSettings from './../store/reducers/ControlPanel/reducer-settings';
import config from '../store/reducers/reducer-config';
import modal from '../store/reducers/reducer-modals';
import builder from '../store/reducers/reducer-builder';
import cache from '../store/reducers/reducer-cache';
import productsManagement from '../store/reducers/reducer-products-mangement';
import customerManagement from '../store/reducers/reducer-customers';
import orderManagement from '../store/reducers/reducer-orders';
import usersManagement from '../store/reducers/ControlPanel/reducer-users';
import groupsManagement from '../store/reducers/ControlPanel/reducer-groups';

// we'll be using this setup to use an advanced setup for Redux Dev Tools
// [scroll to "advanced store setup"] https://github.com/zalmoxisus/redux-devtools-extension
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	// Optionally pass options listed below
});

const reducers = combineReducers({
	modal,
	builder,
	config,
	cache,
	controlPanelSettings,
	productsManagement,
	customerManagement,
	orderManagement,
	usersManagement,
	groupsManagement
});

// we will be using redux-thunk as our middleware to handle async requests
const store = createStore(
	reducers,
	composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer)
);

// eslint-disable-next-line @typescript-eslint/naming-convention
const withRedux = <T extends object>(WrappedComponent: React.ComponentType<T>) => {
	const Wrapped: React.ComponentType<T> = (props) => {
		return (
			<Provider
				store={store}
				// this adds development-mode safety checks for common errors
				noopCheck="always"
			>
				<WrappedComponent {...props} />
			</Provider>
		);
	};
	return Wrapped;
};

export default withRedux;
