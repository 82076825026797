import { SET_CMS_GROUPS } from './../actionTypes';
import { SetGroupsAction } from './../../types/ControlPanel/GroupsTypes';
import { Group } from '../../../containers/ControlPanel/Groups/model.Groups';

/**
 * Will trigger the storing of all groups in state.
 *
 * @param {Group[]} groups An array with all groups objects to store.
 * @returns {SetGroupsAction}
 */
export const setGroups = (groups: Group[]): SetGroupsAction => {
	return {
		type: SET_CMS_GROUPS,
		groups,
	};
};
