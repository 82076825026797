/* eslint-disable no-unused-vars */
import { BlockFormItem, BlockFormItems, RootCallback, ItemCallback, SubItemCallback } from '../BlockForm.types';

const useFormStructure = (items: BlockFormItems | undefined, cbRoot: RootCallback, cbItem: ItemCallback, cbSubItem?: SubItemCallback) => {

	/**
     * Creates the root container of the structure
     * 
     * @returns {JSX.Element}
     */
	const createRoot = () => {
		if(items) {
			const root = items.root;
			const rootItems = root.children;
			const formItems = createItems(rootItems, 'root');
    
			return cbRoot(root, formItems);
		}
		
	};

	/**
     * Creates the individual items of the structure
     * 
     * @param {string[]} itemKeys 
     * @param {string} parent 
     * @returns {JSX.Element[] | undefined}
     */
	const createItems = (itemKeys: string[], parentKey: string): JSX.Element[] | undefined => {
		if(items) {
			return itemKeys.map((itemKey, index) => {
				const item = items[itemKey];
				const parentItem = items[parentKey];
                
				const children = createSubItem(item, item.children, parentKey);
    
				return cbItem(item, index, parentItem, children);	
			});
		}

		return undefined;
	};

	/**
     * Creates a subitem, could be used to create nested items
     * 
     * @param {BlockFormItem} item 
     * @param {string} children 
     * @param {string} parent 
     * @returns {JSX.Element | undefined} 
     */
	const createSubItem = (item: BlockFormItem, children: string[], _parent: string) => {

		if(!children.length) return undefined;

		const itemChildren = createItems(children, item.key);

		if(cbSubItem) {
			return cbSubItem(item, itemChildren, item.key);
		} 

		return itemChildren;
	};

	/**
     * Alias function for "createRoot".
     * Exists only to give a clerar API usage
     * 
     * @returns {JSX.Element | undefined}
     */
	const buildForm = () => {
		return createRoot();
	};

	return {
		buildForm,
		createItems
	};
};

export default useFormStructure;