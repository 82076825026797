import { ReactNode } from 'react';
import { Cell } from 'react-table';
import { Group } from './model.Groups';

/**
 *
 * Generates a TableCell with custom content.
 *
 * @param cell 			The cell data to work on.
 * @returns {TableCell}
 */
export const renderGroupsTableCellContent = (cell: Cell<Group>) => {
	let cellContent: string | ReactNode = '';

	// id is the same as 'accessor' when defining table columns
	switch(cell.column.id) {
		default:
			cellContent = cell.render('Cell');
			break;
	}

	return cellContent;
};
