import axios from '../../../utils/oc-axios';
import { AjaxResponse, ThunkDispatch } from '../../types/ThunkTypes';
import { Group } from '../../../containers/ControlPanel/Groups/model.Groups';
import { SetGroupsAction } from '../../types/ControlPanel/GroupsTypes';
import { setGroups } from '../../actions/ControlPanel/action-groups';
import { SortOrderType } from '../../../definitions/Sorting';

/**
 * Fetches all groups that matches filters.
 *
 * @param {string} searchText	A text to search groups for.
 * @returns {Promise<Group[]}
 */
export const fetchGroups = (
	searchText: string, 
	sortBy?: string,
	sortDirection: SortOrderType = 'asc',
	paginationIndex: number = 0,
	paginationSize: number = 10
) => {
	return async (dispatch: ThunkDispatch<SetGroupsAction>) => {
		return new Promise<Group[]>((resolve, reject) => {
			axios
				.get('modules/groups', {
					params: {
						needle: searchText,
						sort: sortBy,
						sort_direction: sortDirection
					}
				})

				.then((resp: AjaxResponse<Group[]>) => {
					let groups = resp.data;

					if(!Array.isArray(groups)) {
						groups = [];
					}

					dispatch(setGroups(groups));
					resolve(groups);
				})

				.catch((err) => {
					console.error('[thunk-groups]', err);
					reject(err);
				});
		});
	};
};

/**
 * Fetches a specific group and stores it in state.
 *
 * @param {number} groupID The id of the group to fetch.
 * @returns {Promise<User>}
 */
// export const fetchGroup = (groupID: number) => {
// 	return async (dispatch: ThunkDispatch<SetGroupAction>) => {
// 		return new Promise<Group>((resolve, reject) => {
// 			axios
// 				.get(`modules/groups/${groupID}`)
// 				.then((resp: AjaxResponse<Group>) => {
// 					dispatch(setGroup(resp.data));
// 					resolve(resp.data);
// 				})
// 				.catch((err) => {
// 					console.error('[thunk-groups]', err);
// 					reject(err);
// 				});
// 		});
// 	};
// };
