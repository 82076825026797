// Cache

// Pages
export const CACHE_PAGE_SAVE = 'CACHE_PAGE_SAVE';
export const CACHE_PAGE_DUPLICATE = 'CACHE_PAGE_DUPLICATE';
export const CACHE_PAGE_REMOVE = 'CACHE_PAGE_REMOVE';

// Tree
export const CACHE_NAVIGATION_POPULATE = 'CACHE_NAVIGATION_POPULATE';
export const CACHE_NAVIGATION_UPDATE = 'CACHE_NAVIGATION_UPDATE';
export const CACHE_NAVIGATION_DUPLICATE = 'CACHE_NAVIGATION_DUPLICATE';
export const CACHE_NAVIGATION_REMOVE = 'CACHE_NAVIGATION_REMOVE';
export const CACHE_NAVIGATION_FOLDER_DUPLICATE =
	'CACHE_NAVIGATION_FOLDER_DUPLICATE';

export const CACHE_NAVIGATION_SET_SELECTED = 'CACHE_NAVIGATION_SET_SELECTED';
export const CACHE_NAVIGATION_CLEAN_SELECTED =
	'CACHE_NAVIGATION_CLEAN_SELECTED';

// Templates
export const CACHE_TEMPLATES_POPULATE = 'CACHE_TEMPLATES_POPULATE';

// Navigation types
export const CACHE_NAVIGATION_TYPES_POPULATE =
	'CACHE_NAVIGATION_TYPES_POPULATE';

// Modals
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_MODAL_CONFIG = 'SET_MODAL_CONFIG';
export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE';

// Config
export const CONFIG_BUILDER_SET_VIEW_VARIANT =
	'CONFIG_BUILDER_SET_VIEW_VARIANT';
export const CONFIG_BUILDER_TOGGLE_VISIBILITY_VARIANT_ROWS =
	'CONFIG_BUILDER_TOGGLE_VISIBILITY_VARIANT_ROWS';
export const CONFIG_MEDIAARCHIVE_SET_SELECTED_FOLDER =
	'CONFIG_MEDIAARCHIVE_SET_SELECTED_FOLDER';

// Builder
export const BUILDER_UPDATE_SAVEHASH = 'BUILDER_UPDATE_SAVEHASH';
export const SET_BUILDER_INITIAL_STATE = 'SET_BUILDER_INITIAL_STATE';
export const BUILDER_CLEAN_STATE = 'BUILDER_CLEAN_STATE';
export const SET_PAGE_SETTINGS = 'SET_PAGE_SETTINGS';
export const UPDATE_BLOCK_CONTENT = 'UPDATE_BLOCK_CONTENT';
export const UPDATE_TEXT_CONTENT = 'UPDATE_TEXT_CONTENT';
export const CREATE_ROW_SET_TO_AREA = 'CREATE_ROW_SET_TO_AREA';
export const CREATE_ROW_SET_TO_EMPTY_AREA = 'CREATE_ROW_SET_TO_EMPTY_AREA';
export const CREATE_BLOCK_IN_COLUMN = 'CREATE_BLOCK_IN_COLUMN';
export const DELETE_BLOCK = 'DELETE_BLOCK';
export const CHANGE_COLUMN_WIDTH = 'CHANGE_COLUMN_WIDTH';
export const SET_ROW_VISIBILITY_SETTINGS = 'SET_ROW_VISIBILITY_SETTINGS';
export const SET_ROW_WIDTH_SETTINGS = 'SET_ROW_WIDTH_SETTINGS';
export const SET_ROW_REVERSE_SETTINGS = 'SET_ROW_REVERSE_SETTINGS';

// Builder DND
export const DND_CREATE_ROW_SET_TO_AREA = 'DND_CREATE_ROW_SET_TO_AREA';
export const DND_CREATE_COLUMN_SET_TO_ROW = 'DND_CREATE_COLUMN_SET_TO_ROW';
export const DND_MOVE_BLOCK_WITHIN_ANY_COLUMN =
	'DND_MOVE_BLOCK_WITHIN_ANY_COLUMN';
export const DND_MOVE_ROW_VERTICALLY = 'DND_MOVE_ROW_VERTICALLY';

// ControlPanel > Users
export const SET_LISTITEM_USERS = 'SET_LISTITEM_USERS';
export const SET_USER = 'SET_USER';

// ControlPanel > Groups
export const SET_CMS_GROUPS = 'SET_CMS_GROUPS';

// ControlPanel > Settings
export enum ControlPanelSettingsActions {
	SET_ALL_SETTINGS = 'SET_ALL_SETTINGS',
	SET_WEBPAGE_TITLE = 'SET_WEBPAGE_TITLE',
	SET_WEBPAGE_SLOGAN = 'SET_WEBPAGE_SLOGAN',
	SET_WEBPAGE_HIDE_SEARCHENGINE = 'SET_WEBPAGE_HIDE_SEARCHENGINE',
	SET_WEBPAGE_404PAGE = 'SET_WEBPAGE_404PAGE',
	SET_WEBPAGE_BACKGROUND_IMAGE = 'SET_WEBPAGE_BACKGROUND_IMAGE',
	SET_WEBPAGE_CATALOG_IMAGE = 'SET_WEBPAGE_CATALOG_IMAGE',
	SET_COMPANY_INFO = 'SET_COMPANY_INFO',

	ADD_CONTACT_METHOD = 'ADD_CONTACT_METHOD',
	REMOVE_CONTACT_METHOD = 'REMOVE_CONTACT_METHOD',
}

// Modules > Products
export const SET_CATEGORIES_TREE = 'SET_CATEGORIES_TREE';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const DESELECT_PRODUCT = 'DESELECT_PRODUCT';
export const DESELECT_ALL = 'DESELECT_ALL';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_PRODUCT_AND_CATEGORIES = 'SELECT_PRODUCT_AND_CATEGORIES';
export const SELECT_PRODUCT_AND_DESELECT_CATEGORIES =
	'SELECT_PRODUCT_AND_DESELECT_CATEGORIES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT = 'SET_PRODUCT';
export const APPEND_PRODUCTS = 'APPEND_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const CLEAR_EDIT_PRODUCT = 'CLEAR_EDIT_PRODUCT';
export const APPEND_PRODUCTS_CATEGORIES = 'PATCH_PRODUCTS_CATEGORIES';
export const MARK_PRODUCT_AS_READY = 'MARK_PRODUCT_AS_READY';
export const CLEAR_PRODUCT_STATE = 'CLEAR_PRODUCT_STATE';

export const SET_FILTERED_PRODUCTS = 'SET_FILTERED_PRODUCTS';
export const CLEAR_FILTERED_PRODUCTS = 'CLEAR_FILTERED_PRODUCTS';

// Modules > Customers
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const CLEAR_FILTERED_CUSTOMERS = 'CLEAR_FILTERED_CUSTOMERS';
export const SET_FILTERED_CUSTOMERS = 'SET_FILTERED_CUSTOMERS';
export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';

// Modules > Orders
export const SET_LISTITEM_ORDERS = 'SET_LISTITEM_ORDERS';
export const SET_ORDER = 'SET_ORDER';
