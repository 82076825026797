import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProps } from './model.Icon';

const Icon: React.FC<IconProps> = (props) => {
	return (
		<FontAwesomeIcon
			icon={props.icon}
			color={props.color}
			onClick={props.onClick}
			fixedWidth={props.fixedWidth}
			size={props.size}
			spin={props.spin}
			pulse={props.pulse}
			flip={props.flip}
			rotation={props.rotation}
			className={props.className}
			style={props.style}
		/>
	);
};

export default Icon;
