import React from 'react';
import styled from 'styled-components/macro';
import { LabelProps } from './model.Label';
import Message from '../../UI/Message/Message';

const Label: React.FC<LabelProps> = (props) => {
	const label = props.label && (
		<ScLabelContainer
			style={props.style}
			className={props.className}
		>
			{props.isRequired && (
				<ScRequired>
					*
				</ScRequired>
			)}
			{/* <ScLabelText type={props.type}>{props.label}</ScLabelText> */}
			<ScLabelText>
				{props.label}
			</ScLabelText>
		</ScLabelContainer>
	);

	const description = props.description && (
		<Message isSmall>
			{props.description}
		</Message>
	);

	return (
		<ScContainer
			style={props.style}
			className={props.className}
		>
			<ScLabelWrapper>
				<div>
					{label}
					{description}
				</div>
				{props.children}
			</ScLabelWrapper>
		</ScContainer>
	);
};

export default Label;

const ScContainer = styled.div`
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const ScLabelWrapper = styled.label`
	cursor: pointer;
	user-select: none;
	display: flex;
	flex-direction: column;
`;

const ScRequired = styled.div`
	color: #d0021b;
	margin-right: 4px;
`;
const ScLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	&:last-child {
		margin-bottom: 8px;
	}
`;

const ScLabelText = styled.div`
	display: block;
	width: 100%;
	font-weight: 400;
	font-size: 14px;
`;
