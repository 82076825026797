import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from '../../../../utils/oc-axios';
import useModal from '../../../../hooks/Modal/useModal';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { SkeletonSettings } from '../../../../components/Skeletons';
import { ModalSettingsContainer, Button, Icon } from '../../../../components/UI';
import { TextInput, CheckItem, Select } from '../../../../components/Forms';
import PublishDateSelector from '../../../../components/UI/PublishDateSelector';
import MainNavigation from '../../../MainNavigation/MainNavigation';
import usePageLanguage from '../../../../hooks/usePageLanguage/usePageLanguage';
import { SUPPORTED_LANGUAGES } from '../../../../settings';
import GeneralNavItemSettings from '../../../../components/GeneralNavItemSettings/GeneralNavItemSettings';

const URITypes = {
	http: {
		label: 'Unik adress (URL)',
		description:
			'Den unika adressen länken ska peka till, öppnas alltid i ny flik',
		validation: 'url',
		placeholder: 'https://exempel.se'
	},
	slug: {
		label: 'Intern sida (Slug)',
		description: 'Intern sida som länken ska peka till',
		validation: 'slug',
		placeholder: 'kontakta-oss'
	},

	email: {
		label: 'E-postadress',
		description: 'Skapa länk av en e-post address',
		validation: 'email',
		placeholder: 'info@exempel.se'
	},
	phone: {
		label: 'Telefonnummer',
		description: 'Skapa länk av telefonnummer',
		validation: 'phone',
		placeholder: '+46 3512345'
	}
};

const generateURITypesOptions = () => {
	return Object.entries(URITypes).map(([key, type]) => (
		<option
			key={`urioption_${key}`}
			value={key}
		>
			{type.label}
		</option>
	));
};

/**
 * @param duplicate			Triggered when wanting to duplicate the navigation item.
 *
 * @param modal				Data comming from the modal, currentState, setState....
 * @param formValidation	useFormvalidation from MainnavigationTree.
 */
const LinkSettings = (props) => {
	const alert = useAlert()[1];

	// This component is intended to be used inside a Modal, data is coming from modal's state
	const { currentState, updateState } = props.modal;

	const linkSelectModal = useModal();
	const openLinkSelectModal = linkSelectModal.open;
	const closeLinkSelectModal = linkSelectModal.close;
	const getAsComponentLinkSelectModal = linkSelectModal.getAsComponent;

	// Returns the active page language
	const { activeLanguage } = usePageLanguage();

	const formValidation = props.formValidation;

	/**
	 * Triggered when publish or unpublish date-time changes.
	 *
	 * @param {string} dateType 		publish_date or unpublish_date depending on what changed
	 * @param {string|null} value 		The new value
	 */
	const publishingChangedHandler = useCallback(
		(propName, value) => {
			const notPublished = propName === 'publish_date' && value === null;
			const changingUnpublishDate = propName === 'unpublish_date';

			// remove the unpublish date from state if the page is not published at all.
			let unpublishValue = null;
			switch(notPublished) {
				case true:
					unpublishValue = null;
					break;

				default:
					unpublishValue = changingUnpublishDate
						? value
						: currentState.unpublish_date;
					break;
			}

			updateState({
				[propName]: {
					$set: value
				},

				// remove the unpublish date from state if the page is not published at all.
				unpublish_date: {
					$set: unpublishValue
				}
			});
		},
		[currentState.unpublish_date, updateState]
	);

	/**
	 * Updates link's uri with the selected page url.
	 */
	const menuItemDoubleClickHandler = useCallback(
		(item) => {
			updateState({
				uri: { $set: `/${item.slug}` }
			});

			closeLinkSelectModal();
		},
		[closeLinkSelectModal, updateState]
	);

	/**
	 * Opens a modal with the MainMenu tree.
	 */
	const openMainMenuSelector = useCallback(() => {
		openLinkSelectModal({
			title: 'Välj mål för länk',
			position: 'right',
			hideBackdrop: false,
			isDismissable: true,
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (originalState, currentState, closeModal) => {
						// Reset builder block settings
						closeModal();
					}
				},
				{
					text: 'Klar',
					action: (originalState, currentState, closeModal) => {
						// Notify builder to update all block's atributes before closing the modal

						closeModal();
					}
				}
			],
			state: {}
		});
	}, [openLinkSelectModal]);

	/**
	 * Handles when a input/select changes it's value
	 */
	const fieldChangedHandler = React.useCallback((ev) => {
		const property = ev.target.name;
		const newValue = ev.target.value;

		// Update the title prop in l10n in order to see the changes 
		// of the title immediately in the tree
		if(property === 'title') {
			updateState({
				[property]: { $set: newValue },
				l10n: {
					[activeLanguage]: {
						[property]: { $set: newValue }
					}
				} 
			});
		}

		updateState({
			[property]: { $set: newValue }
		});
	}, [activeLanguage, updateState]);

	/**
	 * Handles when a input/select changes it's value
	 */
	const URITypeChangedHandler = useCallback(
		(ev) => {
			// reset the valdiation to clean existing valdiation errors.
			formValidation.resetErrors();

			updateState({
				uri_type: { $set: ev.target.value },
				uri: { $set: '' }
			});
		},
		[updateState, formValidation]
	);

	/**
	 * Handles when a checkbox changes it's value
	 */
	const checkboxChangedHandler = useCallback(
		(ev) => {
			updateState({
				[ev.target.name]: { $set: ev.target.checked }
			});
		},
		[updateState]
	);

	/**
	 * Fetch page's data, will remove loader when populated.
	 */
	useEffect(() => {
		if(!currentState.dataFetched) {
			axios
				.get(`navigations/settings/links/${currentState.uuid}`)
				.then(({ data }) => {
				// do some checks and modifications to the link value depending on what type of uri it is
				//   as data from and to abck-end need to be translated.
					let uri = data.uri || '';
					let uri_type = 'http';

					switch(true) {
					// check if the value if of type mail
						case uri.search('mailto:') >= 0:
							uri_type = 'email';
							uri = uri
							// remove uri type from the value
								.replace('mailto:', '');
							break;

						case uri.search('tel:') >= 0:
							uri_type = 'phone';
							uri = uri
							// remove uri type from the value
								.replace('tel:', '');
							break;

						case uri.search('https://') < 0 && uri.search('http://') < 0:
							uri_type = 'slug';

							break;

						default:
							uri_type = 'http';
							break;
					}

					updateState({
						$merge: {
							...data,
							uri: uri,
							uri_type: uri_type,

							dataFetched: true
						}
					});
				});
		}
	}, [currentState, updateState]);

	return (
		<>
			{SUPPORTED_LANGUAGES.length > 0 && (
				<ScLanguage>
					{SUPPORTED_LANGUAGES.find(language => language.code === activeLanguage).name}
				</ScLanguage>
			)}

			{getAsComponentLinkSelectModal(
				<MainNavigation
					itemDoubleClicked={menuItemDoubleClickHandler}
					disabledItemsFilters={[{ property: 'type', value: 'link' }]}
				/>
			)}

			{/* Show a skeleon if no data is set yet...have to wait for useEffect to fetch and populate data */}
			{!currentState.dataFetched ? (
				<SkeletonSettings />
			) : (
				<ModalSettingsContainer>
					<TextInput
						label="Navigationstitel"
						description="Namnet på sidan när den visas i navigationen."
						isRequired
						name="title"
						id="title"
						value={currentState.title}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['title']}
						changed={(ev, ...data) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true
							})}
					/>

					<GeneralNavItemSettings label="Inställningar nedan appliceras på alla översättningar för länken">
						<Select
							label="Typ av länk"
							name="uri_type"
							changed={URITypeChangedHandler}
							value={currentState.uri_type}
						>
							{generateURITypesOptions()}
						</Select>

						<TextInput
							label={URITypes[currentState.uri_type].label}
							description={
							URITypes[currentState.uri_type].description
						}
							placeholder={
							URITypes[currentState.uri_type].placeholder
						}
							isRequired
							name="uri"
							id="uri"
							value={currentState.uri}
							formValidationUnregister={
							formValidation.unregisterElement
						}
							error={formValidation.errors['uri']}
							changed={(ev, ...data) => {
								formValidation.watch(ev, fieldChangedHandler, data);
							}}
							inputRef={(ref) =>
								formValidation.registerElement(ref, {
									required: true,
									validation:
									URITypes[currentState.uri_type].validation
								})}
						/>

						{currentState.uri_type === 'slug' && (
							<>
								<ScButtonContainer>
									<ScButton
										isPrimary
										isSmall
										onClick={openMainMenuSelector}
									>
										Välj i sidträd
									</ScButton>
								</ScButtonContainer>
							</>
						)}

						{(currentState.uri_type === 'slug' ||
						currentState.uri_type === 'http') && (
							<CheckItem
								type="checkbox"
								title="Öppna i ny flik"
								description="Länken öppnas i en ny flik i webbläsaren"
								changed={checkboxChangedHandler}
								name="new_window"
								checked={currentState.new_window}
							/>
						)}

						<PublishDateSelector
							checkboxTitle="Publicera länk"
							publish={currentState.publish_date}
							publishChanged={(newValue) =>
								publishingChangedHandler('publish_date', newValue)}
							unpublish={currentState.unpublish_date}
							unpublishChanged={(newValue) =>
								publishingChangedHandler('unpublish_date', newValue)}
							onError={() =>
								alert('SHOW', {
									priority: AlertPriorityTypes.warn,
									title: 'Publiceringsdatum',
									children:
									'Slut datumet kan inte vara samma eller innan publicerings-datumet'
								})}
						/>

						<Button
							onClick={async (ev) => {
								// TODO
								ev.stopPropagation();
								await props.duplicate({
									...currentState
									//id: navItemId,
								});
								props.modal.close();
							}}
						>
							Duplicera länk
						</Button>
					</GeneralNavItemSettings>
				</ModalSettingsContainer>
			)}
		</>
	);
};

LinkSettings.propTypes = {
	modal: PropTypes.object,
	formValidation: PropTypes.object.isRequired,

	duplicate: PropTypes.func.isRequired
};

export default LinkSettings;

const ScButtonContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;

const ScButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 3px;
`;

const ScInfoText = styled.p`
	font-size: 12px;
	color: #e76677;
	align-items: center;
	display: flex;
	margin-bottom: 16px;
`;

const ScStatusIcon = styled(Icon)`
	margin-right: 8px;
`;

const ScLanguage = styled.p`
	position: absolute;
	z-index: 1000;
	top: 12px;
	right: 16px;
	font-size: 14px;
	font-weight: 300;
	color: grey;
`;