import React from 'react';
import styled from 'styled-components/macro';
import { Cell, HeaderGroup, Row, useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import axios from './../../../utils/oc-axios';
import { Group } from './model.Groups';
import { GROUPS_LIST_COLUMNS } from './consts.Groups';
import { renderGroupsTableCellContent } from './func.Groups';
import GroupDetails from './GroupDetails/GroupDetails';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import Search from '../../../components/Search/Search';
import {
	Table,
	TableCell,
	TableHead,
	TableHeading,
	TableBody,
	TableRow
} from '../../../components/Table/index';
import useModal from '../../../hooks/Modal/useModal';
import { SortingState } from '../../../definitions/Sorting';
import { RootState } from '../../../store/types/RootTypes';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import SkeletonTable from '../../../components/Skeletons/SkeletonTable/SkeletonTable';
import { fetchGroups } from '../../../store/thunks/ControlPanel/thunk-groups';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import useFormValidation from '../../../hooks/useFormValidation/useFormValidation';

/**
 * Filter all groups by a search term.
 *
 * @param {string} searchTerm	The text to search for.
 * @param {Group[]} groups		The groups to make the search on and filter.
 * @returns {Group[]} 			The groups that match the search term.
 */
const filterGroupsBySearchText = (searchTerm: string, groups: Group[]) =>
	groups.filter((group) => {
		// get a lowercase copy of the the string to work on like searchterm and
		// 	group properties to ignore case.
		const term = searchTerm.toLowerCase();
		const name = group.name.toLowerCase();
		const description = group.description?.toLowerCase() || ''; // default to empty string if undefined/null

		switch(true) {
			case name.includes(term):
				return true;

			case description.includes(term):
				return true;

			default:
				return false;
		}
	});

const Groups: React.FC = (props) => {
	const dispatch = useDispatch<any>();

	const groupDetailsModal = useModal();

	const notification = useAlert()[1] as AlertDispatch;

	const formValidation = useFormValidation();

	// A simple counter to trigger a refetch of the list, like when remoing a group, and you wanna refetch.
	const [reloadCounter, setReloadCounter] = React.useState<number>(0);

	// Show skeleton while fetch is loading
	const [isLoading, setLoading] = React.useState(true);

	const [sort] = React.useState<SortingState>({
		// the name of a property of Group to sort after.
		sortBy: 'name',

		// ascending or descending
		sortOrder: 'asc'
	});

	const [searchText, setSearchText] = React.useState<string>('');

	const groups: Group[] = useSelector(
		(state: RootState) => state.groupsManagement.groups
	);

	// all the groups from the Redux state filtered with the current searchTerm.
	const filteredGroups = filterGroupsBySearchText(searchText, groups);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns: GROUPS_LIST_COLUMNS,
		data: filteredGroups
	});

	/**
	 * Open modal with a group's information.
	 */
	const openGroupDetails = React.useCallback((group: Group | null) => {
		if(!group) {
			// Creating a new group
			groupDetailsModal.open({
				title: 'Skapar grupp',
				width: '98%',
				height: '98%',
				isDismissable: 'true',
				isBackdropBlurred: true,
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						action: (
							_oState: number,
							_cState: number,
							closeModal: any
						) => {
							formValidation.resetErrors();
							closeModal();
						}
					},
					{
						text: 'Lägg till',
						action: (
							_oState: number,
							_cState: Group,
							closeModal: any
						) =>
							formValidation.submit(() => {
								let alertId = notification('SHOW', {
									priority: AlertPriorityTypes.info,
									title: 'Skapa grupp',
									children: `Skapar grupp ${_cState.name}...`
								});
								axios
									.post('modules/groups', _cState)
									.then(() => {
										notification('MODIFY', {
											alertID: alertId,
											priority:
													AlertPriorityTypes.success,
											title: 'Skapa grupp',
											children: `${_cState.name} har skapats`
										});
										closeModal();
									})

									.catch(() => {
										notification('MODIFY', {
											alertID: alertId,
											priority:
													AlertPriorityTypes.error,
											title: 'Skapa grupp',
											children:
													'Ett eller flera fel hittades'
										});
									})

								// trigger update list of users
									.finally(() => {
										formValidation.resetErrors();
										setReloadCounter(reloadCounter + 1);
									});
							})
					}
				],
				// send object with id -1 to indicate a new user is being created
				state: {
					id: -1,
					key: '',
					name: '',
					description: '',
					users: 0,
					privileges: {}
				}
			});
		} else {
			groupDetailsModal.open({
				title: `Redigerar ${group.name}`,
				width: '98%',
				height: '98%',
				isDismissable: 'true',
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						action: (
							_oState: number,
							_cState: Group,
							closeModal: any
						) => {
							formValidation.resetErrors();
							closeModal();
						}
					},
					{
						text: 'Spara',
						action: (
							_oState: number,
							_cState: Group,
							closeModal: any
						) =>
							formValidation.submit(() => {
								let alertId = notification('SHOW', {
									priority: AlertPriorityTypes.info,
									title: 'Uppdaterar grupp',
									children: `Sparar gruppen ${_cState.name}...`
								});

								axios
									.put(
										`modules/groups/${_cState.id}`,
										_cState
									)
									.then(() => {
										notification('MODIFY', {
											alertID: alertId,
											priority:
													AlertPriorityTypes.success,
											title: 'Grupp uppdaterad',
											children: `Gruppen ${group.name} har sparats`
										});

										closeModal();
									})

									.catch(() => {
										notification('MODIFY', {
											alertID: alertId,
											priority:
													AlertPriorityTypes.error,
											title:
													'Ett fel har uppstått, prova igen',
											children: `Det gick inte att uppdatera gruppen ${group.name}.`
										});
									})

								// trigger update list of users
									.finally(() => {
										formValidation.resetErrors();
										setReloadCounter(reloadCounter + 1);
									});
							})
					}
				],
				state: group
			});
		}
	}, [formValidation, groupDetailsModal, notification, reloadCounter]);

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {HTMLElement} input
	 */
	const searchButtonClickedHandler = React.useCallback((input: any) => {
		const value = input.current.value;
		setSearchText(value);
	}, [setSearchText]);

	/**
	 * Clears the search input texts when x button is clicked.
	 */
	const searchClearedHandler = React.useCallback(() => {
		setSearchText('');
	}, [setSearchText]);

	/**
	 * Increases the reload counter to triger a refetch by the useEffect.
	 */
	const reloadGroupsList = React.useCallback(() => {
		setReloadCounter(reloadCounter + 1);
	}, [reloadCounter]);

	/**
	 * Fetch groups the first time and when searchtext, sorting or page index/size changes.
	 */
	React.useEffect(() => {
		setLoading(true);
		dispatch(fetchGroups(searchText)).then(() => setLoading(false));
	}, [searchText, dispatch, reloadCounter]);

	return (
		<>
			{groupDetailsModal.getAsComponent(
				<GroupDetails
					reloadGroupsList={reloadGroupsList}
					formValidation={formValidation}
				/>
			)}

			<ModuleContainer
				hasButton
				buttonText="Skapa grupp"
				buttonIcon={['fal', 'plus']}
				header="Grupper"
				buttonOnClick={() => openGroupDetails(null)}
			>
				<ScSearch
					hasButton
					searchPlaceholder="Sök på namn, användarnamn eller e-postadress"
					searchBtnClicked={searchButtonClickedHandler}
					isDisabled={isLoading}
					cleared={searchClearedHandler}
					enableSearchOnEnter
				/>

				<ErrorBoundary>
					{isLoading && (
						<SkeletonTable />
					)}

					{!isLoading && (
						<Table
							overFlowScroll
							{...getTableProps()}
						>
							<TableHead>
								{headerGroups.map((headerGroup: HeaderGroup) => (
									<div
										style={{ display: 'table-row' }}
										{...headerGroup.getHeaderGroupProps()}
									>
										{headerGroup.headers.map(
											(column: HeaderGroup) => (
												<TableHeading
													isSortable={false}
													isActive={
													sort.sortBy === column.id
												}
													sortOrder={sort.sortOrder}
													{...column.getHeaderProps()}
												>
													{column.render('Header')}
												</TableHeading>
											)
										)}
									</div>
								))}
							</TableHead>

							<TableBody {...getTableBodyProps()}>
								{rows.map((row: Row<any>) => {
									prepareRow(row);

									return (
										<TableRow {...row.getRowProps()}>
											{row.cells.map((cell: Cell<Group>) => {
												return (
													<TableCell
														clicked={() =>
															openGroupDetails(
																cell.row.original
															)}
														{...cell.getCellProps()}
													>
														{renderGroupsTableCellContent(
															cell
														)}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					)}
				</ErrorBoundary>
			</ModuleContainer>
		</>
	);
};
export default withErrorBoundary(Groups);

const ScSearch = styled(Search)`
	margin-bottom: 24px;
`;
