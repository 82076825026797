import { SetUserAction, SetUsersListItemsAction } from './../../types/ControlPanel/UsersTypes';
import { SET_LISTITEM_USERS, SET_USER } from './../actionTypes';
import PaginationInfo from '../../../definitions/PaginationInfo';
import { User, UserListItem } from '../../../containers/ControlPanel/Users/model.Users';

/**
 * Will trigger the storing of all users in state with the pagination info.
 *
 * @param {UserListItem[]} users An array with all user objects to store.
 * @param {PaginationInfo} paginationInfo The information about the current pagination.
 * @returns {SetUsersListItemsAction}
 */
export const setListItemUsers = (
	users: UserListItem[],
	paginationInfo: PaginationInfo
): SetUsersListItemsAction => {
	return {
		type: SET_LISTITEM_USERS,
		users,
		paginationInfo
	};
};

/**
 * Will trigger the storing of a single user in state.
 *
 * @param {User} user The complete user data to store.
 * @returns {SetUserAction}
 */
export const setUser = (user: User): SetUserAction => {
	return {
		type: SET_USER,
		user
	};
};
