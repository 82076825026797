import update from 'immutability-helper';
import { SET_CMS_GROUPS } from '../../actions/actionTypes';
import {
	GroupsState,
	SetGroupsAction
} from '../../types/ControlPanel/GroupsTypes';

const initialState: GroupsState = {
	groups: []
};

let updatedState;

/**
 * Handles the action with new state's data and create a new state to store.
 *
 * @param {object} state The new state to store.
 * @param {object} action The action data the reducer will use to calculate new state.
 */
const groupsManagement = (state = initialState, action: SetGroupsAction) => {
	switch(action.type) {
		case SET_CMS_GROUPS:
			updatedState = update(state, {
				groups: {
					$set: action.groups
				}
			});

			return updatedState;

		default:
			return state;
	}
};

export default groupsManagement;
