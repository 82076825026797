import { Privileges } from '../../definitions/Privileges';

export enum PrivilegesMode {
	ADDED = 'added',
	REMOVED = 'removed',
	INCLUDED = 'included',
}

export type VerifiedUserGroupPrivileges = Partial<
Record<keyof Privileges, PrivilegesMode>
>;
