import React from 'react';
import { VerifiedUserGroupPrivileges, PrivilegesMode } from './types';
import { Group } from '../../containers/ControlPanel/Groups/model.Groups';
import { User } from '../../containers/ControlPanel/Users/model.Users';
import { Privileges } from '../../definitions/Privileges';

type MergedPrivileges = (keyof Privileges)[];

const useUserGroupPrivileges = () => {
	/**
	 * Get user's groups' privileges combined
	 *
	 * @param {User} user
	 * @return {MergedPrivileges}
	 */
	const getUserGroupPrivileges = React.useCallback(
		(user: User): MergedPrivileges => {
			// Reduces each user group privileges to a single array containing only the priv. name
			// E.g. of final structure ['groups.add', 'groups.add', ...etc]
			const groupsPrivileges: MergedPrivileges = user.groups.reduce(
				(prev: MergedPrivileges, group: Group) => {
					prev.push(
						...(Object.keys(group.privileges) as MergedPrivileges)
					);
					return prev;
				},
				[]
			);

			// As different groups may contain the same permissions we clear dups before returning the result
			return [...new Set(groupsPrivileges)];
		},
		[]
	);

	/**
	 * Get object with user's groups' initial privileges
	 *
	 * @param user
	 * @return {VerifiedUserGroupPrivileges}
	 */
	const resetPrivileges = React.useCallback(
		(user: User): VerifiedUserGroupPrivileges => {
			const groupsPrivileges = getUserGroupPrivileges(user);

			let result = groupsPrivileges.reduce(
				(
					prev: VerifiedUserGroupPrivileges,
					current: keyof Privileges
				) => {
					return {
						...prev,
						[current]: { mode: PrivilegesMode.INCLUDED }
					};
				},
				{}
			);

			return result;
		},
		[getUserGroupPrivileges]
	);

	/**
	 * Compare user privileges with the user's group privileges.
	 * Return object with all privileges, including information
	 * about whether the privilege is added or removed in comparison to the group privileges
	 *
	 * @param {User} user
	 * @returns {VerifiedUserGroupPrivileges}
	 */
	const getVerifiedUserPrivileges = React.useCallback(
		(user: User): VerifiedUserGroupPrivileges => {
			//Get user's privileges
			let userPrivileges: MergedPrivileges = Object.keys(
				user.privileges
			) as MergedPrivileges;

			userPrivileges = userPrivileges.filter(
				(prop) => user.privileges[prop] === true
			);

			//Get all groups' privileges combined
			const groupsPrivileges = getUserGroupPrivileges(user);

			/**
			 * Check if user privilege is included in group privileges or not,
			 * and add it to the result object
			 */
			let result = userPrivileges.reduce(
				(
					prev: VerifiedUserGroupPrivileges,
					current: keyof Privileges
				) => {
					return {
						...prev,
						[current]: groupsPrivileges.includes(current)
							? PrivilegesMode.INCLUDED
							: PrivilegesMode.ADDED
					};
				},
				{}
			);

			/**
			 * Check if group privilege is included in user privileges,
			 * and add it to the result object
			 */
			result = groupsPrivileges.reduce(
				(
					prev: VerifiedUserGroupPrivileges,
					current: keyof Privileges
				) => {
					if(!userPrivileges.includes(current)) {
						return {
							...prev,
							[current]: PrivilegesMode.REMOVED
						};
					}

					return prev;
				},
				result
			);

			return result;
		},
		[getUserGroupPrivileges]
	);

	return {
		getVerifiedUserPrivileges,
		resetPrivileges,
		getUserGroupPrivileges
	};
};

export default useUserGroupPrivileges;
