/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import GroupAccessModal from './GroupUsersModal';
import axios from '../../../utils/oc-axios';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import SplitSlider from '../../../components/SplitSlider';
import Tree from '../../../components/Tree/Tree';
import MultiSelectBar from '../../../components/MultiSelectBar/MultiSelectBar';
import { Group } from '../../../containers/ControlPanel/Groups/model.Groups';
import { RootState } from '../../../store/types/RootTypes';
import Search from '../../../components/Search/Search';
import { fetchGroups } from '../../../store/thunks/ControlPanel/thunk-groups';
import { Icon, Message } from '../../../components/UI';
import useModal from '../../../hooks/Modal/useModal';
import { CheckItem } from '../../../components/Forms';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import { SplitSliderSideProps } from '../../../components/SplitSlider/SplitSlider.types';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import { SkeletonTable, SkeletonTree } from '../../../components/Skeletons';

const NavItemAccess: React.FC = () => {
	const notification = useAlert()[1] as AlertDispatch;

	// Redux
	const dispatch = useDispatch<any>();

	// Modal instance for the group users management.
	const groupAccessModal = useModal();

	// The current search value, updated by ScSearch.
	const [searchText, setSearchText] = React.useState<string>('');

	// Show skeleton while fetch is loading.
	const [isLoadingTree, setIsLoadingTree] = React.useState(true);

	// All Tree items
	const [treeItems, setTreeItems] = React.useState<Record<string, any>>();

	// All items that are currently selected in the Tree
	const [selectedTreeItems, setSelectedTreeItems] = React.useState<string[]>([]);

	// Show skeleton while fetch is loading
	const [isLoadingGroups, setIsLoadingGroups] = React.useState(true);

	// All selected Groups id
	const [selectedGroups, setSelectedGroups] = React.useState<number[]>([]);

	// All available Groups
	const groups: Group[] = useSelector((state: RootState) => state.groupsManagement.groups);

	// The complete status of all loadings
	const isLoading = isLoadingGroups || isLoadingTree;

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {React.MutableRefObject<HTMLInputElement>} input Reference to the search input element.
	 * @returns {void}
	 */
	const searchButtonClickedHandler = React.useCallback((input: React.MutableRefObject<HTMLInputElement>): void => {
		const value = input?.current.value;
		setSearchText(value);
	}, [setSearchText]);

	/**
	 * Clears the search input texts when x button is clicked.
	 * 
	 * @returns {void}
	 */
	const searchClearedHandler = React.useCallback((): void => {
		setSearchText('');
	}, [setSearchText]);

	/**
	 * Dispatch Redux action to fetch all groups.
	 * 
	 * @returns {void}
	 */
	const fetchAllGroups = React.useCallback(async (): Promise<void> => {
		setIsLoadingGroups(true);
		await dispatch(fetchGroups(searchText));

		// Clear all selected users as some of the entries may dissapear 
		//	when searching and they not match the search terms.
		setSelectedGroups([]);
		setSelectedTreeItems([]);
		setIsLoadingGroups(false);
	}, [dispatch, searchText]);

	/**
	 * Handles when the user clicks on a Table row's Checkbox.
	 * 
	 * @param {Group} group The Group object that the event was triggered for.
	 * @returns {Promise<void>}
	 */
	const groupsTableRowClickedHandler = React.useCallback(async (group: Group): Promise<void> => {
		const groupIdIndex = selectedGroups.indexOf(group.id);

		let selectedGroupsState: number[] = [];
		let selectedTreeItems: string[] = [];

		// If in array (selected), toggle by removing it
		// Else add it to selected array
		if(groupIdIndex > -1) {
			selectedTreeItems = [];
			selectedGroupsState = [];
		} else {
			// Show loader under the time been.
			setIsLoadingTree(true);

			// Fetch all navigation allowances for the group and select the allowances in the Tree.
			try {
				const resp = await axios.get(`modules/groups/${group.id}/navigation-allowances`);

				const groupNavAllowances = resp.data.result;

				// filter out the items that are not among the course tree items.
				selectedTreeItems = treeItems ? groupNavAllowances.filter((item: number) => {
					const id = item.toString();
					return Object.keys(treeItems).includes(id);
				}) : [];

				selectedGroupsState = [group.id];

			} catch(e) {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: 'Ett oväntad fel inträffat, vänligen prova snart igen.'
				});
			} finally {
				setIsLoadingTree(false);
			}
		}

		// Set all states with the new data
		setSelectedGroups(selectedGroupsState);
		setSelectedTreeItems(selectedTreeItems);

	}, [notification, selectedGroups, treeItems]);

	/**
	 * Opens a modal when the user clicks on a settings button for a group.
	 * 
	 * @param {React.MouseEvent<SVGSVGElement>} ev The event triggered.
	 * @param {Group} group The group the event was triggered for.
	 * @returns void
	 */
	const groupRowSettingsClickedHandler = React.useCallback((ev: React.MouseEvent<SVGSVGElement>, group: Group): void => {
		ev.stopPropagation();

		groupAccessModal.open({
			title: `Hanterar användare i grupp ${group.name}`,
			width: '60%',
			height: '95%',
			isDismissable: false,
			isBackdropBlurred: false,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (
						_oState: number,
						_cState: number,
						closeModal: any
					) => {
						closeModal();
					}
				}
			],

			state: {
				key: group.key,
				id: group.id
			}
		});
	}, [groupAccessModal]);

	/**
	 * Handles the closing of the actionbar.
	 * 
	 * @returns {void}
	 */
	const actionbarClosedHandler = React.useCallback((): void => {
		// De-select all Groups
		setSelectedGroups([]);

		// clear all selected tree items
		setSelectedTreeItems([]);
	}, []);

	/**
	 * Handles the the user checks a Tree item.
	 *
	 * @param {React.MouseEvent<HTMLDivElement>} ev	The event, unknown type
	 * @param {any} item The NavigationItem data, there is no type for it.
	 * @returns {void}
	 */
	const selectTreeItemHandler = React.useCallback((ev: React.MouseEvent<HTMLDivElement>, item: any): void => {
		ev.preventDefault();

		// Skip if no group is selected as the Tree can't be interacted with without a selected Group.
		if(selectedGroups.length === 0) return;

		// Check if the item is already selected, if so unselect it.
		const isAlreadySelected = selectedTreeItems.includes(item.id);

		let updatedSelected: string[] = [];

		if(isAlreadySelected) {
			let children: string[] = [];

			if(treeItems) children = getChildren(item, treeItems);

			// unselect the selected item and it's children
			updatedSelected = selectedTreeItems.filter(id => id !== item.id && !children.includes(id));

		} else {
			let parents: string[] = [];
			
			// also select parents
			if(treeItems) parents = getParents(item, treeItems);

			// stores only unique values
			updatedSelected = [...new Set([...selectedTreeItems, item.id, ...parents])];
		}

		setSelectedTreeItems(updatedSelected);
	}, [selectedGroups.length, selectedTreeItems, treeItems]);

	/**
	 * Assign permissions to the selected groups.
	 * 
	 * @return {void}
	 */
	const assignPermissionsClickedHandler = React.useCallback((): void => {
		const groupId = selectedGroups[0];

		// Skip if there is no selected group.
		if(!groupId) return;

		setIsLoadingTree(true);
		setIsLoadingGroups(true);

		switch(true) {
			// If we are removing all permissions from the group (no navItems selected)
			case selectedTreeItems.length == 0: {
				axios.delete(
					`modules/groups/${groupId}/navigation-allowances`
				)
					.then(() => {
						// Clear all selected Tree items
						setSelectedTreeItems([]);
						setSelectedGroups([]);
					})
					.catch(() => {
						notification('SHOW', {
							priority: AlertPriorityTypes.error,
							title: 'Radering av behörigheter misslyckades, vänligen prova snart igen.'
						});
					});

				break;
			}

			// If we are modifiying or changing assigned permissions
			case selectedTreeItems.length > 0: {
				axios.post(
					`modules/groups/${groupId}/navigation-allowances`,
					{
						pages: selectedTreeItems
					}
				)
					.then(() => {
						// Clear all selected Tree items
						setSelectedTreeItems([]);
						setSelectedGroups([]);
					})
					.catch(() => {
						notification('SHOW', {
							priority: AlertPriorityTypes.error,
							title: 'Tilldelningen av behörigheter misslyckades, vänligen prova snart igen.'
						});
					});

				break;
			}

		}

		setIsLoadingTree(false);
		setIsLoadingGroups(false);

	}, [notification, selectedGroups, selectedTreeItems]);

	/**
	 * Generate all the columns for the groups table.
	 */
	const groupsTableColumns = React.useMemo(() => [
		{
			Header: 'Grupp',
			accessor: 'name',
			Cell: (tableData: any) => {
				const group = tableData.row.original as Group;
				return (
					<ScColumn>
						<ScCheckItem
							type="checkbox"
							checked={selectedGroups.includes(group.id)}
							changed={() => groupsTableRowClickedHandler(group)}
							isDisabled={isLoading}
							isNotClickable={isLoading}
						/>
						<div>
							{group.name}
						</div>
					</ScColumn>
				);
			}
		},
		{
			Header: '',
			accessor: 'manage-users',
			Cell: (tableData: any) => {
				const group = tableData.row.original as Group;
				return (
					<ScColumnRight>
						<ScIcon
							icon={['fal', 'user-pen']}
							onClick={(ev: React.MouseEvent<SVGSVGElement>) => groupRowSettingsClickedHandler(ev, group)}
							color="white"
							data-show-on-hover
						/>
					</ScColumnRight>
				);
			}
		}
	], [selectedGroups, isLoading, groupsTableRowClickedHandler, groupRowSettingsClickedHandler]);

	/**
	 * Load all Tree items from backend
	 */
	React.useEffect(() => {
		axios.get('navigations/trees/8?nav_types=folder')
			.then((resp) => {
				setTreeItems(resp.data);
			})
			.catch(() => {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: 'Kunde inte hämta navigationen, vänligen prova snart igen.'
				});
			})
			.finally(() => {
				setIsLoadingTree(false);
			});
	}, [notification]);

	/**
	 * Fetch groups the first time and when searchtext, sorting or page index/size changes.
	 */
	React.useEffect(() => {
		fetchAllGroups()
			.catch(() => {
				setIsLoadingGroups(false);
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: 'Kunde inte hämta grupper, vänligen prova snart igen.'
				});
			});
	}, [searchText, dispatch, notification, fetchAllGroups]);

	return (
		<ErrorBoundary>
			{groupAccessModal.getAsComponent(
				<GroupAccessModal />
			)}

			<ModuleContainer
				header="Sidåtkomst"
			>
				<SplitSlider axis="horizontal">
					<ScSide
						key="side_A"
						size={30}
						minSize={20}
					>
						<Tree
							collapsePreviouslyExpanded={false}
							scope="navitem-access"
							payload={treeItems}
							checked={selectedTreeItems}
							skeleton={isLoadingTree ? <SkeletonTree /> : (
								<Message>
									Inga mappar hittades...
								</Message>
							)}
							preExpanded={treeItems && ['r8'].concat([...treeItems['r8'].children])}
							clicked={selectTreeItemHandler}
							useCheckboxes={selectedGroups.length > 0 ? () => null : false}
							highlighted={[]}
							isLoading={isLoadingTree}
						/>
					</ScSide>

					<ScSide
						key="side_B"
						size={70}
						minSize={50}
					>
						<ScMain>
							<ScSearch
								hasButton
								searchPlaceholder="Sök på grupp"
								searchBtnClicked={searchButtonClickedHandler}
								isDisabled={isLoadingGroups}
								cleared={searchClearedHandler}
								enableSearchOnEnter
							/>

							{!isLoadingGroups ? (
								<ReactTable
									data={groups}
									columns={groupsTableColumns}
									totalResults={groups.length}
									hasNativePagination
									hasNativeSorting
									resultsPerPage={100}
								/>
							) : <SkeletonTable />}
						</ScMain>
					</ScSide>
				</SplitSlider>

				{selectedGroups.length > 0 && !isLoading && (
					<ScMultiSelectBar
						texts={{
							selectedSingle: 'Vald grupp',
							selectedMany: 'Valda grupper'
						}}
						onClose={actionbarClosedHandler}
						opts={[{
							icon: ['fal', 'save'],
							label: 'Tilldela',
							action: assignPermissionsClickedHandler
						}]}
						amount={selectedGroups.length}
						isLoading={isLoading}
					/>
				)}
			</ModuleContainer>
		</ErrorBoundary>
	);
};

export default NavItemAccess;

/**
 * Returns an array with IDs of all parents to given tree item.
 * 
 * @param {any} treeItem The tree item to get parents for.
 * @param {Record<string, any>} treeItems All tree items.
 * @returns {string[]} An array with IDs of all parents to given tree item.
 */
const getParents = (treeItem: any, treeItems: Record<string, any>): string[] => {
	const parents: string[] = [];

	if(treeItem.parent_reference) {
		const parentID = treeItem.parent_reference;
		parents.push(parentID);
		parents.push(...getParents(treeItems[parentID], treeItems));
	}

	return parents;
};

/**
 * Returns an array with IDs of all children to given tree item.
 * 
 * @param {any} treeItem The tree item to get children for.
 * @param {Record<string, any>} treeItems All tree items.
 * @returns {string[]} An array with IDs of all children to given tree item.
 */
const getChildren = (treeItem: any, treeItems: Record<string, any>): string[] => {
	const children: string[] = [];

	if(treeItem.children) {
		treeItem.children.forEach((childID: string) => {
			children.push(childID);

			if(treeItems[childID]) children.push(...getChildren(treeItems[childID], treeItems));
		});
	}

	return children;
};

const ScSide = styled.div<SplitSliderSideProps>`
	height: 100%;
	overflow-y: auto;
	margin: 0 32px 100px 32px ;
`;

const ScMain = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
`;

const ScSearch = styled(Search)`
	margin-bottom: 24px;
`;

const ScIcon = styled(Icon)`
	cursor: pointer;
	height: 14px;
	margin-left: 8px;
`;

const ScColumn = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const ScColumnRight = styled(ScColumn)`
	float: right;
`;

const ScCheckItem = styled(CheckItem)`
	margin-bottom: 0;
	margin-right: 16px;
`;

const ScMultiSelectBar = styled(MultiSelectBar)`
	position: fixed;
	bottom: 40px;
`;