import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import styled, { css } from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import ConflictModal from './Modals/ConflictModal';
import ImageSettings from './Modals/ImageSettings';
import FolderSettings from './Modals/FolderSettings';
import MoveMediaModal from './Modals/MoveMediaModal';
import CropModal from './Modals/CropModal/CropModal';
import axios from '../../utils/oc-axios';
import { BASE_URL as AXIOS_BASE_URL, SHOW_IMAGE_CROPPER } from '../../settings';
import useAlert, { AlertPriorityTypes } from '../../hooks/useAlert';
import useModal from '../../hooks/Modal/useModal';
import ItemManager from '../../components/ItemManager/ItemManager';
import { SkeletonTree } from '../../components/Skeletons';
import SplitSlider from '../../components/SplitSlider';
import Tree from '../../components/Tree/Tree';
import UploadDropzone from '../../components/UI/UploadDropzone';
import { Icon } from '../../components/UI';
import { setMediaArchiveSelectedFolder } from '../../store/actions/action-config';
import withErrorBoundary from '../../hoc/withErrorBoundary';

/**
 * Sorting options for the list/table view
 */
const sortingOptions = [
	{
		name: 'Bild',
		property: 'thumbnail',
		table: {
			render: (item, value) => (
				<img
					alt={item.original_name}
					src={value}
					style={{ width: '32px' }}
				/>
			)
		}
	},
	{
		name: 'Filnamn',
		property: 'original_name'
	},
	{
		name: 'Uppladdad',
		property: 'created'
	},
	{
		name: 'Upplösning',

		// Use an object to "join" multiple of the item's properties and show i as one.
		property: {
			// The separator used by join.
			separator: 'x',

			// The properties of the item object to merge
			// NOTICE: The first one in array will be used to sort on!.
			properties: ['height', 'width']
		}
	},
	{
		name: 'Filstorlek (KB)',
		property: 'size',

		// Convert the values to another format/scale like, bytes to KB
		convertTo: ['bytes', 'KB']
	}
];

/**
 *
 * @param {int} maxSelectionAmount      The maximum allowed amount of items to be selected at the same time, 0 for disabled
 *
 * @param {func} mediaChosen			Will be called when the selected files are "chosen" in the SelectBar
 *
 */
const MediaArchive = (props) => {
	// #region Hooks definitions
	const dispatch = useDispatch();

	const alerts = useAlert()[1];

	// modals
	const imageSettingsModal = useModal();
	const folderSettingsModal = useModal();

	const mediaMoveModal = useModal();
	const deleteImageConflictModal = useModal();
	const deleteFolderConflictModal = useModal();
	const cropperModal = useModal();

	// states
	const [isLoading, setLoading] = useState(true);

	const [mediaFolders, setMediaFolders] = useState({});

	// All currently selected media file'ss uuid
	const [selectedMediaFiles, setSelectedMediaFiles] = useState([]);

	// The file from the selected folder (mediaArchiveConfig.selected)
	const [mediaFiles, setMediaFiles] = useState([]);

	const mediaArchiveConfig = useSelector(
		(state) => state.config.mediaarchive
	);

	const imgCropRef = React.useRef();

	// #endregion

	// #region Handlers - Tree/Folders

	/**
	 * Change the current folder when one is selected on Tree.
	 *
	 * @param {Event} ev        Object containing original event dispatched
	 * @param {object} item     Full set of props of a clicked nav item
	 */
	const selectFolderHandler = useCallback(
		(ev, item) => {
			dispatch(setMediaArchiveSelectedFolder(mediaFolders, item));
		},
		[dispatch, mediaFolders]
	);

	/**
	 * Updates the state by adding a new input item to it for writing the name of the new folder.
	 * 	Triggered when the user press the + icon, before the input fields appears.
	 *
	 * @param {Event} ev				An Event.
	 * @param {object} newItemData		An object with all the navItem'sprops like icon, middleware, type, etc... see Tree docs
	 * @param {object} suggestedState	The sugegsted state with the input included.
	 */
	const addFolderHandler = (ev, newItemData, suggestedState) => {
		ev.stopPropagation();

		// update state to show the new input navItem
		setMediaFolders(
			update(mediaFolders, {
				$set: suggestedState
			})
		);
	};

	/**
	 * Create the new folder in front-end and back-end
	 * 	Triggered when user press ENTER after writing a name for the navigation item.
	 *
	 * @param {Event} ev 		Original event dispatched
	 * @param {object} input 	Info to create item
	 * @param {string} name 	The name for the new folder
	 * @param {string} eventStr Event type e.g. keyPress|blur
	 */
	const createFolderHandler = (ev, input, name, eventStr) => {
		ev.stopPropagation();

		const parentId = input.parent_reference;
		const parent = mediaFolders[parentId];
		const itemIndex = parent.children.indexOf(input.id);

		const data = {
			navigation_id: input.rootNavigationId,
			navigation_type: mediaFolders.add.type,

			parent_id: !input.is_child ? null : parentId,
			title: name
		};

		axios
			.post('navigations/items', data)
			.then((response) => {
				const item = response.data;
				const updatedState = update(mediaFolders, {
					[parentId]: {
						children: {
							$splice: [[itemIndex, 1, item.id]]
						}
					},
					$merge: {
						[item.id]: item
					},
					$unset: [input.id]
				});

				setMediaFolders(updatedState);
			})
			.catch((err) => {
			// To be replaced with a proper alert
				console.warn('[Could not add folder]', err);

				const updatedState = update(mediaFolders, {
					[parentId]: {
						children: {
							$splice: [[itemIndex, 1]]
						}
					},
					$unset: [input.id]
				});

				setMediaFolders(updatedState);
			});
	};

	/**
	 * Shows a confirmation modal and removes a media folder.
	 */
	const deleteFolderHandler = async (ev, action, treeItem) => {
		ev.stopPropagation();
		axios
			.delete(`/navigations/items/${treeItem.item.id}`)
			.then((response) => {
				const item = treeItem.item;
				const parentId = item.parent_reference;

				const updatedState = update(mediaFolders, {
				// remove reference from parent
					[parentId]: {
						children: {
							$splice: [
								[
									mediaFolders[parentId].children.indexOf(
										item.id
									),
									1
								]
							]
						}
					},

					// remove the navItem
					$unset: [item.id]
				});

				// TODO Clean selected items if the removed folder is current folder.
				/*setSelectedFiles({
					...selectedFiles,
					folder: parentId,
				});*/

				setMediaFolders(updatedState);
			})
			.catch((err) => {
				if(err.response?.status === 409) {
					const response = err.response.data;

					// Prepare data for the modal
					const errors = Object.values(response.references).map(
						(error) => {
							const result = [];

							const filepath = error.file.split('/');
							const filename = filepath[filepath.length - 1];

							for(const ref of error.references) {
								result.push(`${filename} > ${ref.title}`);
							}

							return result;
						}
					);

					// Open modal with errors
					deleteFolderConflictModal.open({
						title: '',
						position: 'center',
						actions: [
							{
								text: 'Stäng',
								isDefault: true,
								action: (
									originalState,
									currentState,
									closeModal
								) => {
									closeModal();
								}
							}
						],
						state: errors
					});
				} else {
					console.warn(err);
				}
			});
	};

	/**
	 * Callback that receives data from tree
	 * In most cases it is enough simply using the "updateInstructions" parameter
	 * Which contains a new state for the tree + an accurate axios payload
	 *
	 * @param {*} updateInstructions (Object containing a suggested state and axios payload)
	 * @param {*} dragSource (Object containing source data from dnd)
	 * @param {*} dragDestination (Object containing destination data from dnd)
	 * @param {*} dragInstruction (Object containing instructions from dnd)
	 * @param {*} origEv (Object containing original event dispatched)
	 */
	const dragEndHandler = async (updateInstructions, dragSource) => {
		const { updatedState, axiosPayload } = updateInstructions;
		const sourceProperties = dragSource.properties;

		setMediaFolders(update(mediaFolders, { $set: updatedState }));

		await axios.put(
			`navigations/items/${sourceProperties.itemId}`,
			axiosPayload
		);
	};

	/**
	 * Opens the folderSettings modal with the selected folder's data.
	 *
	 * @param {Event} ev				An Event.
	 * @param {object} newItemData		An object with all the navItem'sprops like icon, middleware, type, etc... see Tree docs
	 * @param {object} navItem			An object with internal properties for the navItem, like .item that contains the data from back-end
	 */
	const openFolderSettingsHandler = (ev, newItemData, navItem) => {
		ev.stopPropagation();

		// extract only a subset of data for sending using modal's state.
		const {
			id,
			uuid,
			navigation_id,
			type,
			parent_reference,
			title,
			description
		} = navItem.item;

		// TODO Handler for Ta-bort (dele handler) & Klar (save handler) are needed!
		folderSettingsModal.open({
			title: 'Mapp',
			position: 'right',
			hideBackdrop: true,
			isPositionable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (originalState, currentState, closeModal) => {
						closeModal();
					}
				},
				{
					text: 'Spara',
					action: saveFolderSettingsHandler
				}
			],
			state: {
				id: id,
				uuid: uuid,
				navigation_id: navigation_id,
				navigation_type: type,
				parent_reference: parent_reference,
				title: title,
				description: description
			}
		});
	};

	/**
	 * Handles saving the folder settings.
	 */
	const saveFolderSettingsHandler = (
		originalState,
		currentState,
		closeModal
	) => {
		const rootFolder = Object.values(mediaFolders).find(folder => folder.is_root);

		// Set navigtion_id to the root folder's id (the root is the media archive) 
		// We have to do this because the original navigation_id is not correct and causes an error when updating the folder
		const folderState = {
			...currentState,
			navigation_id: rootFolder.id
		};

		axios
			.put(`navigations/items/${currentState.id}`, folderState)
			.then(() => {
				const updatedState = update(mediaFolders, {
					[currentState.id]: {
						title: { $set: currentState.title },
						description: { $set: currentState.description }
					}
				});

				setMediaFolders(updatedState);

				closeModal();
			});
	};

	// #endregion

	// #region Handlers - Media
	//
	//

	/**
	 * Will move selected files to a new parent
	 * State is updated accordingly
	 */
	const prompMoveMediaModal = () =>
		mediaMoveModal.open({
			title: 'Välj mapp att flytta filer till',
			position: 'center',
			isDismissable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (originalState, currentState, closeModal) =>
						closeModal()
				}
			]
		});

	/**
	 * Function called when files have been moved after choosing a folder in mediaMoveModal.
	 */
	const mediaMovedHandler = () => {
		// delete media from state
		setMediaFiles(
			update(mediaFiles, {
				$set: mediaFiles.filter(
					(file) => !selectedMediaFiles.includes(file.uuid)
				)
			})
		);

		// Empty selected files all those items should have been moved.
		setSelectedMediaFiles([]);

		mediaMoveModal.close();
	};

	/**
	 *
	 * @param {string[]} uuid 	The media's uuid as string array.
	 */
	const deleteMedia = (uuid) => {
		const alertId = alerts('SHOW', {
			priority: AlertPriorityTypes.info,
			title: 'Raderar filer...'
		});

		return new Promise((resolve, reject) => {
			// Send request to back-end for removal
			axios
				.delete('media/files', {
					data: {
						files: uuid
					}
				})
				.then(() => {
					alerts('MODIFY', {
						alertID: alertId,
						priority: AlertPriorityTypes.success,
						title: 'Radering lyckades'
					});

					resolve();
				})
				.catch((err) => {
				// 409 Conflict, returned because the files are used.
					if(err.response && err.response.status === 409) {
						const response = err.response.data;

						// close alert
						alerts('CLOSE', {
							alertID: alertId
						});

						// Prepare data for the modal
						const errors = Object.values(response.errors).map(
							(error) => {
								const result = [];

								for(const ref of error.references) {
									result.push(ref.title);
								}

								return result;
							}
						);

						// Open modal with errors
						deleteImageConflictModal.open({
							title: '',
							position: 'center',
							actions: [
								{
									text: 'Stäng',
									isDefault: true,
									action: (
										originalState,
										currentState,
										closeModal
									) => {
										closeModal();
									}
								}
							],
							state: errors
						});

						// Must trigger reject otherwise state will updated without the files that have NOT
						// 	being removed due to conflict, we must reject
						reject(
							new Error('Can\'t remove media due to files in use')
						);
					} else {
						alerts('MODIFY', {
							alertID: alertId,
							priority: AlertPriorityTypes.error,
							title: '🤷 Ett fel har uppstått',
							children: err.message
						});

						reject(err);
					}
				});
		});
	};

	/**
	 * Will prepare file removal
	 * If a single file is deleted by it's action bar it will only delete that one
	 * If a file or multiple files are selected and deleted by MultiSelectBar
	 * then the selectedItems state is used
	 *
	 * @param {*} file
	 */
	const selectionBarDeleteHandler = () => {
		setLoading(true);

		deleteMedia(selectedMediaFiles)
			.then(() => {
				const updatedState = update(mediaFiles, {
					$set: mediaFiles.filter(
						(file) => !selectedMediaFiles.includes(file.uuid)
					)
				});

				// clean selected items as the are removed.
				setSelectedMediaFiles([]);

				// update state without the removed items
				setMediaFiles(updatedState);
			})
		// The error would be triggered when error or conflict. Handled internally.
			.catch(() => void 0)
			.finally(() => {
			// Show monday bar again
			// re enable selection & re-select items
				setLoading(false);
			});
	};

	/**
	 * Show image settings
	 *
	 * @param {object} item		The 'item' prop (ex the data of the specific item in ItemManager) passed to the Action component of the ImageThumbnail.
	 */
	const showImageSettings = async (item) => {
		imageSettingsModal.open({
			title: 'Bild',
			position: 'right',
			hideBackdrop: true,
			isPositionable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (originalState, currentState, closeModal) => {
						/*setSelectedFiles({
							...selectedFiles,
							items: [],
						});*/
						closeModal();
					}
				},
				{
					text: 'Spara',
					action: saveMediaSettingsHandler
				}
			],
			state: {
				isFetching: true,
				data: { uuid: item.id }
			}
		});

		/*setSelectedMediaFiles({
			...selectedFiles,
			items: [itemUuid],
		});*/
	};

	/**
	 * Save the updated media settings & update it in current state.
	 */
	const saveMediaSettingsHandler = async (
		originalState,
		currentState,
		closeModal
	) => {
		// Get the item's index in state
		const itemIndex = mediaFiles.findIndex(
			(media) => media.uuid === currentState.data.uuid
		);

		// Create an updated version of the state.
		const updatedState = update(mediaFiles, {
			[itemIndex]: {
				$merge: {
					name: currentState.data.filename ?? currentState.data.original_name,
					alt_text: currentState.data.alt_text,
					description: currentState.data.description
				}
			}
		});

		const alertID = alerts('SHOW', {
			priority: AlertPriorityTypes.info,
			title: 'Sparar...'
		});

		axios
			.post(`/media/files/${currentState.data.uuid}/settings`, {
				filename: currentState.data.filename ?? currentState.data.original_name,
				description: currentState.data.description,
				alt_text: currentState.data.alt_text
			})
			.then(() => {
				alerts('MODIFY', {
					alertID: alertID,
					priority: AlertPriorityTypes.success,
					title: 'Sparat'
				});

				setMediaFiles(updatedState);
				closeModal();
			})
			.catch((err) => {
			// 409 Conflict, returned because the files are used.
				if(err.response && err.response.status === 422) {
					alerts('MODIFY', {
						alertID: alertID,
						priority: AlertPriorityTypes.warning,
						title: 'Kan ej spara',
						children: err.response.data.message
					});
				}
			});
	};

	// #endregion

	// #region Handlers - ItemManager
	//
	//

	/**
	 * Triggered when items are sorted
	 * @param {object} newSortBy            The criteria & direccion used when sorting
	 * @param {array} suggestedState        The sorted items.
	 */
	const itemsSortingHandler = (newSortBy, suggestedState) => {
		setMediaFiles(suggestedState);
	};

	/**
	 * Triggered when an item in ItemManager is selected or un-selected.
	 *
	 * @param {boolean} isSelected              The new selection state for the itemID.
	 *                                              True=selected, False=Not selected
	 * @param {string} itemID                   The itemID that was selected/un-selected
	 * @param {array(itemID)} suggestedState    The suggested new state with all currently selected items.
	 */
	const selectItemHandler = (isSelected, itemID, suggestedState) => {
		setSelectedMediaFiles(suggestedState);
	};

	// #endregion

	// #region Handlers - FileUploader
	//
	//

	/**
	 * Callback triggered on a successful file upload
	 * Will automatically update state
	 *
	 * @param {array} files		Files that where uploaded, response from back-end.
	 */
	const uploadCompleteHandler = (files) => {
		files = files.map((file) => {
			return {
				...file,
				id: file.uuid,
				name: file.title,
				thumbnail: file.thumb
			};
		});
		setMediaFiles([...mediaFiles, ...files]);

		// Remove previously selected files and select the newly added files
		setSelectedMediaFiles(
			update(selectedMediaFiles, {
				$set: files.map((file) => file.uuid)
			})
		);
	};

	/**
     * Get the complete object of the choosen media file in mediaFiles 
     * and pass it to the callback function (mediaChosen)
     * 
     * @param {string} id   
     * @returns {void}
     */
	const itemDoubleClickedHandler = React.useCallback((id) => {
		//Return if mediaChosen isn't defined or
		//there are selected media files
		if(!props.mediaChosen || selectedMediaFiles.length > 0) return;
        
		const fileObject = mediaFiles.filter((media) => media.uuid === id);
		props.mediaChosen(fileObject);
	}, [mediaFiles, props, selectedMediaFiles]);

	// #endregion

	// #region Consts - General
	//
	//

	/**
	 * Will disable following when used inside a modal.
	 *
	 * - All item actions.
	 * - SelectBar "DELETE"
	 */
	const isInModal = !!props.modal;
	// #endregion

	// #region Consts - Tree/Folders
	//
	//

	/**
	 * This callback handler opens up a modal window for cropping if the criteria is met
	 * 
	 * @param {object} fileProps 
	 * @returns {void}
	 */
	const openCropperModalHandler = React.useCallback(async (fileProps) => {
		// Get the uuid from the fileProps
		const { id: uuid } = fileProps;

		// Find the specific file data from the state
		const file = mediaFiles.find(file => file.uuid === uuid);
		
		// If we have no match, kill the process here
		if(!file) {
			// Trigger an error
			alerts('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Ingen fil hittades...'
			});

			return;
		}

		const { src, mime, extension, folder_uuid } = file;
		const whiteListedMimeTypes = ['image/jpeg', 'image/png'];

		// If it's not a valid image file with the white listed formats, kill the process here
		if(!(whiteListedMimeTypes.includes(mime))) {
			alerts('SHOW', {
				priority: AlertPriorityTypes.error,
				title: 'Vi tillåter endast att man beskär bilder av typerna jpg och png'
			});
			return;
		}

		// Get the window size in order to determine the crop area of the modal
		const winWidth = window.innerWidth;
		const winHeight = window.innerHeight;

		// Create an image object
		const image = new Image();
    	image.src = src;
		image.onload = function() {

			imgCropRef.current = {
				src: src,
				mime: mime,
				extension: extension,
				folder_uuid: folder_uuid
			};

			cropperModal.open({
				title: 'Bildbeskärning',
				position: 'center',
				isDismissable: true,
				width: this.width > winWidth ? winWidth : this.width,
				height: this.height > winHeight ? winHeight : this.width,
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						action: (originalState, currentState, closeModal) =>
							closeModal()
					}
				]
			});
		};
	}, [alerts, cropperModal, mediaFiles]);

	/**
	 * Array with options used for the Tree header.
	 */
	const treeHeaderOpts = [
		{
			icon: ['fal', 'plus'],
			type: 'folder',
			showOnlyForType: ['folder'],
			middleware: 'middlewareAddInput',
			action: addFolderHandler
		}
	];

	/**
	 * Array with opts for the Tree used for showing folders in Media Archive.
	 */
	const treeItemOpts = [
		{
			icon: ['fal', 'plus'],
			type: 'folder',
			showOnlyForType: ['folder'],
			middleware: 'middlewareAddInput',
			action: addFolderHandler
		},
		{
			icon: ['fal', 'trash'],
			type: 'folder',
			action: deleteFolderHandler
		},
		{
			icon: ['fal', 'gear'],
			type: 'folder',
			showOnlyForType: ['folder'],
			action: openFolderSettingsHandler
		}
	];

	// #endregion

	// #region Consts - ItemManager
	//
	//

	// configure button actions on hover
	//	Show then only if whe have NOT selected anything & on hover.
	const itemActions = !isInModal
		? [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'gear']}
				           />,
				action: showImageSettings
			},
			{
				component: (
					<Icon
						color={'#fafafa'}
						icon={['fal', 'trash']}
					/>
				),
				action: (imageThumbnailProps) => {
					const mediaUUID = imageThumbnailProps.id;

					deleteMedia([mediaUUID])
						.then(() => {
						// delete media from state
							setMediaFiles(
								update(mediaFiles, {
									$set: mediaFiles.filter(
										(file) => file.uuid !== mediaUUID
									)
								})
							);

							// If item was selected, remove from selectedItems too.
							setSelectedMediaFiles(
								update(selectedMediaFiles, {
									$set: selectedMediaFiles.filter(
										(file) => file !== mediaUUID
									)
								})
							);
						})
						.catch(() => void 0);
				}
			}
		  ]
		: [];

	// #endregion

	// #region Consts - SelectionBar
	//
	//

	/**
	 * Options to use for the seletion bar.
	 */
	let selectionBarOpts = [
		{
			id: 'move',
			icon: ['fal', 'sitemap'],
			label: 'Flytta',
			action: prompMoveMediaModal
		},
		{
			id: 'download',
			icon: ['fal', 'download'],
			label: 'Ladda ner',
			action: () =>
				(window.location.href = `${AXIOS_BASE_URL}media/downloads/files/${selectedMediaFiles.join(
					','
				)}`)
		}
	];

	if(selectedMediaFiles.length === 1 && SHOW_IMAGE_CROPPER) {
		selectionBarOpts.unshift({
			id: 'crop',
			icon: ['fal', 'crop'],
			label: 'Beskär',
			action: () => {
				const file = mediaFiles.find((media) => selectedMediaFiles.includes(media.uuid));
				openCropperModalHandler(file);
			}
		});
	}

	// Push DELETE button only in NOT in a modal (can't delete media as it may conflict with images used in a page etc..)
	if(!isInModal) {
		selectionBarOpts.push({
			id: 'remove',
			icon: ['fal', 'trash'],
			label: 'Radera',
			action: selectionBarDeleteHandler
		});
	}

	// Push Choose button when in modal and a callback (mediaChosen) is given.
	if(isInModal && props.mediaChosen) {
		selectionBarOpts.push({
			id: 'choose',
			icon: ['fal', 'circle-plus'],
			label: 'Välj',
			action: () => {
				// Get the complete object store in mediaFiles from an array of uuid in selectedMEdiaFiles
				// We need to pass the callback the complete item/media object.
				const fileObjs = mediaFiles.filter((media) =>
					selectedMediaFiles.includes(media.uuid));

				props.mediaChosen(fileObjs);
			}
		});
	}

	// #endregion

	// #region useEffects
	//
	//

	/**
	 * Fetch all folders on mount.
	 */
	useEffect(() => {
		axios.get('navigations/trees/media').then(({ data }) => {
			const firstFolder = Object.keys(data)[0];

			setMediaFolders(
				update(mediaFolders, {
					$set: data
				})
			);

			// only set the selectedFolder if there is not one selected already
			if(!mediaArchiveConfig.selectedFolder.id) {
				dispatch(
					setMediaArchiveSelectedFolder(data, data[firstFolder])
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Fetch all files, even when a folder is selected in Tree.
	 */
	useEffect(() => {
		if(mediaArchiveConfig.selectedFolder.uuid) {
			// show skeleton
			setLoading(true);

			axios
				.get(
					`media/folders/${mediaArchiveConfig.selectedFolder.uuid}/files`
				)
				.then(({ data }) => {
				// Adjust media archive's file format from back-end to the necessary by ItemManager
					const files = data.map((file) => {
						return {
							...file,
							id: file.uuid,
							name: file.title,
							thumbnail: file.thumb
						};
					});

					setMediaFiles(files);

					// hide skeleton
					setLoading(false);
				});
		}
	}, [mediaArchiveConfig.selectedFolder]);

	/**
	 * Clean selected items if whe change the selected/current folder
	 */
	useEffect(() => setSelectedMediaFiles([]), [
		mediaArchiveConfig.selectedFolder
	]);

	// #endregion

	// #region Render
	//
	//

	return (
		<>
			{imgCropRef.current && (cropperModal.getAsComponent(
				<CropModal
					imgSrc={imgCropRef.current.src}
					imgMime={imgCropRef.current.mime}
					imgExt={imgCropRef.current.extension}
					imgTargetFolder={imgCropRef.current.folder_uuid}
					uploadCompleted={uploadCompleteHandler}
				/>
			))}
			{mediaMoveModal.getAsComponent(
				<MoveMediaModal
					mediaItems={selectedMediaFiles}
					mediaFolders={mediaFolders}
					disabledFolders={[mediaArchiveConfig.selectedFolder.id]}
					moveCompleted={mediaMovedHandler}
				/>
			)}
			{imageSettingsModal.getAsComponent(<ImageSettings />)}
			{folderSettingsModal.getAsComponent(<FolderSettings />)}
			{deleteImageConflictModal.getAsComponent(
				<ConflictModal
					title="Du kan inte radera denna bild"
					text="Denna bild används på en eller flera sidor. För att kunna ta bort denna bild, behöver du ta bort dem på sidorna den används på."
					showMoreText="Visa sidor"
					showLessText="Dölj sidor"
				/>
			)}
			{deleteFolderConflictModal.getAsComponent(
				<ConflictModal
					title="Du kan inte radera denna mapp"
					text="Denna mapp innehåller filer som används på en eller flera sidor. För att kunna ta bort denna mapp, behöver du ta bort filerna på sidorna de används på."
					showMoreText="Visa sidor"
					showLessText="Dölj sidor"
				/>
			)}
			<ScContainer isInModal={!!props.modal}>
				<SplitSlider axis="horizontal">
					<ScSide
						key="side_A"
						size={30}
						minSize={20}
					>
						<ScHeader>
							<Sch2>
								Media-arkiv
							</Sch2>
						</ScHeader>

						{
							<Tree
								scope="media-archive"
								skeleton={<SkeletonTree />}
								inputCallback={createFolderHandler}
								payload={mediaFolders}
								enableDrag={true}
								dragEnd={dragEndHandler}
								headerOpts={treeHeaderOpts}
								clicked={selectFolderHandler}
								opts={treeItemOpts}
								highlighted={[
									mediaArchiveConfig.selectedFolder.id
								]}
								preExpanded={[
									'r4',
									...mediaArchiveConfig.expandedFolders
								]}
							/>
						}
					</ScSide>

					<ScMain
						size={70}
						minSize={50}
					>
						<UploadDropzone
							resetTime={5000}
							uploadTo={mediaArchiveConfig.selectedFolder.uuid}
							label="Släpp filer eller tryck här för att ladda upp!"
							onComplete={uploadCompleteHandler}
						/>

						<ItemManager
							isLoading={isLoading}
							// Items
							items={mediaFiles}
							itemActions={itemActions}
							selectedItems={selectedMediaFiles}
							/**
							 * null / undefined = selection is unlimited
							 * 0 = selection is disabled
							 * x = slection amount limited to x
							 */
							maxSelectionAmount={props.maxSelectionAmount}
							// Sorting
							sortingOptions={sortingOptions}
							sortingChanged={itemsSortingHandler}
							// Selection
							selectionBarButtons={selectionBarOpts}
							selectionChanged={selectItemHandler}
							doubleClicked={itemDoubleClickedHandler}
						/>
					</ScMain>
				</SplitSlider>
			</ScContainer>
		</>
	);
	// #endregion
};

MediaArchive.propTypes = {
	maxSelectionAmount: PropTypes.number,

	mediaChosen: PropTypes.func
};

export default withErrorBoundary(MediaArchive);

// #region Styled components
//
//

const ScHeader = styled.div`
	padding: 16px;
	position: relative;
`;

const ScSide = styled.div`
	/* min-width: 308px; */
	height: 100%;
	overflow-y: auto;
`;

const ScMain = styled.div`
	background-color: #fff;
	/* padding: 16px; */
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
`;

const ScContainer = styled.section`
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: row;
	background-color: var(--bg-bright-color);
	width: 100%;
	margin: 16px;

	${(props) =>
		props.isInModal &&
		css`
			margin: 0;
			height: 100%;
		`}
`;

const Sch2 = styled.h2`
	text-align: center;
	font-size: 24px;
	margin: 0 0 16px;
`;
// #endregion
