import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Cell, HeaderGroup, Row, usePagination, useTable } from 'react-table';
import update from 'immutability-helper';
import axios from './../../../../utils/oc-axios';
import { GroupDetailsProps } from './model.GroupDetails';
import { GROUPMEMBERS_LIST_COLUMNS } from './consts.GroupDetails';
import { Group } from '../../../../containers/ControlPanel/Groups/model.Groups';
import useModal from '../../../../hooks/Modal/useModal';
import { ModalProps } from '../../../../hooks/useModal/useModal.model';
import Search from '../../../../components/Search/Search';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeading,
	TablePagination,
	TableRow
} from '../../../../components/Table';
import {
	BasicContentModal,
	Loader,
	ModalContainer
} from '../../../../components/UI';
import ControlPanelHeading from '../../../../components/ControlPanel/ControlPanelHeading/ControlPanelHeading';
import { User } from '../../../../containers/ControlPanel/Users/model.Users';
import SkeletonTable from '../../../../components/Skeletons/SkeletonTable/SkeletonTable';
import PaginationInfo from '../../../../definitions/PaginationInfo';
import ErrorBoundary from '../../../../hoc/ErrorBoundary/ErrorBoundary';
import { SortingState } from '../../../../definitions/Sorting';
import SkeletonTree from '../../../../components/Skeletons/SkeletonTree/SkeletonTree';
import TreeWrapper from '../../../../components/CheckboxTree/TreeWrapper/TreeWrapper';
import TreeCategory from '../../../../components/CheckboxTree/TreeCategory/TreeCategory';
import ItemsWrapper from '../../../../components/CheckboxTree/ItemsWrapper/ItemsWrapper';
import TreeItem from '../../../../components/CheckboxTree/TreeItem/TreeItem';
import useCheckboxTree from '../../../../hooks/useCheckboxTree/useCheckboxTree';
import usePrivilegesToCheckboxTree from '../../../../hooks/usePrivilegesToCheckboxTree/usePrivilegesToCheckboxTree';
import { Privileges } from '../../../../definitions/Privileges';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';
import TextInput from '../../../../components/Forms/TextInput/TextInput';
import { Textarea } from '../../../../components/Forms';

const GroupDetails: React.FC<GroupDetailsProps> = (props) => {
	const formValidation = props.formValidation;

	const deleteGroupModal = useModal();
	const removeUsersModal = useModal();

	const notification = useAlert()[1] as AlertDispatch;

	// if the modal is loading data or not.
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	// store all available privileges and groups
	// fetch them avery time to get the most up-to-date
	const [availablePrivileges, setAvailablePrivileges] = React.useState<any[]>(
		[]
	);

	// true=showing current users // false=adding new users
	// const [
	// 	isAddingOrShowingUsers,
	// 	setAddingOrShowingUsers,
	// ] = React.useState<boolean>(true);

	const reloadGroupsList = props.reloadGroupsList;
	const modal: ModalProps<Group> | undefined = props?.modal;

	const [sort] = useState<SortingState>({
		// the name of a property of Group to sort after.
		sortBy: 'name',

		// ascending or descending
		sortOrder: 'asc'
	});

	const [searchText, setSearchText] = useState<string>('');

	// store all members of the group
	const [groupMembers, setGroupMembers] = useState<User[]>([]);

	// The complete Group's object with all data or null if not loaded yet.
	const group: Group | null = props.modal?.currentState || null;
	const isCreatingNewGroup = React.useMemo(() => group && group.id === -1, [
		group
	]);
	const updateState = props.modal?.updateState;

	// information about how to paginate from the Redux state.
	const [paginationInfo, setPaginationInfo] = useState<PaginationInfo>({
		pageCount: 0,
		totalResults: 0,
		resultsPerPage: 0
	});

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		// setPageSize,
		// Get the state from the instance
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns: GROUPMEMBERS_LIST_COLUMNS,
			data: groupMembers,
			initialState: { pageIndex: 0, pageSize: 25 },
			manualPagination: true,

			// Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: paginationInfo.pageCount
		},

		usePagination
	);

	const {
		getTreeFromPrivileges,
		enableWithAuthenticatedUser,
		checkItemsFromList
	} = usePrivilegesToCheckboxTree();

	/**
	 * Generate the privileges tree data.
	 */
	const rawTree = React.useMemo(() => {
		// generate the base tree data from all the available privileges.
		let baseTree = getTreeFromPrivileges(availablePrivileges);

		// enable privileges based on the current authenticated user's privileges.
		baseTree = enableWithAuthenticatedUser(baseTree);

		// don't continue if the group id is not defined, is already loaded(has id) is if creting a new group
		if(!group) return baseTree;

		// check items from a list of privileges
		baseTree = checkItemsFromList(baseTree, group.privileges);

		return baseTree;
	}, [
		group,
		getTreeFromPrivileges,
		availablePrivileges,
		enableWithAuthenticatedUser,
		checkItemsFromList
	]);

	/**
	 * Used to generate the privileges tree
	 */
	const { tree: privilegesTree } = useCheckboxTree({
		data: rawTree
	});

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {HTMLElement} input
	 */
	const searchButtonClickedHandler = useCallback((input: any) => {
		const value = input.current.value;
		gotoPage(0);
		setSearchText(value);
	}, [gotoPage, setSearchText]);

	/**
	 * Clears the search input texts when x button is clicked.
	 */
	const searchClearedHandler = useCallback(() => {
		gotoPage(0);
		setSearchText('');
	}, [gotoPage, setSearchText]);

	/**
	 * Handle when a tree item is changed.
	 */
	const privilegesTreeCategoryCheckboxChangedHandler = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, categoryID: string) => {
			event.stopPropagation();

			const treeCategory = privilegesTree.find(
				(category) => category.id === categoryID
			);

			const privilegeCategory = availablePrivileges.find(
				(cat) => cat.id === categoryID
			);

			// do nothing if we dont have an user or category, should not never happen.
			if(!treeCategory || !group) return;

			const addingPrivilege = !treeCategory.isChecked;

			// all privileges for a category
			const catPrivileges = Object.keys(privilegeCategory.privileges);

			let query = {};
			if(addingPrivilege) {
				const updatedPrivileges = update(group.privileges, {
					// convert array of privileges keys to object
					$merge: catPrivileges.reduce(
						(accumulator: object, priv) => ({
							...accumulator,
							[priv]: true
						}),
						{}
					)
				});

				query = {
					$set: updatedPrivileges
				};
			} else {
				query = {
					$unset: catPrivileges
				};
			}

			// updating user's privileges will automatically update tree as it
			//	depends on the users privileges
			if(updateState)
				updateState({
					privileges: query
				});
		},
		[availablePrivileges, group, privilegesTree, updateState]
	);

	/**
	 * Handle when a tree item is changed.
	 */
	const privilegesTreeItemCheckboxChangedHandler = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, privilegeKey: string) => {
			event.stopPropagation();

			let query = {};

			const groupPriv = group?.privileges || {};

			// if the privilege is already in the object, then we should remove it to toggle it.
			const addingPrivilege = !(
				(privilegeKey as keyof Privileges) in groupPriv
			);

			if(addingPrivilege) {
				query = {
					[privilegeKey]: { $set: true }
				};
			} else {
				query = {
					$unset: [privilegeKey]
				};
			}

			if(updateState)
				// updating user's privileges will automatically update tree as it
				//	depends on the users privileges
				updateState({
					privileges: query
				});
		},
		[group, updateState]
	);

	/**
	 * TODO
	 */
	// const toggleShowOrAddUsers = useCallback(() => {
	// 	setAddingOrShowingUsers(!isAddingOrShowingUsers);
	// }, [isAddingOrShowingUsers]);

	/**
	 * Open confirmation modal to delete current group
	 */
	const openDeleteGroup = useCallback(() => {
		deleteGroupModal.open({
			actions: [
				{
					text: 'Nej',
					isDefault: true,
					action: (
						originalState: any,
						currentState: any,
						closeModal: any
					) => {
						closeModal();
					}
				},
				{
					text: 'Ja',
					action: (
						originalState: any,
						currentState: any,
						closeModal: any
					) => {
						let alertId = notification('SHOW', {
							priority: AlertPriorityTypes.loading,
							title: 'Grupp tas bort',
							children: `Tar bort ${group?.name}...`
						});

						axios

							.delete(`modules/groups/${group?.id}`)
							.then(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.info,
									title: 'Grupp borttagen',
									children: `Gruppen ${group?.name} har nu bilvit borttagen.`
								});

								// close the confirmation modal
								modal?.close();

								// reload groups list
								if(reloadGroupsList) reloadGroupsList();

								// close the group details modal
								closeModal();

							})
							.catch(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.error,
									title: 'Ett fel har uppstått',
									children: `Det gick inte att ta bort gruppen ${group?.name}.`
								});
							});
					}
				}
			]
		});
	}, [deleteGroupModal, group, modal, notification, reloadGroupsList]);

	// const openRemoveUsers = useCallback(() => {
	// 	removeUsersModal.open({
	// 		actions: [
	// 			{
	// 				text: 'Nej',
	// 				isDefault: true,
	// 				action: (
	// 					originalState: any,
	// 					currentState: any,
	// 					closeModal: any
	// 				) => {
	// 					closeModal();
	// 				},
	// 			},
	// 			{
	// 				text: 'Ja',
	// 				action: (
	// 					originalState: any,
	// 					currentState: any,
	// 					closeModal: any
	// 				) => {
	// 					// TODO
	// 					closeModal();
	// 				},
	// 			},
	// 		],
	// 	});
	// }, [removeUsersModal]);

	/**
	 * Handles when a input field changes, updating state
	 */
	const fieldChangedHandler = useCallback((ev: any, elProps: any) => {
		let newValue = ev.target.value;

		// do some modifications for the value of some fields
		switch(elProps.id) {
			case 'enabled':
				newValue = newValue === 'true' ? true : false;
		}

		if(updateState)
			updateState({
				[elProps.id]: { $set: newValue }
			});
	}, [updateState]);

	/**
	 * Loads group data like members.
	 */
	useEffect(() => {
		if(!group || isCreatingNewGroup) return;

		axios
			.get('modules/users', {
				params: {
					group: group.id,
					query: searchText,
					sort: sort.sortBy,
					order: sort.sortOrder,
					page: pageIndex + 1, // backend counts from page 1
					limit: pageSize
				}
			})

			.then((resp) => {
				const users = resp.data.users;

				setGroupMembers(users);

				const newPaginationState = {
					pageCount: Math.ceil(resp.data.amount / pageSize),
					totalResults: resp.data.amount,
					resultsPerPage: pageSize 
				};
				setPaginationInfo(newPaginationState);

				setIsLoading(false);
			})

			.catch(() => {
				props.modal?.close();
			});
	}, [
		group,
		isCreatingNewGroup,
		modal,
		notification,
		props.modal,
		pageIndex,
		pageSize,
		searchText,
		sort.sortBy,
		sort.sortOrder
	]);

	/**
	 * Fetch all available permissions from backend.
	 */
	useEffect(() => {
		if(isLoading)
			axios
				.get('auth/privileges')
				.then((resp) => {
				// if creating a new group then nothing more should be loaded
					setIsLoading(false);
					setAvailablePrivileges(resp.data);
				})
				.catch(() => {
					modal?.close();
				});
	}, [modal, notification, isLoading]);

	return (
		<>
			{deleteGroupModal.getAsComponent(
				<BasicContentModal
					title={`Du håller på att ta bort gruppen ${group?.name}`}
					text="Är du säker på att du vill ta bort?"
				/>
			)}

			{removeUsersModal.getAsComponent(
				<BasicContentModal
					title="Du håller på att ta bort en eller flera användare"
					text="Är du säker på att du vill ta bort?"
				/>
			)}

			{isLoading && <Loader />}

			<ModalContainer>
				<ScGroup>
					<ScGroupInner>
						<ScGroupInfo>
							<ScFlexSpaceBetween>
								<ControlPanelHeading>
									Gruppinformation
								</ControlPanelHeading>
								{!isLoading && !isCreatingNewGroup && (
									<ScAction onClick={openDeleteGroup}>
										Ta bort Grupp
									</ScAction>
								)}
							</ScFlexSpaceBetween>

							<TextInput
								id="name"
								name="name"
								label="Namn"
								value={group?.name}
								isRequired
								formValidationUnregister={
									formValidation.unregisterElement
								}
								error={formValidation.errors['name']}
								changed={(
									ev: React.ChangeEvent<HTMLInputElement>,
									...data
								) => {
									formValidation.watch(
										ev,
										fieldChangedHandler,
										data
									);
								}}
								inputRef={(ref) =>
									formValidation.registerElement(ref, {
										required: true
              
									})}

							/>
							<Textarea
								id="description"
								name="description"
								label="Beskrivning"
								value={group?.description as string ?? ''}
								changed={fieldChangedHandler}
							/>
						</ScGroupInfo>

						<ScRights>
							<ControlPanelHeading>
								Rättigheter
							</ControlPanelHeading>

							{isLoading ? (
								<SkeletonTree />
							) : (
								<TreeWrapper isHorizontal={true}>
									{privilegesTree.map((category) => (
										<TreeCategory
											key={`CheckboxTreeCategory_${category.id}`}
											id={category.id}
											name={category.name}
											isChecked={category.isChecked}
											isEnabled={category.isEnabled}
											checkboxChanged={
												privilegesTreeCategoryCheckboxChangedHandler
											}
										>
											<ItemsWrapper isHorizontal={false}>
												{!!category.items &&
													category.items.map(
														(item) => (
															<TreeItem
																key={item.key}
																id={item.key}
																icon={item.icon}
																text={item.text}
																isChecked={
																	item.isChecked
																}
																isEnabled={
																	item.isEnabled
																}
																checkboxChanged={
																	privilegesTreeItemCheckboxChangedHandler
																}
															/>
														)
													)}
											</ItemsWrapper>
										</TreeCategory>
									))}
								</TreeWrapper>
							)}
						</ScRights>
					</ScGroupInner>
					{/* <ScLockedElement>
						Låsta Element (kommer senare)
					</ScLockedElement> */}
				</ScGroup>

				<ControlPanelHeading>
					Användare
				</ControlPanelHeading>
				{/* TODO */}
				{/* <ScToggle
					leftToggleIcon={['fal', 'users']}
					leftToggle="Visa användare"
					rightToggleIcon={['fal', 'plus']}
					rightToggle="Lägg till användare"
					isDisabled={false}
					isActive={!isAddingOrShowingUsers}
					clicked={toggleShowOrAddUsers}
				/> */}

				<Search
					hasButton
					searchPlaceholder="Sök på namn, användarnamn eller e-postadress"
					searchBtnClicked={searchButtonClickedHandler}
					isDisabled={isLoading}
					cleared={searchClearedHandler}
				/>

				<ErrorBoundary>
					<Table
						overFlowScroll
						{...getTableProps()}
					>
						<TableHead>
							{headerGroups.map((headerGroup: HeaderGroup) => (
								<div
									style={{ display: 'table-row' }}
									{...headerGroup.getHeaderGroupProps()}
								>
									{headerGroup.headers.map(
										(column: HeaderGroup) => (
											<TableHeading
												isSortable={false}
												isActive={
													sort.sortBy === column.id
												}
												sortOrder={sort.sortOrder}
												{...column.getHeaderProps()}
											>
												{column.render('Header')}
											</TableHeading>
										)
									)}
								</div>
							))}
						</TableHead>

						<TableBody {...getTableBodyProps()}>
							{page.map((row: Row<any>) => {
								prepareRow(row);

								return (
									<TableRow {...row.getRowProps()}>
										{row.cells.map((cell: Cell<Group>) => {
											return (
												<TableCell
													{...cell.getCellProps()}
												>
													{cell.render('Cell')}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</ErrorBoundary>

				<div>
					{isLoading && (
						// Use our custom loading state to show a loading indicator
						<SkeletonTable />
					)}

					<TablePagination
						page={page}
						pageIndex={pageIndex}
						pageCount={pageCount}
						totalResults={paginationInfo.totalResults}
						pageOptions={pageOptions}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
						isDisabled={isLoading}
					/>
				</div>
			</ModalContainer>
		</>
	);
};

export default GroupDetails;

const ScGroup = styled.div`
	display: flex;
`;

const ScGroupInner = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

const ScGroupInfo = styled.div`
	margin-bottom: 32px;
`;

// const ScLockedElement = styled.div`
// 	width: 361px;
// 	margin-left: 32px;
// `;

// const ScToggle = styled(Toggle)`
// 	margin-bottom: 16px;
// `;

// const ScCheckItem = styled(CheckItem)`
// 	visibility: hidden;
// `;

// const ScTableRow = styled(TableRow)`
// 	:hover {
// 		${ScCheckItem} {
// 			visibility: visible;
// 		}
// 	}
// `;

// const ScTableCellCheck = styled(TableCell)`
// 	width: 24px;
// `;
// const ScTableHeadingCheck = styled(TableHeading)`
// 	width: 24px;
// `;

const ScAction = styled.div`
	text-align: right;
	font-size: 12px;
	text-decoration: underline;
	color: var(--red-color);
	text-transform: uppercase;
	margin-left: 24px;
	white-space: nowrap;
	font-weight: 600;
	cursor: pointer;
`;

// const ScTableAction = styled(ScAction)<ScTableActionProps>`
// 	${(props) =>
// 		props.add &&
// 		css`
// 			color: #34df4f;
// 		`}
// `;

const ScFlexSpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
`;

const ScRights = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
