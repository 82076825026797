import axios from '../../../utils/oc-axios';
import {
	setListItemUsers,
	setUser
} from '../../actions/ControlPanel/action-users';
import {
	SetUsersListItemsAction,
	SetUserAction
} from '../../types/ControlPanel/UsersTypes';
import { SortOrderType } from '../../../definitions/Sorting';
import { AjaxResponse, ThunkDispatch } from '../../types/ThunkTypes';
import {
	APIModuleUsersResponse,
	User,
	UserListItem
} from '../../../containers/ControlPanel/Users/model.Users';

/**
 * Fetches all users that matches filters.
 *
 * @param {string} searchText			A text to search users for.
 * @param {string} sortBy				To sort the users after a column, this is the columns name/order object's property name.
 * @param {SortOrderType} sortOrder		If the sorting is ASC or DESC.
 * @param {number} pageIndex			Pagination, the current page in view.
 * @param {number} pageSize				Pagination, how many results per page to show.
 * @returns {Promise<UserListItem[]}
 */
export const fetchAllListItemUsers = (
	searchText: string,
	sortBy: string,
	sortOrder: SortOrderType,
	pageIndex: number,
	pageSize: number
) => {
	return async (dispatch: ThunkDispatch<SetUsersListItemsAction>) => {
		return new Promise<UserListItem[]>((resolve, reject) => {
			axios
				.get('modules/users', {
					params: {
						query: searchText,
						sort: sortBy,
						order: sortOrder,
						page: pageIndex,
						limit: pageSize
					}
				})

				.then((resp: AjaxResponse<APIModuleUsersResponse>) => {
					const users = resp.data.users;
					const amount = parseInt(resp.data.amount);

					dispatch(
						setListItemUsers(users, {
							pageCount: Math.ceil(amount / pageSize),
							totalResults: amount,
							resultsPerPage: pageSize
						})
					);

					resolve(users);
				})

				.catch((err) => {
					console.error('[thunk-users]', err);
					reject(err);
				});
		});
	};
};

/**
 * Fetches a specific user and stores it in state.
 *
 * @param {number} userID The id of the user to fetch.
 * @returns {Promise<User>}
 */
export const fetchUser = (userID: number) => {
	return async (dispatch: ThunkDispatch<SetUserAction>) => {
		return new Promise<User>((resolve, reject) => {
			axios
				.get(`modules/users/${userID}`)
				.then((resp: AjaxResponse<User>) => {
					dispatch(setUser(resp.data));
					resolve(resp.data);
				})
				.catch((err) => {
					console.error('[thunk-users]', err);
					reject(err);
				});
		});
	};
};
