import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import update from 'immutability-helper';
import { parseStringAsObjectPath, isEmptyObject } from 'react-utils';
import TabContainer from '../../../../../components/Tabs/TabContainer';
import Tab from '../../../../Tabs/Tab';
import {
	CheckItem,
	InputRange,
	Select,
	Textarea,
	TextInput
} from '../../../../Forms';
import { PositionGrid, ImageThumbnail, Icon, Button } from '../../../../UI';
import DragDrop from '../../../../../hoc/DragDrop/DragDrop';
import useModal from '../../../../../hooks/Modal/useModal';
import MediaArchive from '../../../../../containers/MediaArchive/MediaArchive';
import Draggable from '../../../../../hoc/DragDrop/Draggable';
import Label from '../../../../Forms/Label/Label';
import ScreenVariants from '../../../../ScreenVariants';
import MainNavigation from '../../../../../containers/MainNavigation/MainNavigation';

const BlockSlideshowSettings = (props) => {
	// The modal hook this component is inside of.
	const { currentState, updateState } = props.modal;

	// The func to trigger when something inside here changed.
	const changed = props.changed;

	// The hook to make formValidation possible
	const formValidation = props.formValidation;

	// The block data to show inside this component
	const block = currentState.data;

	const mediaArchiveModal = useModal();
	const openMediaArchiveModal = mediaArchiveModal.open;
	const closeMediaArchiveModal = mediaArchiveModal.close;
	const getAsComponentMediaArchiveModal = mediaArchiveModal.getAsComponent;

	const mobileImageModal = useModal();
	const openMobileImageModal = mobileImageModal.open;
	const closeMobileImageModal = mobileImageModal.close;
	const getAsComponentMobileImageModal = mobileImageModal.getAsComponent;

	const linkSelectModal = useModal();
	const openLinkSelectModal = linkSelectModal.open;
	const closeLinkSelectModal = linkSelectModal.close;
	const getAsComponentLinkSelectModal = linkSelectModal.getAsComponent;

	// state to keep track of whether individual image settings
	// are collaped or not
	const [showImageSetting, setShowImageSettings] = useState({
		index: 0,
		show: false
	});

	/**
	 * Handles when the state changed like an input change it's value, and updated modal's state
	 *
	 * @param {*} property
	 * @param {*} value
	 * @param {*} preventBuilderPreview
	 */
	const stateChangedHandler = useCallback(
		(property, newValue, preventBuilderPreview) => {
			if(newValue === 'true') newValue = true;
			if(newValue === 'false') newValue = false;

			const suggestedState = parseStringAsObjectPath(property, newValue);

			let updateDetail = {
				updated: Date.now()
			};

			if(property === 'images' && newValue.length < 2) {
				updateDetail = {
					updated: Date.now(),
					show_navigation: false,
					autoplay: false
				};
			}

			// update modal's state
			updateState({
				data: {
					...suggestedState,
					$merge: updateDetail
				}
			}).then((updatedState) => {
				// notify Builder to update the block for preview to work
				if(!preventBuilderPreview) changed(updatedState);
			});
		},
		[changed, updateState]
	);

	/**
	 * Handles when an input changes it's value.
	 *
	 * @param {Event} ev
	 */
	const inputChangedHandler = useCallback(
		(ev, inputProps) =>
			stateChangedHandler(
				ev.target.name,
				ev.target.value,
				inputProps.preventBuilderPreview
			),
		[stateChangedHandler]
	);

	/***
	 * Handles when a checkbox changes it's value.
	 *
	 * @param {Event} ev
	 */
	const checkboxChangedHandler = useCallback(
		(ev, inputProps) =>
			stateChangedHandler(
				ev.target.name,
				ev.target.checked,
				inputProps.preventBuilderPreview
			),
		[stateChangedHandler]
	);

	/**
	 * Will handle the callback when the gear is clicked
	 * Will expand a panel below the row of where the clicked image is located
	 *
	 * @param {object} imgThumbProps	Props that ImageThumbnail received.
	 */
	const showImageSettingHandler = useCallback(
		(imgThumbProps) => {
			if(!isEmptyObject(formValidation.errors)) return;

			setShowImageSettings((setting) => ({
				index: imgThumbProps.index,
				show:
					setting.index === imgThumbProps.index && setting.show
						? false
						: true
			}));

			stateChangedHandler('initial_slide', imgThumbProps.index, false);
		},
		[formValidation.errors, stateChangedHandler]
	);

	const addImagesHandler = useCallback(() => {
		openMediaArchiveModal({
			width: '80%',
			height: '80%',
			isDismissable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					isVisible: false,
					action: (originalState, currentState, closeModal) =>
						closeModal()
				}
			]
		});
	}, [openMediaArchiveModal]);

	/**
	 * Opens a modal with the main menu for slecting an item
	 */
	const openMainMenuSelector = useCallback(() => {
		openLinkSelectModal({
			title: 'Välj mål för länk',
			position: 'right',
			hideBackdrop: false,
			isDismissable: true,
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (originalState, currentState, closeModal) => {
						// Reset builder block settings
						closeModal();
					}
				},
				{
					text: 'Klar',
					action: (originalState, currentState, closeModal) => {
						// Notify builder to update all block's atributes before closing the modal

						closeModal();
					}
				}
			],
			state: {}
		});
	}, [openLinkSelectModal]);

	/**
	 * Navigate to a page in Editor mode
	 *
	 * @param {Event} ev
	 * @param {object} item
	 */
	const menuItemDoubleClickHandler = useCallback(
		(item) => {
			updateState({
				data: {
					images: {
						[showImageSetting.index]: {
							link: {
								uri: {
									$set: item.slug
								}
							}
						}
					}
				}
			});

			closeLinkSelectModal();
		},
		[closeLinkSelectModal, showImageSetting.index, updateState]
	);

	/**
	 * Media Archive returns an array of objects
	 * Updates the state and closes the modal
	 *
	 * @param {array} images
	 */
	const selectImageHandler = useCallback(
		(images) => {
			const slideshowUuid = currentState.data.uuid;

			const slideshowImages = images.map((image) => ({
				alt_text: image.original_name,
				caption: '',
				content_position: 'south',
				image_type: 'slideshow',
				link: {
					new_window: false,
					title: '',
					uri: '',
					uuid: ''
				},
				link_type: 'none',
				media: {
					src: image.src,
					uuid: image.uuid
				},
				mobile_media: {
					thumbnail: null,
					src: null,
					uuid: null
				},
				slideshow_uuid: slideshowUuid,
				theme: 'default',
				uuid: ''
			}));

			const updatedState = update(currentState, {
				data: {
					images: {
						$push: [...slideshowImages]
					}
				}
			});

			stateChangedHandler('images', updatedState.data.images, false);

			closeMediaArchiveModal();
		},
		[closeMediaArchiveModal, currentState, stateChangedHandler]
	);

	/**
	 * Opens a media archive modal to select mobile image
	 */
	const addMobileImagesHandler = useCallback(() => {
		openMobileImageModal({
			width: '80%',
			height: '80%',
			isDismissable: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					isVisible: false,
					action: (originalState, currentState, closeModal) =>
						closeModal()
				}
			]
		});
	}, [openMobileImageModal]);

	/**
	 * Media Archive returns an array of objects
	 * Updates the state and closes the modal
	 *
	 * @param {array} images
	 */
	const selectMobileImageHandler = useCallback(
		(image) => {
			const editedImage = currentState.data.images[showImageSetting.index];

			const updatedImage = {
				...editedImage,
				mobile_media: {
					uuid: image[0].uuid,
					src: image[0].src,
					thumbnail: image[0].thumbnail
				}
			};

			const updatedState = update(currentState, {
				data: {
					images: {
						[showImageSetting.index]: {
							$set: updatedImage
						}
					}
				}
			});

			stateChangedHandler('images', updatedState.data.images, false);
			closeMobileImageModal();
		},
		[
			closeMobileImageModal,
			currentState,
			showImageSetting.index,
			stateChangedHandler
		]
	);

	/**
	 * Removes an image from the slideshow.
	 *
	 * @param {object} imageThumbnailProps		Props  given to ImageThumbnail / the component where the actions are for.
	 */
	const deleteImageHandler = useCallback(
		(imageThumbnailProps) => {
			const itemIndex = imageThumbnailProps.index;

			const updatedState = update(currentState, {
				data: {
					images: {
						$splice: [[itemIndex, 1]]
					}
				}
			});

			stateChangedHandler('images', updatedState.data.images, false);

			if(itemIndex === showImageSetting.index) {
				setShowImageSettings({
					index: null,
					show: false
				});
			}
		},
		[currentState, showImageSetting.index, stateChangedHandler]
	);

	/**
	 * Will expand link settings for specifc image
	 *
	 * @param {*} selectedImage
	 * @param {*} imageIndex
	 */
	const expandLinkSettings = useCallback(
		(selectedImage, imageIndex) => {
			return (
				<>
					<TextInput
						label="Länkadress (URL)"
						name={`images.${imageIndex}.link.uri`}
						id={'link.uri'}
						error={formValidation.errors['link.uri']}
						value={selectedImage.link.uri}
						preventBuilderPreview
						formValidationUnregister={
							formValidation.unregisterElement
						}
						changed={(ev, ...data) => {
							formValidation.watch(ev, inputChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true,
								validation: 'slugurl'
							})}
					/>
					{selectedImage.link_type === 'button' && (
						<TextInput
							label="Knapptext"
							name={`images.${imageIndex}.link.title`}
							id={'link.title'}
							value={selectedImage.link.title}
							changed={inputChangedHandler}
						/>
					)}

					<ScButtonContainer>
						<ScButton
							isPrimary
							isSmall
							onClick={openMainMenuSelector}
						>
							Välj i sidträd
						</ScButton>
					</ScButtonContainer>

					<CheckItem
						type="checkbox"
						title="Öppna länken i ett nytt fönster"
						description="Länken öppnas i ett nytt fönster eller flik"
						name={`images.${imageIndex}.link.new_window`}
						changed={checkboxChangedHandler}
						checked={selectedImage.link.new_window}
						preventBuilderPreview
					/>
				</>
			);
		},
		[
			checkboxChangedHandler,
			formValidation,
			inputChangedHandler,
			openMainMenuSelector
		]
	);

	/**
	 * Expands settings for captions if for a specific image
	 *
	 * @param {*} selectedImage
	 * @param {*} imageIndex
	 */
	const expandCaptionSettings = useCallback(
		(selectedImage, imageIndex) => {
			return (
				<>
					<TextInput
						label="Rubrik"
						name={`images.${imageIndex}.title`}
						id={'title'}
						value={selectedImage.title}
						error={formValidation.errors['title']}
						changed={inputChangedHandler}
					/>

					<Textarea
						label="Beskriving"
						name={`images.${imageIndex}.caption`}
						changed={inputChangedHandler}
						resizeVertical
						value={selectedImage.caption}
					/>
				</>
			);
		},
		[formValidation, inputChangedHandler]
	);

	/**
	 * Calculates which index to inject settings at when a thumbnail is clicked
	 * If the selected image is the first one (0), then the injection index will be
	 * whatever value that "itemsPerRow" holds. For all other cases the default calculation is used
	 *
	 * If to is set to 2 (third item in an array) the following logic then applies
	 * - number 0, to 2 -> returns 2 (will expand settings after first row)
	 * - number 3, to 2 -> returns 4 (will expand settings after second row)
	 */
	const getInjectionIndex = useCallback(
		(itemsPerRow = 3) => {
			const totalItems = block.images.length;
			const lastImageIndex = totalItems - 1;
			const clickedIndex = showImageSetting.index;
			const rowNumberOfItem = Math.ceil((clickedIndex + 1) / itemsPerRow); // Add 1 to clickedIndex as row number starts counting from 1 and not from 0 (index do start from 0).

			let expandAt = itemsPerRow * rowNumberOfItem - 1; // -1 to compensate as index start from 0 and not from 1

			// Set the lastrow index to the last item for those cases where the last row have 1 or 2 items but not 3.
			if(expandAt > lastImageIndex) expandAt = lastImageIndex;

			return expandAt;
		},
		[block.images.length, showImageSetting.index]
	);

	// settings for mobile image thumbnail
	const mobileImageThumbnailActions = useMemo(
		() => [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'gear']}
				           />,
				action: addMobileImagesHandler
			},
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'trash']}
				           />,
				action: () => {
					updateState({
						data: {
							images: {
								[showImageSetting.index]: {
									mobile_media: {
										uuid: { $set: null },
										thumbnail: { $set: null },
										src: { $set: null }
									}
								}
							}
						}
					}).then((updatedState) => {
						// notify Builder to update the block for preview to work
						changed(updatedState);
					});
				},
				styles: { hover: { backgroundColor: '#DA534D' }}
			}
		],
		[addMobileImagesHandler, changed, showImageSetting.index, updateState]
	);

	/**
	 * This function is triggered when clicking on the gear for a specifc image
	 * JSX including image settings within a slide show scope is then returned to the renderer
	 */
	const expandImageSettings = useCallback(() => {
		const imageIndex = showImageSetting.index;
		const selectedImage = block.images[imageIndex];

		let arrowPosition;
		switch(imageIndex % 3) {
			case 0:
				arrowPosition = 'isFirst';
				break;

			case 1:
				arrowPosition = 'isSecond';
				break;

			default:
				arrowPosition = 'isThird';
		}

		const contentPosition =
			selectedImage.content_position === 'none'
				? 'south'
				: selectedImage.content_position;

		return (
			<ScSettingWrapper arrowPosition={arrowPosition}>
				<Label label="Välj variant bild">
					<ScreenVariants
						currentVariant="mobile"
						variants={{ mobile: true }}
						changed={() => void 0}
					>
						<ScImageThumbnail
							height="184px"
							width="100%"
							actions={mobileImageThumbnailActions}
							placeholderClicked={addMobileImagesHandler}
							thumbnail={selectedImage.mobile_media.src}
						/>
					</ScreenVariants>
				</Label>

				<TextInput
					label="Alternativ-text"
					description="The text showing when hover the image. Good for SEO purposes"
					changed={inputChangedHandler}
					name={`images.${imageIndex}.alt_text`}
					value={selectedImage.alt_text}
					maxLength={127}
					preventBuilderPreview
				/>

				<Select
					label="Text"
					name={`images.${imageIndex}.has_caption`}
					changed={inputChangedHandler}
					value={selectedImage.has_caption}
				>
					<option value={false}>
						Dold
					</option>
					<option value={true}>
						Synlig
					</option>
				</Select>

				{selectedImage.has_caption &&
					expandCaptionSettings(selectedImage, imageIndex)}

				<Select
					label="Länk"
					name={`images.${imageIndex}.link_type`}
					changed={inputChangedHandler}
					value={selectedImage.link_type}
				>
					<option value="none">
						Ingen
					</option>
					<option value="on_image">
						På bilden
					</option>
					<option value="button">
						Knapp
					</option>
				</Select>

				{selectedImage.link_type !== 'none' &&
					expandLinkSettings(selectedImage, imageIndex)}

				{(selectedImage.has_caption ||
					selectedImage.link_type === 'button') && 
					(
						<>
							<Select
								label="Bakgrundsfärg"
								name={`images.${imageIndex}.theme`}
								changed={inputChangedHandler}
								value={selectedImage.theme}
							>
								<option value="default">
									Ingen
								</option>
								<option value="lightTransparent">
									Ljus med transparens
								</option>
								<option value="light">
									Ljus
								</option>
								<option value="darkTransparent">
									Mörk med transparens
								</option>
								<option value="dark">
									Mörk
								</option>
							</Select>

							<PositionGrid
								label="Placering av text/knapp"
								name={`images.${imageIndex}.content_position`}
								changed={inputChangedHandler}
								value={contentPosition}
							/>
						</>
					)}
			</ScSettingWrapper>
		);
	}, [
		addMobileImagesHandler,
		block.images,
		expandCaptionSettings,
		expandLinkSettings,
		inputChangedHandler,
		mobileImageThumbnailActions,
		showImageSetting.index
	]);

	/**
	 * Triggered when a drag operation starts
	 *
	 * @param {*} source
	 */
	const dragOver = useCallback(
		(source) => {
			if(showImageSetting.show)
				setShowImageSettings({ index: 0, show: false });
		},
		[showImageSetting.show]
	);

	/**
	 * Will disable other images if form validation has failed for current image
	 *
	 * @param {*} index
	 */
	const isDisabled = useCallback(
		(index) => {
			if(!isEmptyObject(formValidation.errors)) {
				if(showImageSetting.index !== index) return true;
			}

			return false;
		},
		[formValidation.errors, showImageSetting.index]
	);

	/**
	 * Updates the positions when an image is dropped on a horizontal position
	 *
	 * @param {object} source -> the dragged item
	 * @param {object} destination -> the hovered item displaying a valid drop zone
	 * @param {object} instruction -> additional instructions regarding the drop
	 */
	const dragEnd = useCallback(
		(source, destination, instruction) => {
			if(!source || !destination) return;

			const sourceIndex = source.properties.index;
			const destinationIndex = destination.properties.index;
			const sourceImageObject = block.images[source.properties.index];
			const object = {
				images: block.images,
				preventBuilderPreview: false
			};

			const updatedObject = update(object, {
				images: {
					$splice: [
						[sourceIndex, 1],
						[destinationIndex, 0, sourceImageObject]
					]
				}
			});

			stateChangedHandler('images', updatedObject.images, false);
		},
		[block.images, stateChangedHandler]
	);

	// configure button actions on hover
	const imageThumbnailActions = useMemo(
		() => [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'gear']}
				           />,
				text: 'Settings',
				action: showImageSettingHandler
			},
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'trash']}
				           />,
				text: 'Radera',
				action: deleteImageHandler,
				styles: { hover: { backgroundColor: '#DA534D' }}
			}
		],
		[deleteImageHandler, showImageSettingHandler]
	);

	/**
	 * @todo lägg till new window checkbox för bilderna istället för bildspelet, rad ~90
	 * @todo Ska vi ha med "none" här också?, rad ~108
	 */
	return (
		<>
			{getAsComponentMediaArchiveModal(
				<MediaArchive
					mediaChosen={(files) => selectImageHandler(files)}
					fileShowOnly={['image']}
				/>
			)}

			{getAsComponentMobileImageModal(
				<MediaArchive
					mediaChosen={(files) => selectMobileImageHandler(files)}
					fileShowOnly={['image']}
					maxSelectionAmount={1}
				/>
			)}

			{getAsComponentLinkSelectModal(
				<MainNavigation
					itemDoubleClicked={menuItemDoubleClickHandler}
				/>
			)}

			<TabContainer
				disableAllTabsButActive={formValidation.formHasErrors()}
			>
				<Tab title="Innehåll">
					<ScAddImage onClick={addImagesHandler}>
						<ScIcon icon={['fal', 'circle-arrow-up']} />
						<ScAddImageText>
							Lägg till
						</ScAddImageText>
					</ScAddImage>

					<ScDragDrop
						scope="slide-show"
						onDragOver={dragOver}
						onDragEnd={dragEnd}
					>
						{useMemo(
							() =>
								block.images.map((image, index) => {
									return (
										<React.Fragment key={index}>
											<ScDraggable
												id={index}
												key={index}
												index={index}
												scope="slide-show"
												allowedDirection="horizontal"
												isHandle
												className={props.className}
											>
												<ImageThumbnail
													index={index}
													actions={
														imageThumbnailActions
													}
													thumbnail={image.media.src}
													width="100%"
													isDisabled={isDisabled(
														index
													)}
												/>
											</ScDraggable>

											{showImageSetting.show &&
												index === getInjectionIndex() &&
												expandImageSettings()}
										</React.Fragment>
									);
								}),
							[
								block.images,
								expandImageSettings,
								getInjectionIndex,
								imageThumbnailActions,
								isDisabled,
								props.className,
								showImageSetting.show
							]
						)}
					</ScDragDrop>
				</Tab>

				<Tab title="Avancerat">
					{block.images.length < 2 && (
						<ScNotice>
							För att aktivera avancerade inställningar krävs
							minst två bilder i bildspelet.
						</ScNotice>
					)}

					<Select
						label="Animation"
						name={'transition_type'}
						changed={inputChangedHandler}
						value={block.transition_type}
						isDisabled={block.images.length < 2}
					>
						<option value="slide">
							Linjär övergång
						</option>
						<option value="fade">
							Mjuk övergång
						</option>
					</Select>

					<Select
						label="Animationskurva"
						name={'transition_easing'}
						changed={inputChangedHandler}
						value={block.transition_easing}
						isDisabled={block.images.length < 2}
					>
						<option value="slide">
							Ease
						</option>
						<option value="ease-in">
							Ease-in
						</option>
						<option value="ease-out">
							Ease-out
						</option>
						<option value="ease-in-out">
							Ease-in-out
						</option>
						<option value="linear">
							Linear
						</option>
					</Select>

					<CheckItem
						type="checkbox"
						title="Automatisk övergång mellan bilder"
						changed={checkboxChangedHandler}
						name="autoplay"
						value={block.autoplay}
						checked={block.autoplay ? block.autoplay : false}
						isDisabled={block.images.length < 2}
					/>

					{block.autoplay && (
						<>
							<Label
								label="Visningstid"
								description="Antal sekunder mellan de automatiska övergångarna"
							>
								<ScRangeContainer>
									<span>
										{block.transition_delay / 1000}
										s
									</span>
									<InputRange
										name="transition_delay"
										min="2000"
										max="6000"
										step="500"
										changed={inputChangedHandler}
										value={
											block.transition_delay
												? block.transition_delay
												: 3000
										}
									/>
								</ScRangeContainer>
							</Label>

							<Label
								label="Övergångstid"
								description="Antal sekunder som en animering tar"
							>
								<ScRangeContainer>
									<span>
										{block.transition_duration / 1000}
										s
									</span>
									<InputRange
										name="transition_duration"
										min="500"
										max="4000"
										step="500"
										changed={inputChangedHandler}
										value={
											block.transition_duration
												? block.transition_duration
												: 1500
										}
									/>
								</ScRangeContainer>
							</Label>
						</>
					)}

					<CheckItem
						type="checkbox"
						title="Visa föregående och nästa"
						name="show_navigation"
						changed={checkboxChangedHandler}
						isDisabled={block.images.length <= 1}
						checked={
							block.show_navigation
								? block.show_navigation
								: false
						}
					/>
				</Tab>
			</TabContainer>
		</>
	);
};

BlockSlideshowSettings.propTypes = {
	modal: PropTypes.object,
	changed: PropTypes.func,
	formValidation: PropTypes.object
};

export default BlockSlideshowSettings;

const ScSettingWrapper = styled.div`
	padding: 16px;
	width: 100%;
	position: relative;
	background: var(--bg-bright-color);
	margin-top: 8px;
	margin-bottom: 24px;
	&:after {
		bottom: 100%;

		${(props) =>
		props.arrowPosition === 'isFirst' &&
			css`
				left: 17%;
			`}

		${(props) =>
		props.arrowPosition === 'isSecond' &&
			css`
				left: 50%;
			`}

		${(props) =>
		props.arrowPosition === 'isThird' &&
			css`
				left: 83%;
			`}

		border: solid transparent;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-bottom-color: var(--bright-color);
		border-width: 16px;
		margin-left: -16px;
	}
`;

const ScImageThumbnail = styled(ImageThumbnail)`
	background-color: var(--bg-bright-color);
	padding: 0;

	#ScBorder {
		border: 1px solid var(--bg-dark-grey-color);
	}

	:hover {
		#ScBorder {
			border: 1px solid var(--bg-dark-grey-color);
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

const ScDragDrop = styled(DragDrop)`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	cursor: move;
`;

const ScRangeContainer = styled.div`
	display: grid;
	grid-template-columns: 10% 90%;

	& span {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 4px;
	}
`;

const ScIcon = styled(Icon)`
	color: #c4c4c4;
	transition: color ease 0.3s;
`;

const ScAddImageText = styled.div`
	color: #c4c4c4;
	text-align: center;
	font-size: 14px;
	transition: color ease 0.3s;
`;
const ScAddImage = styled.div`
	border: 1px dashed #c4c4c4;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 4px;
	transition: border ease 0.3s;
	padding: 8px;
	margin-bottom: 24px;

	&:last-child {
		margin-bottom: 0;
	}

	:hover {
		cursor: pointer;
		color: #333;
		border: 1px dashed #333;
		${ScIcon} {
			color: #333;
		}
		${ScAddImageText} {
			color: #333;
		}
	}
`;

const ScDraggable = styled(Draggable)`
	position: relative;
	width: 33.3333%;
	display: block;
	flex: none;
`;

const ScNotice = styled.div`
	margin-bottom: 16px;
	padding: 8px;
	background: rgba(115, 210, 243, 0.8);
	box-shadow: 0px 16px 40px -12px rgba(115, 210, 243, 0.32);
`;

const ScButtonContainer = styled.div`
	position: relative;
	margin-bottom: 24px;
`;

const ScButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 3px;
`;
