import React from 'react';
import styled from 'styled-components/macro';
import { TreeItemProps } from './model.TreeItem';
import { CheckItem } from '../../Forms';
import { Tooltip, Icon } from '../../UI';

const TreeItem: React.FC<TreeItemProps> = (props) => {
	const { id, text, icon, checkboxChanged, isChecked, isEnabled } = props;

	/**
	 * Triggered when changing checkbox.
	 */
	const checkboxChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		if(checkboxChanged) checkboxChanged(event, id);
	}, [checkboxChanged, id]);

	return (
		<ScCheckItemWrapper className={props.className}>
			<CheckItem
				isSmall={true}
				title={text}
				type="checkbox"
				checked={isChecked}
				isDisabled={!isEnabled}
				isNotClickable={!isEnabled}
				changed={checkboxChangedHandler}
			/>

			<ScCheckItemStatusWrapper>
				{icon && (
					<Tooltip
						isOver
						text={icon.tooltipText}
						isCenter
					>
						<Icon
							icon={icon.icon}
							color={icon.color}
							style={{ width: '8px', height: '8px' }}
						/>
					</Tooltip>
				)}
			</ScCheckItemStatusWrapper>
		</ScCheckItemWrapper>
	);
};

export default TreeItem;

const ScCheckItemWrapper = styled.div`
	position: relative;
`;

const ScCheckItemStatusWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: -8px;
	transform: translate(-100%, -50%);
	height: 8px;
	width: 8px;
`;
