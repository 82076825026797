import update from 'immutability-helper';
import {
	UsersState,
	SetUsersListItemsAction,
	SetUserAction
} from './../../types/ControlPanel/UsersTypes';
import { SET_LISTITEM_USERS, SET_USER } from '../../actions/actionTypes';

const initialState: UsersState = {
	// all users in a summarized format (UserListItem instead of a full User object)
	listItemUsers: [],

	// stores a single user, will be the last one fetched with fetchUser
	currentUser: null,

	// the current state of the pagination.
	paginationInfo: {
		// total amount of pages available.
		pageCount: 0,

		// total amount of results in all pages.
		totalResults: 0,

		// how many items/results there are showed in a page.
		resultsPerPage: 0
	}
};

let updatedState;

/**
 * Handles the action with new state's data and create a new state to store.
 *
 * @param {object} state The new state to store.
 * @param {object} action The action data the reducer will use to calculate new state.
 */
const usersManagement = (
	state = initialState,
	action: SetUsersListItemsAction | SetUserAction
) => {
	switch(action.type) {
		case SET_LISTITEM_USERS:
			updatedState = update(state, {
				listItemUsers: {
					$set: action.users
				},

				paginationInfo: {
					$set: action.paginationInfo
				}
			});

			return updatedState;

		case SET_USER:
			return update(state, {
				currentUser: {
					$set: action.user
				}
			});

		default:
			return state;
	}
};

export default usersManagement;
