import { Text } from 'slate';
import escapeHtml from 'escape-html';
import { serializeMarks } from './serialize-helper';
import { serializeAlignments } from '../../custom/components/AlignmentButton/alignments-api';
import {
	BULLETED_LIST,
	HEADING_FOUR,
	HEADING_ONE,
	HEADING_THREE,
	HEADING_TWO,
	LINK,
	LIST_ITEM,
	NUMBERED_LIST,
	PARAGRAPH
} from '../../OC7Editor';

/**
 * Function that serves a HTML serializer
 * It will loop through Slate nodes, wrap specific text nodes in marks, and map block/inline to an appropriate element
 *
 * @param {Node} node
 * @returns {Node | string}
 */
export const serialize = (node) => {
	// If the node is a text node, then just return it
	if(Text.isText(node)) {
		return node.text;
	}

	// Serializ the marks (e.g. i, b, u)
	// This function will HTML serialize the Slate Object
	// e.g. "bold" becomes <b>[contents]</b>
	const childrenSerializedWithMarks = serializeMarks(node.children);

	// Slate does not support Element nodes without at least one text node
	// Thus internally we use \n for Slate... but when stored in HTML we will be using <br/>
	// So here we will be serializing the text nodes with marks and convert \n to <br/>
	const children = childrenSerializedWithMarks
		.map((n) => serialize(n))
		.join('')
		.replace(/\n/g, '<br/>');

	// Switch-case that will serialize block/inline elements to HTML
	// Elements that should listen to alignment rules are processed with serializeAlignments to determine appropriate setting
	// Alignsments are currently added inline
	switch(node.type) {
		case PARAGRAPH:
			return `<p${serializeAlignments(node)}>${children}</p>`;

		case LINK:
			if(!children) return '';
			let href = node.href;
			let targetBlank = '';

			// In case the link is an advanced link, then we will use the data-hash attribute to store the link
			if(node.uri_type === 'advanced') {
				return `<a data-hash="${escapeHtml(href)}" ${node.button_class ? 'class=\'' + escapeHtml(node.button_class) + '\'' : ''}">${children}</a>`;
			}

			switch(true) {
				case node.uri_type === 'email' && !href.includes('mailto'):
					href = `mailto:${href}`;
					break;

				case node.uri_type === 'phone' && !href.includes('tel'):
					href = `tel:${href}`;
					break;

				default:
					if(node.new_window) {
						targetBlank = ' target="_blank"';
					}
			}

			return `<a href="${escapeHtml(href)}"${targetBlank} ${node.button_class ? 'class=\'' + escapeHtml(node.button_class) + '\'' : ''}">${children}</a>`;

		case NUMBERED_LIST:
			return `<ol${serializeAlignments(node)}>${children}</ol>`;

		case BULLETED_LIST:
			return `<ul${serializeAlignments(node)}>${children}</ul>`;

		case LIST_ITEM:
			return `<li>${children}</li>`;

		case HEADING_ONE:
			return `<h1${serializeAlignments(node)}>${children}</h1>`;

		case HEADING_TWO:
			return `<h2${serializeAlignments(node)}>${children}</h2>`;

		case HEADING_THREE:
			return `<h3${serializeAlignments(node)}>${children}</h3>`;

		case HEADING_FOUR:
			return `<h4${serializeAlignments(node)}>${children}</h4>`;

		default:
			return children;
	}
};
