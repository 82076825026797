import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import {
	getTreeRootItems,
	renderTableRowWithTable,
	renderSubLevelComponent
} from './funcs.Settings';
import { SettingsProps } from './model.Settings';
import SettingsDetails from './SettingDetails/SettingDetails';
import { RootState } from '../../../store/types/RootTypes';
import {
	Setting,
	SettingInputTypes,
	SettingsInitialState,
	SettingsList,
	UpdatedSettings
} from '../../../store/types/ControlPanel/SettingsTypes';
import useFormValidation from '../../../hooks/useFormValidation/useFormValidation';
import useModal from '../../../hooks/Modal/useModal';
import useAuth from '../../../hooks/useAuth/useAuth';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import {
	fetchAllSettings,
	updateSettings
} from '../../../store/thunks/ControlPanel/thunk-settings';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import { Icon } from '../../../components/UI';
import { SkeletonTable } from '../../../components/Skeletons';
import Search from '../../../components/Search/Search';
import { SettingsPrivileges } from '../../../definitions/Privileges';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import { CloseModal } from '../../../hooks/useModal/useModal.model';
import { NewMediaFile } from '../../../definitions/Media';

const Settings: React.FC<SettingsProps> = (props) => {
	const dispatch = useDispatch<any>();

	const settingState: SettingsInitialState = useSelector(
		(state: RootState) => state.controlPanelSettings
	);

	const notification = useAlert()[1] as AlertDispatch;
	const formValidation = useFormValidation();

	const { verifyUserPermission } = useAuth();

	const [searchWord, setSearchWord] = React.useState<string>('');

	/** List of items that are curremtly collapsed */
	const [collapsedItems, setCollapsedItems] = React.useState<Array<string>>(
		[]
	);

	// Show skeleton while fetch is loading
	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	// if the current logged-in user is have advance setting privilege.
	const userIsAdvanced = React.useMemo(
		() => verifyUserPermission(SettingsPrivileges.OPTIONS_ADVANCED),
		[verifyUserPermission]
	);

	/**
	 * Used to recursivily create a new object that is used to display options when a filter is set
	 */
	const applyFilterToSettingsOptions = React.useCallback((): SettingsList => {
		// Editable items that match
		const matches = Object.entries(settingState.list).reduce(
			(prev: SettingsList, current) => {
				const key = current[0];
				const currentObject = current[1];
				const name = currentObject.name;
				const displayName = currentObject?.display_name;
				const comment = currentObject?.comment;
				const defaultValue = currentObject?.default;
				const overrideValue = currentObject?.override;
				const value = currentObject?.value;

				const values = [
					name,
					displayName,
					comment,
					defaultValue,
					overrideValue,
					value
				]
					.filter((v) => v !== undefined)
					.some((v) =>
						String(v).toLowerCase().trim().includes(searchWord));

				if(key && values) {
					prev[key] = {
						...current[1]
					};
				}
				return prev;
			},
			{}
		);

		// Get Relations for the matching elements
		const getRelations = (matches: SettingsList): SettingsList => {
			const parents = Object.keys(settingState.list).reduce(
				(prev: SettingsList, currentKey) => {
					// Get's the current object in the state from it's key
					const obj = settingState.list[currentKey];

					// If the key is not already matched
					if(!(currentKey in matches)) {
						const children = obj.children;

						// Check if the children contains at least one of the keys we are looking for
						if(
							children.some((key) =>
								Object.keys(matches).includes(key))
						) {
							// Create a new array where we update the children references that match
							// E.g. if children contains [apiKey, page] and apiKey is the search word, then get rid page
							// Final result then [apiKey]
							const updatedChildren = children.filter(
								(child) => child in matches
							);

							// Update the children's object immutable
							const updatedObject = update(obj, {
								children: {
									$set: updatedChildren
								}
							});

							// Update the previous object immutable
							prev = update(prev, {
								[currentKey]: {
									$set: updatedObject
								}
							});
						}
					}

					return prev;
				},
				{}
			);

			// we got to root
			const allMatchesAtRoot = Object.values(parents).every(
				(item) => item.is_root
			);

			const currentIteration = { ...parents, ...matches };

			// if all affected elements recursivily got to root, return it
			if(allMatchesAtRoot) return currentIteration;

			// Otherwise keep going
			return getRelations(currentIteration);
		};

		// Trigger the relational search
		return getRelations(matches);
	}, [searchWord, settingState.list]);

	const tableData = React.useMemo(() => {
		let list = settingState.list;
		if(searchWord) list = applyFilterToSettingsOptions();

		// for all settings of type MEDIA, populate them to get the complete info from backend.
		return new Map<string, Setting>(Object.entries(list));
	}, [applyFilterToSettingsOptions, searchWord, settingState.list]);

	/**
	 * Fetch data and hide loading skeleton.
	 */
	React.useEffect(() => {
		dispatch(fetchAllSettings()).then(() => setIsLoading(false));
	}, [dispatch]);

	const settingsDetailsModal = useModal();

	/**
	 * Toggle the collapsed state of an item.
	 */
	const toggleCollapsableItem = React.useCallback(
		(key: string) => {
			// index int he array of the items if its already there
			const itemIndex = collapsedItems.findIndex((item) => item === key);
			let updatedState = collapsedItems;

			if(itemIndex >= 0) {
				// the item is collapsed as it is in the array and has an index.
				// 	to toggle the item we have to remove it.
				updatedState = update(updatedState, {
					$splice: [[itemIndex, 1]]
				});
			} else {
				// to toggle the item we have to add it
				updatedState = update(updatedState, {
					$push: [key]
				});
			}

			setCollapsedItems(updatedState);
		},
		[collapsedItems]
	);

	/*
	 * Triggered when the filter input value changes
	 *
	 * @param {React.KeyboardEvent<HTMLInputElement>} ev
	 * @returns {void}
	 */
	const filterChangedHandler = (
		ev: React.KeyboardEvent<HTMLInputElement> | undefined
	) => {
		const elem = ev?.target as HTMLInputElement;
		const value = elem?.value ?? '';

		setSearchWord(value.toLowerCase().trim());
	};

	/**
	 * Opens a modal with a Setting details, to change it.
	 */
	const openSettingsDetails = useCallback((settingKey: any) => {
		const setting = tableData.get(settingKey);
		const name = userIsAdvanced ? setting?.name : setting?.display_name;

		settingsDetailsModal.open({
			title: `Redigerar ${name}`,
			width: '480px',
			isDismissable: 'true',
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (
						originalState: any,
						currentState: any,
						closeModal: any
					) => {
						formValidation.resetErrors();
						closeModal();
					}
				},
				{
					text: 'Spara',
					action: (
						originalState: Setting,
						currentState: Setting,
						closeModal: CloseModal
					) => {
						formValidation.submit(() => {
							if(!currentState.wasChanged) {
								closeModal();
								return;
							}

							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.loading,
								title: 'Uppdaterar inställningen',
								children: 'Sparar...'
							});

							// updatedSettings to send to backend
							const updatedSetting: UpdatedSettings = {
								options: [
									update(currentState, {
										// set the value to save here in 'override'
										// as this is the prop used by backend tor ead the data.
										override: {
											$apply: () => {
												switch(currentState.type) {
													case SettingInputTypes.MEDIA:
														const mediaValue = currentState.value as NewMediaFile;

														// if removed the media then return null;
														if(!mediaValue)
															return null;

														return mediaValue.uuid;
													default:
														return currentState.value;
												}
											}
										},
										wasChanged: { $set: false }
									})
								]
							};

							// Update state
							dispatch(updateSettings(updatedSetting))
								.then(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.info,
										title: 'Inställning sparad',
										children: 'Inställningen har uppdaterats'
									});

									closeModal();
								})
								.catch(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.error,
										title:
												'Ett eller flera fel hittades',
										children: 'Det gick inte att uppdatera inställningen.'
									});
								});
						});
					}
				}
			],
			state: setting
		});
	}, [
		dispatch,
		formValidation,
		notification,
		settingsDetailsModal,
		tableData,
		userIsAdvanced
	]);

	return (
		<>
			{settingsDetailsModal.getAsComponent(
				<SettingsDetails formValidation={formValidation} />
			)}
			<ScModuleContainer header="Inställningar">
				{isLoading ? (
					<SkeletonTable />
				) : (
					<>
						<ScSearch
							searchPlaceholder="Sök på kolumnfälten"
							changed={filterChangedHandler}
							cleared={filterChangedHandler}
							isDisabled={isLoading}
						/>

						<ErrorBoundary>
							{getTreeRootItems(tableData).map((rootSetting) => {
								const sectionKey = `Section_${rootSetting.display_name}`;
								const isSectionCollapsed = collapsedItems.includes(
									sectionKey
								);

								const sectionContent =
									rootSetting.children.length > 0 ? (
										<>
											{renderTableRowWithTable(
												tableData,
												rootSetting,
												openSettingsDetails,
												userIsAdvanced
											)}
											{renderSubLevelComponent(
												tableData,
												rootSetting,

												userIsAdvanced,
												collapsedItems,

												openSettingsDetails,
												toggleCollapsableItem
											)}
										</>
									) : (
										renderTableRowWithTable(
											tableData,
											rootSetting,
											openSettingsDetails,
											userIsAdvanced
										)
									);

								return (
									<ScSection key={sectionKey}>
										<ScSectionTitle
											onClick={() =>
												toggleCollapsableItem(
													sectionKey
												)}
										>
											<ScCollapse>
												<Icon
													icon={[
														'fal',

														isSectionCollapsed
															? 'chevron-up'
															: 'chevron-down'
													]}
												/>
											</ScCollapse>
											{rootSetting.display_name}
										</ScSectionTitle>

										{!isSectionCollapsed && sectionContent}
									</ScSection>
								);
							})}
						</ErrorBoundary>
					</>
				)}
			</ScModuleContainer>
		</>
	);
};

export default withErrorBoundary(Settings);

const ScModuleContainer = styled(ModuleContainer)`
	display: flex;
	flex-direction: column;
`;

const ScSearch = styled(Search)`
	margin-bottom: 24px;
`;

const ScSection = styled.div`
	border-bottom: 1px solid #ddd;
	padding-bottom: 24px;
	margin-bottom: 24px;

	:last-child {
		border-bottom: 0px solid #ddd;
		padding-bottom: 0;
		margin-bottom: 0;
	}
`;

const ScSectionTitle = styled.div`
	display: inline-block;
	font-size: 14px;
	margin-bottom: 8px;
	font-weight: 600;
	cursor: pointer;
`;

const ScCollapse = styled.div`
	margin-right: 8px;
	display: inline-block;
`;
