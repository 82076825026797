import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useMemo
} from 'react';
import * as Sentry from '@sentry/browser';
import { useSelector, useDispatch } from 'react-redux';
import HtmlToReact, { Parser } from 'html-to-react';
import { FrameContextConsumer } from 'react-frame-component';
import styled, { css, StyleSheetManager } from 'styled-components/macro';
import { isEmptyObject } from 'react-utils';
import { default as ObjectHash } from 'object-hash';
import { useParams, useNavigate } from 'react-router-dom';
import useModal from './../../hooks/Modal/useModal';
import useAlert, { AlertPriorityTypes } from './../../hooks/useAlert';
import axios from '../../utils/oc-axios';
import DragDrop from '../../hoc/DragDrop/DragDrop';
import withErrorBoundary from '../../hoc/withErrorBoundary';
import useFormValidation from '../../hooks/useFormValidation';
import {
	setBuilderViewVariant,
	toggleBuilderVariantRowVisibility
} from '../../store/actions/action-config';
import {
	deleteBlockThunk,
	fetchContents,
	updateBlockContentThunk
} from '../../store/thunks/thunk-builder';
import {
	createRowSetToArea,
	createRowSetToEmptyArea,
	createBlockInColumn,
	dndCreateColumnSetToRow,
	dndCreateRowSetToArea,
	dndMoveBlockWithinAnyColumn,
	dndMoveRowVertically,
	changeColumnWidth,
	cleanState,
	setRowVisibilitySettings,
	setRowWidthSettings,
	setRowReverseSettings,
	setPageSettings,
	updateSavehash
} from '../../store/actions/action-builder';
import Loader from '../../components/UI/Loaders/Loader';
import { Icon, BasicContentModal } from '../../components/UI';
import BlockDefinitions from '../../components/Builder/BlockDefinitions';
import SelectMenuItem from '../../components/Dialogs/SelectMenuItem';
import { updatePage } from '../../store/thunks/thunk-cache';
import PublishModal from '../../components/Builder/PublishModal';
import PublicationStatus from '../../components/Builder/PublicationStatus';
import CustomInput from '../../components/Builder/CustomInput';
import BlockArea from '../../components/Builder/BlockArea';
import Iframe from '../../components/Builder/Iframe';
import ChooseBlockDialog from '../../components/Dialogs/ChooseBlockDialog';
import usePageLanguage from '../../hooks/usePageLanguage/usePageLanguage';
import { SUPPORTED_LANGUAGES } from '../../settings';
import { updateAcceptLanguageHeader } from '../../hooks/useLanguageSelection/useLanguageSelection';

const htmlToReactParser = new Parser();
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

const Builder = (props) => {
	// check so that opts are of type array
	if(props.opts && !Array.isArray(props.opts)) {
		console.warn('[Builder] I Expect "opts" to be an array of objects');
	}

	// modal used for asking if you really want to close without saving
	const changesMadeModal = useModal();

	// Returns the active page language
	const { activeLanguage } = usePageLanguage();

	// modal used for setting publish date
	const publishModal = useModal();
	const openPublishModal = publishModal.open;
	const getAsComponentPublishModal = publishModal.getAsComponent;

	// modal used for opening blockSettings
	const blockSettingsModal = useModal();
	const openBlockSettingsModal = blockSettingsModal.open;
	const closeBlockSettingsModal = blockSettingsModal.close;
	const getAsComponentBlockSettingsModal = blockSettingsModal.getAsComponent;

	// form validation for block settings dialogues
	const blockSettingsFormValidation = useFormValidation();

	const linkToolModal = useModal();
	const openLinkToolModal = linkToolModal.open;
	const getAsComponentLinkToolModal = linkToolModal.getAsComponent;

	// form validation for link tool
	const linkToolModalFormValidation = useFormValidation();

	// add block modal
	const addBlockModal = useModal();
	const openAddBlockModal = addBlockModal.open;
	const getAsComponentAddBlockModal = addBlockModal.getAsComponent;

	const notification = useAlert()[1];

	// sets a state whether the page is fully loaded or not
	const [isPageLoaded, setPageLoaded] = useState(false);

	// An object of key/value pairs of the dynamic params from the current URL
	// used to get the page uuid
	const params = useParams();

	// Function that lets you navigate programmatically
	const navigate = useNavigate();

	// select the current builder template
	const editor = useSelector((state) => state.builder);

	// declare redux dispatcher
	const dispatch = useDispatch();

	// fetches builder configuration
	const builderConfig = useSelector((state) => state.config.builder);

	// fetches the view type in use, e.g. desktop | mobile
	const viewInUse = builderConfig.variants.current;

	// fetches the different view varients/devices possible
	const viewVariants = builderConfig.variants;

	// fetches whether hidden block rows should be rendered but disabled or not
	const viewHiddenBlockRowsForVariant = builderConfig.variants.showHiddenRows;

	// Render block settings if opened dinamically for the correct block
	let blockSettingsModalContent = useRef();

	// checks if there are unsaved changes, when populating the save, the reducer calculates the saveHash
	const hasUnsavedChanges = useMemo(() => {
		// prevent crash when state is empty on first render.
		if(!editor.elements) return false;

		return editor.saveHash !== ObjectHash(editor.elements);
	}, [editor.elements, editor.saveHash]);

	/**
	 * componentDidMount
	 * Fetches the page specifik block information
	 */
	useEffect(() => {
		// If a language is set in the URL and the active language hasn't been changed 
		// to the language from the URL yet we need to update the Accept-Language header 
		// in order to fetch the correct content
		if(params.lang && params.lang !== activeLanguage) { 
			updateAcceptLanguageHeader(params.lang);
			return;
		}
		
		dispatch(fetchContents(params.uuid));

		// Clean redux state
		return () => {
			dispatch(cleanState({}));
		};
	}, [activeLanguage, dispatch, params.lang, params.uuid]);

	/**
	 * Triggered when a page is saved
	 * This will fully apply and store all changes immidiately
	 */
	const saveHandler = useCallback(
		(showNotification = true) => {
			return new Promise((resolve, reject) => {
				let alertId;
				if(showNotification) {
					alertId = notification('SHOW', {
						priority: AlertPriorityTypes.info,
						// title: '💾 Sparar sida...',
						title: 'Sparar sida...'
					});
				}

				axios
					.post(`/pages/contents/${params.uuid}`, {
						elements: editor.elements
					})
					.then(() => {
					// store the hash of the current page content, for comparing if the page has unsaved changes.
						dispatch(updateSavehash());

						if(showNotification)
							notification('MODIFY', {
								alertID: alertId,
								priority: AlertPriorityTypes.success,
								// title: '👌 Sidan har sparats',
								title: 'Sidan har sparats'
							});

						resolve();
					})
					.catch((err) => {
						Sentry.captureException(err);

						let errorMsg = 'Ett oväntad fel har uppstått';

						if(err.response) {
							const { response } = err;
							errorMsg = `${response.status}: ${response.statusText} - ${response.data.error?.message}`;
						}

						if(showNotification)
							notification('MODIFY', {
								alertID: alertId,
								priority: AlertPriorityTypes.error,
								// title: `🤷 Något har gått fel...`,
								title: 'Något har gått fel...',
								children: errorMsg
							});

						reject(err);
					});
			});
		},
		[dispatch, editor.elements, notification, params.uuid]
	);

	/**
	 * Triggered when cancelling edit mode
	 * This action will close the editor
	 */
	const cancelHandler = useCallback(() => {
		// check if there are unsaved changes.
		if(hasUnsavedChanges) {
			changesMadeModal.open({
				actions: [
					{
						text: 'Nej',
						action: (
							originalState,
							currentState,
							closeModal
						) => {
							navigate(`/page/${params.uuid}`);
							closeModal();
						}
					},
					{
						text: 'Avbryt',
						isDefault: true,
						action: (
							originalState,
							currentState,
							closeModal
						) => {
							closeModal();
						}
					},
					{
						text: 'Ja',
						action: (
							originalState,
							currentState,
							closeModal
						) => {
							closeModal();
							saveHandler().then(() =>
								navigate(`/page/${params.uuid}`));
						}
					}
				]
			});
		} else {
			navigate(`/page/${params.uuid}`);
		}
	}, [changesMadeModal, hasUnsavedChanges, params.uuid, saveHandler, navigate]);

	/**
	 * Handles when the publishing of the page is changed in the publishModal
	 */
	const publishChangedHandler = useCallback((data) => {
		const pageObj = {
			...editor.settings,
			...data
		};

		// Dispatch update using thunk that will do all background work for uss.
		return dispatch(updatePage(params.uuid, pageObj));
	}, [dispatch, editor.settings, params.uuid]);

	/**
	 * Will set a state that allows hidden block rows to be visible but disabled
	 * or simply excluded from rendering
	 */
	const toggleHiddenBlockRowsHandler = () => {
		try {
			dispatch(toggleBuilderVariantRowVisibility());
		} catch(error) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: '🤷 Det gick inte att byta synlighetsstatus för att visa/dölja blockrader.',
				children: 'Om felet kvarstår, vänligen kontakta administratör.'
			});

			Sentry.captureException(error);
		}
	};

	/**
	 * Helps adding a new block to a block area
	 * @To-Do: Outsource all block state logic to other file as this logic should practically never be touched and takes up too much space here
	 *
	 * @param area
	 * @param rowId
	 * @param columnId
	 * @param blockId
	 * @param index
	 * @param type
	 * @returns {boolean}
	 */
	const selectBlockTypeHandler = useCallback(
		(areaId, rowId, columnId, blockId, index, type) => {
			try {
				switch(true) {
					// will be called when adding a new row set to an empty area
					case !rowId:
						dispatch(createRowSetToEmptyArea(areaId, type));
						break;

					// will be called when adding a new row set to an area that is not empty
					case !columnId:
						dispatch(createRowSetToArea(areaId, index, type));
						break;

					// will be called when adding a block to an existing column
					default:
						dispatch(
							createBlockInColumn(rowId, columnId, index, type)
						);
				}
			} catch(error) {
				Sentry.captureException(error);

				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: '🤷 Det gick inte att skapa ett block här.',
					children:
						'Om felet kvarstår, vänligen kontakta administratör.'
				});

				console.error(error);
			}
		},
		[dispatch, notification]
	);

	/**
	 * Handles state of visibility of modal that takes care of adding new blocks
	 *
	 * @param ev
	 */
	const addBlockHandler = useCallback(
		(areaId, rowId, columnId, blockId, index) => {
			try {
				openAddBlockModal({
					title: 'Lägger till block',
					actions: [
						{
							text: 'Stäng',
							isDefault: true,
							isVisible: false,
							action: (originalState, currentState, closeModal) =>
								closeModal()
						}
					],
					isDismissable: true,
					position: 'center',
					width: '416px',
					state: {
						allowedBlocktypes: editor.blockTypes,
						blockTypeSelected: (modal, blockType) => {
							selectBlockTypeHandler(
								areaId,
								rowId,
								columnId,
								blockId,
								index,
								blockType
							);

							modal.close();
						}
					}
				});
			} catch(error) {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: '🤷 Det gick inte att öppna modal för val av block.',
					children:
						'Om felet kvarstår, vänligen kontakta administratör.'
				});

				Sentry.captureException(error);
			}
		},
		[
			editor.blockTypes,
			notification,
			openAddBlockModal,
			selectBlockTypeHandler
		]
	);

	/**
	 * Handle updating the block in the builder when uppdated from the Settings modal.
	 * @todo: Outsource to state handler
	 *
	 * @param {*} updatedBlock
	 */
	const blockChangedHandler = useCallback(
		(updatedBlock) => {
			dispatch(updateBlockContentThunk(updatedBlock)).catch((error) => {
				console.error(error);

				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: '🤷 Det gick inte att genomföra ändringen för det här blocket.',
					children:
						'Om felet kvarstår, vänligen kontakta administratör.'
				});

				Sentry.captureException(error);
			});
		},
		[dispatch, notification]
	);

	/**
	 * Deletes a block from it's position
	 * A recursive deletion down to area level can occure if necessary
	 *
	 * @param {object} block
	 */
	const deleteBlockHandler = useCallback(
		(block) => {
			closeBlockSettingsModal();

			dispatch(deleteBlockThunk(block)).catch((error) => {
				console.error(error);

				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: '🤷 Det gick inte att ta bort det här blocket.',
					children:
						'Om felet kvarstår, vänligen kontakta administratör.'
				});

				Sentry.captureException(error);
			});
		},
		[closeBlockSettingsModal, dispatch, notification]
	);

	/**
	 * Hides a block row in desktop or mobile
	 *
	 * @param {object} row
	 * @param {string} variantType
	 */
	const hideBlockRowHandler = useCallback(
		(row, variantType) => {
			try {
				dispatch(setRowVisibilitySettings(row.key, variantType));
			} catch(error) {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: '🤷 Det gick inte att ändra visningsinställning på den här blockraden.',
					children:
						'Om felet kvarstår, vänligen kontakta administratör.'
				});

				Sentry.captureException(error);
			}
		},
		[dispatch, notification]
	);

	/**
	 * Changes the width of the row
	 *
	 * @param {object} row
	 * @param {boolean} hasMaxWidth
	 */
	const setBlockRowWidthHandler = useCallback((row, hasMaxWidth) => {
		try {
			dispatch(setRowWidthSettings(row.key, hasMaxWidth));
		} catch(error) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: '🤷 Det gick inte att ändra visningsinställning på den här blockraden.',
				children: 'Om felet kvarstår, vänligen kontakta administratör.'
			});

			Sentry.captureException(error);
		}
	}, [dispatch, notification]);

	/**
	 * Changes the width of the row
	 *
	 * @param {object} row
	 * @param {boolean} isReversed
	 */
	const setBlockRowReverseHandler = useCallback((row, isReversed) => {
		try {
			dispatch(setRowReverseSettings(row.key, isReversed));
		} catch(error) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: '🤷 Det gick inte att ändra visningsinställning på den här blockraden.',
				children: 'Om felet kvarstår, vänligen kontakta administratör.'
			});

			Sentry.captureException(error);
		}
	}, [dispatch, notification]);

	/**
	 * Changes the column width of a row set
	 *
	 * @param {string} rowId
	 * @param {string} setting
	 */
	const setColumnWidthHandler = useCallback(
		(rowId, setting) => {
			try {
				dispatch(changeColumnWidth(rowId, setting));
			} catch(error) {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: '🤷 Det gick inte att ändra kolumnlayoyt på den här blockraden.',
					children:
						'Om felet kvarstår, vänligen kontakta administratör.'
				});

				Sentry.captureException(error);
			}
		},
		[dispatch, notification]
	);

	/**
	 * Only method that is mandatory of the DragDrop
	 * It's a callback that handles the state of the draggable elements order and positioning
	 *
	 * Handling state here made it to an extremely long function, thus that logic has been outsourced to it's own file
	 *
	 * @param source
	 * @param destination
	 * @param instruction
	 */
	const dragEndHandler = useCallback(
		(source, destination, instruction) => {
			if(!source || !destination || !instruction) return;

			try {
				const { action, type } = instruction;

				const args = [
					source.properties,
					destination.properties,
					instruction
				];

				switch(true) {
					case action === 'create' && type === 'row':
						dispatch(dndCreateRowSetToArea(args));
						break;

					case action === 'create' && type === 'column':
						dispatch(dndCreateColumnSetToRow(args));
						break;

					case action === 'move' && type === 'any':
						dispatch(dndMoveBlockWithinAnyColumn(args));
						break;

					case action === 'move' && type === 'vertical':
						dispatch(dndMoveRowVertically(args));
						break;

					default:
				}
			} catch(error) {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: '🤷 Det gick inte att dra och släppa detta block.',
					children:
						'Om felet kvarstår, vänligen kontakta administratör.'
				});

				Sentry.captureException(error);
			}
		},
		[dispatch, notification]
	);

	/**
	 * Opens a modal with publish date for slecting an item
	 */
	const openPublishDateSelector = useCallback(() => {
		try {
			openPublishModal({
				title: 'Publicera sida',
				position: 'left',
				isDismissable: true,
				actions: [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (originalState, currentState, closeModal) => {
							// Reset builder block settings
							closeModal();
						}
					},
					{
						text: 'Spara & Publicera',
						action: (originalState, currentState, closeModal) => {
							// Currentstate only contains publish/unpublish_date as it is openene with only that data-subset
							const alertId = notification('SHOW', {
								priority: AlertPriorityTypes.info,
								title: '⏱️ Sparar & Publicerar sidan...'
							});
							// Save the page
							saveHandler(false).then(() => {
								// publish the page if saved correctly and close modal
								publishChangedHandler(currentState).then(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.success,
										title: '⏱️ Allt klart'
									});

									// updatera state för sidans info with updated publish dates
									dispatch(
										setPageSettings({
											...editor.settings,
											...currentState
										})
									);

									closeModal();
								});
							});
						}
					}
				],
				state: {
					publish_date: editor.settings.publish_date,
					unpublish_date: editor.settings.unpublish_date
				}
			});
		} catch(error) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: '🤷 Det gick inte att öppna modal med publicingsverktyg.',
				children: 'Om felet kvarstår, vänligen kontakta administratör.'
			});

			Sentry.captureException(error);
		}
	}, [
		dispatch,
		editor.settings,
		notification,
		openPublishModal,
		publishChangedHandler,
		saveHandler
	]);

	/**
	 * Open the modal with a input for text inputing...
	 * Used when adding a link in a tree text (Blocktext)
	 */
	const openLinkToolModalHandler = React.useCallback((data, callback) => {
		try {
			const modalActions = [
				{
					text: 'Avbryt',
					isDefault: true,
					action: (originalState, currentState, closeModal) => {
						callback(null);
						linkToolModalFormValidation.resetErrors();
						closeModal();
					}
				},
				{
					text: 'Klar',
					action: (originalState, currentState, closeModal) => {
						linkToolModalFormValidation.submit(() => {
							callback(currentState);
							closeModal();
						});
					}
				}
			];

			openLinkToolModal({
				title: 'Skapa länk',
				actions: modalActions,
				position: 'center',
				hideBackdrop: false,
				isDismissable: true,
				state: data
			});
		} catch(error) {
			notification('SHOW', {
				priority: AlertPriorityTypes.error,
				title: '🤷 Det gick inte att öppna länkverktyget.',
				children:
						'Om felet kvarstår, vänligen kontakta administratör.'
			});

			Sentry.captureException(error);
		}
	}, [linkToolModalFormValidation, notification, openLinkToolModal]);

	const processingInstructions = [
		{
			// This is REQUIRED, it tells the parser
			// that we want to insert our React
			// component as a child
			replaceChildren: true,
			shouldProcessNode: function (node) {
				return node.attribs && node.attribs['data-blockarea-id'];
			},
			processNode: function (node, children, index) {
				const blockAreaId = node.attribs['data-blockarea-id'];
				// const blockAreaId = Object.keys(template.elements.areas)[0];
				const elems = editor.elements;

				return (
					<BlockArea
						key={`area_${blockAreaId}`}
						id={blockAreaId}
						elems={elems}
						blockTypes={editor.blockTypes}
						addBlock={addBlockHandler}
						blockChanged={blockChangedHandler}
						columnChanged={setColumnWidthHandler}
						viewInUse={viewInUse}
						openLinkToolModal={openLinkToolModalHandler}
						hideBlockRowClicked={hideBlockRowHandler}
						blockRowWidthClicked={setBlockRowWidthHandler}
						blockRowReverseClicked={setBlockRowReverseHandler}
						openBlockSettingsModal={openBlockSettingsModal}
						closeBlockSettingsModal={closeBlockSettingsModal}
						deleteBlockHandler={deleteBlockHandler}
						blockSettingsFormValidation={
							blockSettingsFormValidation
						}
						viewHiddenBlockRowsForVariant={
							viewHiddenBlockRowsForVariant
						}
					/>
				);
			}
		},
		{
			replaceChildren: false,
			shouldProcessNode: function (node) {
				return node.name === 'input' || node.name === 'select';
			},
			processNode: function (node, children) {
				const attribs = node.attribs;
				return (
					<CustomInput
						{...attribs}
						offspring={children}
					/>
				);
			}
		},
		{
			// Anything else
			shouldProcessNode: function (node) {
				return true;
			},
			processNode: processNodeDefinitions.processDefaultNode
		}
	];

	if(blockSettingsModal.originalState) {
		const blockType = blockSettingsModal.originalState.data.type;
		const settingsComponent = blockSettingsModal.originalState.data
			? BlockDefinitions[blockType].settings.component
			: null;

		if(!settingsComponent)
			Sentry.captureException(
				new Error(
					`The block '${blockType}' is missing the value of 'build.settings' in it's definition`
				)
			);

		// Push extra props in the component
		blockSettingsModalContent.current = React.cloneElement(
			settingsComponent,
			{
				changed: blockChangedHandler,
				formValidation: blockSettingsFormValidation
			}
		);
	}

	/**
	 * Dinamically generate new variants components
	 */
	const previewModeOptions = useCallback(
		Object.keys(viewVariants.available).map((variantName) => {
			const isActive = viewInUse === variantName;
			const variant = viewVariants.available[variantName];

			return (
				<ScPreviewModeItem
					key={`previewBtn_${variantName}`}
					onClick={() => {
						dispatch(setBuilderViewVariant(variantName));
					}}
					isActive={isActive}
				>
					<Icon icon={variant.icon} />
				</ScPreviewModeItem>
			);
		}),
		[viewVariants]
	);

	return (
		<>
			{/* Show the Loader when first loading the builder*/}
			{!isPageLoaded &&  <Loader />}

			{/* Render block settings if opened dinamically for the correct block */}
			{getAsComponentBlockSettingsModal(
				blockSettingsModalContent.current
			)}

			{/* Modal for choosing what type of block to add */}
			{getAsComponentAddBlockModal(<ChooseBlockDialog />)}

			{getAsComponentLinkToolModal(
				<SelectMenuItem formValidation={linkToolModalFormValidation} />
			)}

			{changesMadeModal.getAsComponent(
				<BasicContentModal
					title="Granska ändringar"
					text="Du har gjort ändringar. Vill du spara dem?"
				/>
			)}

			{getAsComponentPublishModal(<PublishModal />)}

			<ScContainer editMode>
				<ScBuilderBar>
					<ScActionWrapper>
						<ScActionItem onClick={saveHandler}>
							Spara
						</ScActionItem>
						{editor.settings && !editor.settings.publish_date && (
							<ScActionItem onClick={openPublishDateSelector}>
								Publicera & Spara
							</ScActionItem>
						)}
						<ScActionItem onClick={cancelHandler}>
							Stäng
						</ScActionItem>
					</ScActionWrapper>

					{editor.settings && (
						<ScPageName>
							{editor.settings.title}
							<ScPublishStatus>
								<PublicationStatus
									publishDate={editor.settings.publish_date}
									unpublishDate={
										editor.settings.unpublish_date
									}
								/>
							</ScPublishStatus>
						</ScPageName>
					)}
					<ScPreviewMode>
						{SUPPORTED_LANGUAGES.length > 0 && (
							<ScLanguage>
								{SUPPORTED_LANGUAGES.find(language => language.code === activeLanguage).name}
							</ScLanguage>
						)}
						{previewModeOptions}

						<ScShowHiddenBlock
							onClick={toggleHiddenBlockRowsHandler}
							isActive={props.isActive}
						>
							<Icon
								icon={[
									'fal',
									viewHiddenBlockRowsForVariant
										? 'eye'
										: 'eye-slash'
								]}
							/>
						</ScShowHiddenBlock>
					</ScPreviewMode>
				</ScBuilderBar>

				{!isEmptyObject(editor) && (
					<Iframe
						key="builder_iframe"
						ref={(ref) => {
							// this is a Firefox fix
							// don't remove it
							// see ticket: https://cityweb-team.monday.com/boards/315369938/pulses/318325440
							if(ref && ref.node) {
								ref.node.onload = () => {
									setPageLoaded(true);
								};
							}
						}}
						id="editor"
						initialContent={editor.template.html}
						mountTarget="#mount"
						screenwidth={
							viewVariants.available[viewVariants.current].width
						}
					>
						<FrameContextConsumer>
							{(frameContext) => {
								const { document, window } = frameContext;

								// remove loader when iframe content (incl. images) are fully loaded
								window.onload = () => setPageLoaded(true);

								return (
									<StyleSheetManager target={document.head}>
										<>
											<DragDrop
												scope="page-builder"
												targetDocumentSelector="#editor"
												onDragEnd={dragEndHandler}
											>
												{htmlToReactParser.parseWithInstructions(
													editor.template.body,
													() => true,
													processingInstructions
												)}
											</DragDrop>
										</>
									</StyleSheetManager>
								);
							}}
						</FrameContextConsumer>
					</Iframe>
				)}
			</ScContainer>
		</>
	);
};

// export default builder;
export default withErrorBoundary(Builder);

const ScIcon = styled(Icon)`
	color: #888;
	margin-right: 4px;
`;

const ScPublishStatus = styled.div`
	font-size: 10px;
	margin-left: 8px;
	font-weight: 400;

	${(props) =>
		props.publishStatus === 'isScheduled' &&
		css`
			${ScIcon} {
				color: #20b126;
			}
		`}
	${(props) =>
		props.publishStatus === 'hasPassed' &&
		css`
			${ScIcon} {
				color: #d0021b;
			}
		`};
`;

const ScContainer = styled.section`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background-color: var(--bg-dark-color);
	margin: 16px 16px 0;
	margin: ${(props) => (props.editMode ? '0' : '16px 16px 0')};
	width: 100%;
`;

const ScBuilderBar = styled.nav`
	background: #484848;
	color: #fefefe;
	height: 48px;
	padding: 16px 32px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	z-index: 2;
	/* font-family: var(--font-family-primary); */
	font-style: normal;
	font-weight: 600;
	line-height: 15px;
	font-size: 14px;
	position: relative;
`;

const ScActionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	text-transform: uppercase;
	position: absolute;
	left: 32px;
`;

const ScActionItem = styled.div`
	margin-left: 24px;
	cursor: pointer;

	:first-child {
		margin-left: 0;
	}
`;

const ScPageName = styled.div`
	font-size: 18px;
	text-align: center;
	display: flex;
`;

const ScPreviewMode = styled.div`
	position: absolute;
	right: 32px;
	display: flex;
	font-size: 16px;
`;

const ScPreviewModeItem = styled.div`
	position: relative;
	margin-left: 16px;
	cursor: pointer;
	color: ${(props) => (props.isActive ? '#fafafa' : '#999')};

	${(props) =>
		props.isActive &&
		css`
			::after {
				content: '';
				position: absolute;
				bottom: -8px;
				left: 0;
				right: 0;
				/* width: 100%; */
				height: 1px;
				background: #fafafa;
			}
		`}
	:hover {
		color: ${(props) => (props.isActive ? '#fafafa' : '#c4c4c4')};
	}
	:first-child {
		margin-left: 0;
	}
`;

const ScShowHiddenBlock = styled.div`
	position: relative;
	margin-left: 32px;
	width: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: ${(props) => (props.isActive ? '#fafafa' : '#999')};

	:hover {
		color: ${(props) => (props.isActive ? '#fafafa' : '#c4c4c4')};
	}
	::before {
		content: '';
		position: absolute;
		height: 100%;
		width: 1px;
		left: -16px;
		background: #999;
	}
`;

const ScLanguage = styled.p`
	font-weight: 400;
`;