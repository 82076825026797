import React from 'react';
import styled from 'styled-components/macro';
import { UserDetailsProps } from './model.UserDetails';
import axios from './../../../../utils/oc-axios';
import { ModalProps } from '../../../../hooks/useModal/useModal.model';
import useModal from '../../../../hooks/Modal/useModal';
import TextInput from '../../../Forms/TextInput/TextInput';
import Label from '../../../Forms/Label/Label';
import {
	BasicContentModal,
	Button,
	InfoBox,
	Loader,
	ModalContainer
} from '../../../UI';
import ControlPanelHeading from '../../ControlPanelHeading/ControlPanelHeading';
import { User } from '../../../../containers/ControlPanel/Users/model.Users';
import { Category } from '../../../../hooks/useCheckboxTree/types.useCheckboxTree';
import useCheckboxTree from '../../../../hooks/useCheckboxTree/useCheckboxTree';
import TreeWrapper from '../../../CheckboxTree/TreeWrapper/TreeWrapper';
import TreeCategory from '../../../CheckboxTree/TreeCategory/TreeCategory';
import ItemsWrapper from '../../../CheckboxTree/ItemsWrapper/ItemsWrapper';
import TreeItem from '../../../CheckboxTree/TreeItem/TreeItem';
import { Select } from '../../../Forms';
import { ValidationTypes } from '../../../../hooks/useFormValidation/types';
import { Group } from '../../../../containers/ControlPanel/Groups/model.Groups';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import { AlertDispatch } from '../../../../hooks/useAlert/types.useAlert';

const UserDetails: React.FC<UserDetailsProps> = (props) => {
	const formValidation = props.formValidation;

	const resetGroupRightsModal = useModal();
	const deleteUserModal = useModal();

	const notification = useAlert()[1] as AlertDispatch;

	// Initial loading state, set to false after user has been fetched (or if no user needs to be fetched)
	const [isLoadingUser, setIsLoadingUser] = React.useState<boolean>(true);

	// Loading state used to indicate that groups are being fetched
	const [isLoadingGroups, setIsLoadingGroups] = React.useState<boolean>(false);

	const [
		isTriggeredPassReset,
		setIsTriggeredPassReset
	] = React.useState<boolean>(false);

	const [availableGroups, setAvailableGroups] = React.useState<any[]>([]);

	const reloadUsersList = props.reloadUsersList;

	// modal props
	const modal: ModalProps<User> | undefined = props?.modal;

	const user = modal?.currentState;
	const isCreatingNewUser = React.useMemo(() => user && user.id === -1, [
		user
	]);

	/**
	 * Generate the groups tree and enable the groups the user belong to.
	 */
	const userGroupsTreeItems = React.useMemo((): Category[] => {
		const items = availableGroups.map((group) => ({
			key: group.key,
			text: group.name,

			// checked if the user belong to the group
			isChecked: !!user?.groups.find((_g) => _g.key === group.key),

			// enabled if the logged in user belong to it
			//  to disable giving away permissions users don't have
			// TODO: need the hook to suppert checking if the user elong to a group.
			// isEnabled: isMemberOfGroup(group.key),

			// TODO
			// Man ska inte kunan tilldela grupper man inte tillhör.
			isEnabled: true,

			items: [],
			id: group.id
		}));

		return [
			{
				id: 'groups',
				name: 'Alla',
				items: items
			}
		];
	}, [availableGroups, user]);

	/**
	 * Used to generate the privileges tree
	 */
	const { tree: groupsTree } = useCheckboxTree({
		data: userGroupsTreeItems
	});

	/**
	 * Handle when a tree item is changed.
	 * 
	 * @return {void}
	 */
	const groupsTreeCategoryCheckboxChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, _categoryID: string): void => {
		event.stopPropagation();
	}, []);

	/**
	 * Handle when a tree item is changed.
	 * 
	 * @return {void}
	 */
	const groupsTreeItemCheckboxChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, groupKey: string): void => {
		event.stopPropagation();

		// an object for the "query" for immutability-helper update
		let updateQry = {};

		// toggle the current state of the category.
		const addUserToGroup = event.target.checked;

		switch(addUserToGroup) {
			// add user to a new group
			case true:
				const newGroup = availableGroups.find(
					(g: Group) => g.key === groupKey
				);

				updateQry = { $push: [newGroup] };
				break;

			// remove user from group
			case false:
				const groupIndex = user?.groups.findIndex(
					(g: Group) => g.key === groupKey
				);
				updateQry = { $splice: [[groupIndex, 1]] };
				break;
		}

		// updating user's privileges will automatically update tree as it
		//	depends on the users privileges
		if(modal?.updateState) {
			modal.updateState({
				groups: {
					...updateQry
				}
			});
		}
		
	}, [availableGroups, modal, user?.groups]);

	/**
	 * Open confirmation modal to delete current user
	 * 
	 * @return {void}
	 */
	const openDeleteUser = React.useCallback((): void => {
		deleteUserModal.open({
			actions: [
				{
					text: 'Nej',
					isDefault: true,
					action: (_oState: null, _cState: null, closeModal: any) => {
						closeModal();
					}
				},
				{
					text: 'Ja',
					action: (_oState: null, _cState: null, closeModal: any) => {
						let alertId = notification('SHOW', {
							priority: AlertPriorityTypes.loading,
							title: 'Användare tas bort',
							children: `Tar bort ${user?.name}...`
						});

						axios
							.delete(`modules/users/${user?.id}`)
							.then(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.info,
									title: 'Användare borttagen',
									children: `Användaren ${user?.name} har nu bilvit borttagen.`
								});

								// close the confirmation modal
								modal?.close();

								// reload users list
								if(reloadUsersList) reloadUsersList();

								// close the user details modal
								closeModal();
							})
							.catch(() => {
								notification('MODIFY', {
									alertID: alertId,
									priority: AlertPriorityTypes.error,
									title: 'Ett eller flera fel hittades',
									children: `Det gick inte att ta bort användaren ${user?.name}.`
								});
							});
					}
				}
			]
		});
	}, [deleteUserModal, modal, notification, reloadUsersList, user]);

	/**
	 * Handles when a input field changes, updating state
	 * 
	 * @return {void}
	 */
	const fieldChangedHandler = React.useCallback((ev: any, elProps: any): void => {
		let newValue = ev.target.value;

		// do some modifications for the value of some fields
		switch(elProps.id) {
			case 'enabled':
				newValue = newValue === 'true' ? true : false;
		}

		if(modal?.updateState) {
			modal.updateState({
				[elProps.id]: { $set: newValue }
			});
		}

	}, [modal]);

	/**
	 * Trigger a password reset for the user.
	 */
	const resetpasswordHandler = React.useCallback(() => {
		if(!user) return;

		let alertId = notification('SHOW', {
			priority: AlertPriorityTypes.loading,
			title: 'Skickar återställningslänk',
			children: `Skickar en återställningslänk till ${user?.email}...`
		});

		axios
			.post('auth/password', {
				email: user?.email
			})
			.then(() => {
				notification('MODIFY', {
					alertID: alertId,
					priority: AlertPriorityTypes.success,
					title: 'Återställningslänken skickad',
					children: `Återställningslänk har blivit skickad till ${user?.email}`
				});

				setIsTriggeredPassReset(true);
			})

			.catch(() => {
				notification('MODIFY', {
					alertID: alertId,
					priority: AlertPriorityTypes.error,
					title: 'Ett fel har uppstått',
					children: `Ett fel inträffade när återställningslänken skickades till ${user?.email}, prova igen`
				});
			});
	}, [notification, user]);

	/**
	 * Fetch all available permissions from backend
	 */
	React.useEffect(() => {
		if(isLoadingUser) {

			let endpoint = 'modules/groups';

			// Change the endpoint if creating a new user with defined group
			//	to skip listing all other groups in the system.
			if(isCreatingNewUser && user?.groups.length === 1) {
				endpoint = `modules/groups/${user.groups[0].id}`;
			}

			setIsLoadingGroups(true);

			axios.get(endpoint).then((resp) => {
				if(!Array.isArray(resp.data)) {
					setAvailableGroups([resp.data]);

				} else {
					setAvailableGroups(resp.data);
				}
			}).catch(() => {
				notification('SHOW', {
					priority: AlertPriorityTypes.error,
					title: 'Ett fel inträffade när grupper hämtades',
					children: 'Vänligen prova igen senare.'
				});
			}).finally(() => {
				// if creating a new user then nothing more should be loaded
				setIsLoadingGroups(false);
			});;
		}
	}, [isCreatingNewUser, isLoadingUser, user?.groups, notification]);

	/**
	 * Fetch user's data is editing a user
	 */
	React.useEffect(() => {
		// only fetch user data if not creating a new user
		// only fetch is loading as we dont want to trigger a render loop as this
		//	useEffect depends on the user object itself
		if(user && !isCreatingNewUser && isLoadingUser) {
			axios
				.get(`modules/users/${user?.id}`)
				.then((resp) => {
					setIsLoadingUser(false);

					if(modal?.updateState) {
						modal.updateState({ $set: resp.data });
					}
				})
				.catch(() => {
					notification('SHOW', {
						priority: AlertPriorityTypes.error,
						title: 'Ett fel inträffade när användaren hämtades',
						children: 'Vänligen försök igen'
					});
					modal?.close();
				});
		} else {
			// If the modal is opened to create a new user we can remove the loading state
			setIsLoadingUser(false);
		}
	}, [isCreatingNewUser, isLoadingUser, modal, notification, user]);

	return (
		<>
			{isLoadingUser && <Loader />}
			{user &&
				deleteUserModal.getAsComponent(
					<BasicContentModal
						title={`Du håller på att ta bort användaren ${user?.name}`}
						text="Är du säker på att du vill ta bort?"
					/>
				)}

			{resetGroupRightsModal.getAsComponent(
				<BasicContentModal
					title="Du håller på att återställa rättigheterna"
					text="Är du säker på att du vill återställa rättigheterna till gruppinställningar?"
				/>
			)}

			<ModalContainer>
				<ScUserInfo>
					<ScFlexSpaceBetween>
						<ControlPanelHeading>
							Användaruppgifter
						</ControlPanelHeading>
						{!isCreatingNewUser && (
							<ScRemoveUser onClick={openDeleteUser}>
								Ta bort användare
							</ScRemoveUser>
						)}
					</ScFlexSpaceBetween>

					<TextInput
						id="name"
						name="name"
						isRequired
						label="Namn"
						placeholder="Namn"
						value={user?.name}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={props.formValidation.errors['name']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true
							})}
					/>

					<TextInput
						id="firstname"
						name="firstname"
						label="Förnamn"
						placeholder="Förnamn"
						value={user?.firstname}
						changed={fieldChangedHandler}
					/>

					<TextInput
						id="lastname"
						name="lastname"
						label="Efternamn"
						placeholder="Efternamn"
						value={user?.lastname}
						changed={fieldChangedHandler}
					/>

					<TextInput
						id="login_name"
						name="login_name"
						isRequired
						label="Användarnamn"
						placeholder="Användarnamn"
						value={user?.login_name}
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['login_name']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true
							})}
					/>

					<TextInput
						id="email"
						name="email"
						isRequired
						label="E-postadress"
						placeholder="E-postadress"
						value={user?.email}
						type="text"
						formValidationUnregister={
							formValidation.unregisterElement
						}
						error={formValidation.errors['email']}
						changed={(
							ev: React.ChangeEvent<HTMLInputElement>,
							...data
						) => {
							formValidation.watch(ev, fieldChangedHandler, data);
						}}
						inputRef={(ref) =>
							formValidation.registerElement(ref, {
								required: true,
								validation: {
									type: ValidationTypes.EMAIL
								}
							})}
					/>

					<TextInput
						id="mobile_phone"
						name="mobile_phone"
						label="Mobilnummer"
						placeholder="Mobilnummer"
						value={user?.mobile_phone}
						type="number"
						changed={fieldChangedHandler}
					/>

					<Select
						id="enabled"
						fullWidth
						label="Status"
						value={user?.enabled || isCreatingNewUser}
						changed={fieldChangedHandler}
					>
						<option value="true">
							Aktiverad
						</option>
						<option value="false">
							Inaktiverad
						</option>
					</Select>

					<Select
						id="language"
						fullWidth
						label="Språk"
						value={user?.language}
						changed={fieldChangedHandler}
					>
						<option value="en">
							Engelska
						</option>
						<option value="sv">
							Svenska
						</option>
						<option value="de">
							Tyska
						</option>
					</Select>

					{!isCreatingNewUser && (
						<Label
							label="Lösenord"
							description="Ett mail med en länk för att välja lösenord kommer att skickas ut till användaren."
						>
							<Button
								isAutoWidth
								isDisabled={isTriggeredPassReset}
								onClick={resetpasswordHandler}
							>
								Återställ lösenord
							</Button>
						</Label>
					)}

					{isCreatingNewUser && (
						<InfoBox>
							Ett mail med en länk för att välja lösenord kommer
							att skickas ut till användaren efter användaren är
							skapad.
						</InfoBox>
					)}
				</ScUserInfo>
				<ScGroupsAndRights>
					<ScGroups>
						<ScFlexSpaceBetween>
							<ControlPanelHeading>
								Grupper
							</ControlPanelHeading>
						</ScFlexSpaceBetween>

						{isLoadingGroups && (
							<Loader
								hasNoBackdrop
								isDark
								size="20px"
							/>
						)}

						{!isLoadingGroups && (
							<TreeWrapper isHorizontal={false}>
								{groupsTree.map((category) => (
									<TreeCategory
										key={`CheckboxTreeCategory_${category.id}`}
										id={category.id}
										name={category.name}
										isChecked={category.isChecked}
										isEnabled={category.isEnabled}
										checkboxChanged={
										groupsTreeCategoryCheckboxChangedHandler
									}
									>
										<ItemsWrapper isHorizontal={false}>
											{!!category.items &&
											category.items.map((item) => {
												// Pre-select groups list when creating or editing a current user.
												const isGroupChecked = item.isChecked || (user?.groups.find((group) => group.key === item.key) !== undefined);
												return (
													<TreeItem
														key={item.id ?? item.key}
														id={item.key}
														icon={item.icon}
														text={item.text}
														isChecked={isGroupChecked}
														isEnabled={item.isEnabled}
														checkboxChanged={
															groupsTreeItemCheckboxChangedHandler
														}
													/>
												);
											})}
										</ItemsWrapper>
									</TreeCategory>
								))}
							</TreeWrapper>
						)}
					</ScGroups>
				</ScGroupsAndRights>
			</ModalContainer>
		</>
	);
};
export default UserDetails;

const ScUserInfo = styled.div`
	display: flex;
	flex: 1;
	margin-right: 40px;
	flex-direction: column;
`;

const ScGroupsAndRights = styled.div`
	display: flex;
	flex: 1;
`;

const ScGroups = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 16px;
`;

const ScFlexSpaceBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
	margin-top: 8px;
`;

const ScRemoveUser = styled.div`
	font-size: 12px;
	text-decoration: underline;
	color: var(--red-color);
	text-transform: uppercase;
	margin-left: 24px;
	white-space: nowrap;
	cursor: pointer;
`;