import logo from './assets/infotool_logo.svg';
import { ButtonStyle } from './components/Builder/BlockTypes/BlockForm/Settings/Settings/Settings.types';
import { CustomerMenuOptions } from './containers/SideDrawer/ControlPanelMenuDefinitions.types';
import { VideoPlatforms } from './settings.types';

// Used to point error handling towards the appropriate Sentry DSN
export const SENTRY_DSN = 'https://a2375a13150744edb75c8f108a29ecd9@o264270.ingest.sentry.io/4504711872970752';

export let BASE_URL = '';

// Define the API endpoint to use depending on the environment
switch(process.env.REACT_APP_ENV) {
	case 'local':
		BASE_URL = '//oc.io:10050/cms/';
		break;
	case 'staging':
		BASE_URL = '//staging.infotool.se/cms/';
		break;
	case 'live':
		BASE_URL = '//www.infotool.se/cms/';
		break;
	case 'dev':
		BASE_URL = '//dev.infotool.se/cms/';
		break;

	default:
		throw new Error(
			`Environment '${process.env.REACT_APP_ENV}' is not supported.`
		);
}

// Sets which modules should be visible for the current customer
export const CUSTOM_MODULES = ['seo'];

// Used to show/hide the image cropper
export const SHOW_IMAGE_CROPPER = true;

export const SHOW_SEO_LONG_DESCRIPTION = false;

// Sets different aspect ratios for image cropping
export const IMAGE_CROP_ASPECT_RATIOS = [
	{
		id: '16/9',
		name: '16/9 (Desktop)',
		ratio: 16 / 9
	},
	{
		id: 'kvadrat',
		name: 'Kvadrat',
		ratio: 1
	},
	{
		id: '9/12',
		name: '9/12 (Mobile)',
		ratio: 9 / 12
	},
	{
		id: '12/8',
		name: '12/8 (Custom)',
		ratio: 12 / 8
	}
];

// Links used in the control panel menu
export const WIKI_LINK = '';
export const ERROR_REPORTING_LINK = '';

// The projects logo
export const LOGO_IMAGE = logo;

// An array with supported languages.
// Set the variable to an empty array if the project shouldn't support other languages.
export const SUPPORTED_LANGUAGES = [];
export const defaultLanguage = 'sv';

// Wether to show block row width setting or not
export const SHOW_BLOCK_ROW_WIDTH_SETTING = true;

// Supported video platforms, e.g. ['youtube', 'vimeo'].
export const VIDEO_PLATFORMS: VideoPlatforms[] = ['youtube'];

// Wether to show vimeo video tags or not
export const SHOW_VIMEO_VIDEO_TAGS = false;

// Wether to show block row reverse setting or not
export const SHOW_BLOCK_ROW_REVERSE_SETTING = false;

// Button styles used in the Text Block to style links as buttons
export const BUTTON_STYLES: ButtonStyle[] = [];

// Customers
/**
 * 
 * To use more than 1 flag, use a pipe operator (|) to combine them.
 * Example: CustomerMenuOptions.INFOTOOL_FORMS_DOWNLOAD | CustomerMenuOptions.FLAG_1
 * 
 * If nothing should be shown, set the value to CustomerMenuOptions.NONE;
 * 
 */
export const CUSTOMER_CONTROLPANEL_DEFINITIONS = CustomerMenuOptions.INFOTOOL_FORMS_DOWNLOAD;
