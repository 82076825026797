import { Link } from '../../../../definitions/Link';
import { BlockTypes } from '../../Block.types';

export enum BlockFormRulesetTypes {
	NONE = 'none',
	EMAIL = 'email',
	VISIBILITY = 'visibility',
	FEEDBACK = 'feedback',
	REQUIRED = 'required'
}

export enum BlockFormRulesetResultActions {
	SUBJECT = 'subject', 
	RECIPIENT = 'recipient', 
	BCC = 'bcc', 
	MESSAGE = 'message', 
	REDIRECT = 'redirect', 
	REPLY_TO = 'reply-to',
	NOTICE = 'notice', 
	SHOW = 'show', 
	HIDE = 'hide', 
	REQUIRE = 'require', 
	UNREQUIRE = 'unrequire', 
	MASK = 'mask'
}

export enum BlockFormItemTypes {
	INPUT = 'input',
	TEXT_AREA = 'textarea',
	FILE = 'file',
	SELECT = 'select',
	BUTTON = 'button',
	EMAIL = 'email',
	PHONE = 'phone',

	ADDRESS_GROUP = 'address-group',

	NAME_GROUP = 'name-group',

	OPTION = 'option',
	OPTION_GROUP = 'optgroup',

	RADIO = 'radio',
	RADIO_GROUP = 'radio-group',

	CHECKBOX = 'checkbox',
	CHECKBOX_GROUP = 'checkbox-group'
}

export enum BlockFormInputItemTypes {
	HIDDEN = 'hidden',
	COLOR = 'color', 
	DATE = 'date', 
	DATETIME = 'datetime-local',
	EMAIL = 'email', 
	PASSWORD = 'password', 
	SEARCH = 'search', 
	MONTH = 'month',
	NUMBER = 'number', 
	RANGE = 'range', 
	TELEPHONE = 'tel',
	TEXT = 'text',
	TIME = 'time',
	URL = 'url',
	WEEK = 'week' 
}
export interface BlockFormType {
	// The unique uuid of the block
	uuid: string | null;

	// The form's name.
	name: string | null;

	// The type of block
	type: BlockTypes.FORM;

	// BUTTON - The name of the buttom
	button_text: string | null;

	// TODO
	collective_message: string | null;

	// TODO
	hasMultimedia: boolean;

	// TODO
	empty: boolean;

	// All form inputs elements
	items: Record<string, BlockFormItem>;

	// All rules that apply the form.
	rulesets: Record<string, BlockFormRuleset>;

	// wether to show the form name or not
	show_name: boolean | null;
}

export enum BlockFormItemLayout {
	VERTICAL = 'vertical',
	HORISONTAL = 'horisontal'
}

export interface BlockFormItems {
	[key: string]: BlockFormItem;
}

/**
 * Different types of validation for BlockFormTypes.INPUT
 */
export type BlockFormItemValidation = 'pattern' | 'email' | 'number' | 'phone' | 'alphanum' | 'hex';

/**
 * Used in Redux RootState.builder.formStructure.structures.
 */
export interface BlockFormItemSettings {
	// The display name of the form input type.
	name: string;

	children_dnd: boolean;

	allowed: string[];

	// Font Awesome: icon_name
	icon: string;

	settings: {
		simple: {
			disabled: any;
		},

		advanced: {
			multiple: null;
			size: null;
			'js-target': null;
			autofocus: null;
		}
	}
}

export interface BlockFormItem {
	// A key based on the FormItem's Uuid
	key: string;

	// The unique item's id.
	uuid: string | null;

	// Label name for the input.
	name: string | null;

	// Input name, and also label connection to for/id property.
	unique_name: string;

	// The value of the formitem.
	value: string | null;

	// Additional description for the input
	description: string | null;

	// Adds a placeholder attribute
	placeholder: string | null;

	// Sets the minlength attribute
	minlength: number | null;

	// Sets the maxlength attribute
	maxlength: number | null;

	// User for type=number and sets the minimum value
	min: number | null;

	// User for type=number and sets the maximum value
	max: number | null;

	// User for type=number and sets the step value
	step: number | null;

	// TEXTAREA - How many rows the element should have.
	rows: number | null;

	// TODO
	size: number | null;

	// TODO
	layout: BlockFormItemLayout;

	// Used only for targeting through e.g. JS.
	js_target: string | null;

	// The error message to show if the element is required.
	required_error: string | null;

	validation: BlockFormItemValidation |null;

	// Custom RegEx for validatin the input.
	pattern: string | null;

	// The type of the form element.
	type: BlockFormItemTypes;

	// The type of form element to use for rendering on the webpage (HTML5)
	input_type: BlockFormInputItemTypes | null;

	// FormItems that are childrens of this one.
	children: string[];

	// TODO
	accept: any[];

	// The order of the form element.
	sort_order: number;

	// If the element is root.
	root: boolean;

	// SELECT, FILE, CHECKBOX - If many values can be chosen.
	multiple: boolean;

	// If the 'name' property should be rendered/shown
	show_name: boolean;

	// If the 'description' property should be rendered/shown
	show_description: boolean;

	// If the element must have a value.
	required: boolean;

	// If the element is selected.
	selected: boolean;

	// If the element is disabled.
	disabled: boolean;

	// If the element is read only.
	readonly: boolean;

	// BUTTON - If the element submits the form.
	submit: boolean;

	// If the element should autofocus
	autofocus: boolean;
}

export interface BlockFormRule {

}

export type RootCallback = (item: BlockFormItem, children: JSX.Element[] | undefined, parent?: string) => JSX.Element;
export type ItemCallback = (item: BlockFormItem, index: number, parentItem: BlockFormItem, children?: JSX.Element | JSX.Element[] | undefined) => JSX.Element;
export type SubItemCallback = (item: BlockFormItem, children: JSX.Element[] | undefined, parent?: string) => JSX.Element;
export interface DraggableFormItem {
	id: number | string;
	key: number | string;
	index: number;
	scope: string;
	allowedDirection: 'vertical' | 'horisontal' | 'any';
	isHandle: boolean;
	parent: string;
}

export interface BlockFormProps {
	data?: BlockFormType;
}

export interface GeneralFormItemSetting {
	name: string;
	info: string;
	type: string;
	multiple?: boolean,
	items?: {
		text?: string;
		email?: string;
		password?: string;
		search?: string;
		tel?: string;
		url?: string;
		number?: string;
		range?: string;
		none?: string
		alphanum?: string
		hex?: string
		phone?: string
		pattern?: string
	}
}

export interface GeneralFormItemSettings {
	[key: string]: GeneralFormItemSetting
}

export interface GeneralFormItemStructures {
	[key: string]: {
		name: string;
		locked: boolean,
		allowed: null | string[];
		icon?: string;
		icons?: {
			[key: string]: string;
		};
		settings: {
			simple: Setting,
			advanced: Setting
		}
	}
}

export interface AllowedItem {
	element: string,
	name: string;
}

export interface ConnectedObject {
	[key: string]: keyof BlockFormItem[];
}

export type ConnectedElements = null | keyof BlockFormItem[] | ConnectedObject;

export type Setting = {
	// eslint-disable-next-line no-unused-vars
	[key in keyof BlockFormItem]: ConnectedElements;
};

export type EdittedItem = {
	key: string;
	type: string;
} | null;

export interface Address {
	address: string[],
	postal: string[],
	country: string[]
}

export interface BlockFormRuleset {
	type_name?: string;
	rules?: any[] | null; 
	results?: Record<string, BlockFormResult> | null;
	uuid?: string | null;
	active?: boolean;
	trigger?: string;
	unconditional?: boolean;
	type?: string;
	children?: string[];
}

export interface BlockFormResult {
	form_item: string | null;
	link: Link,
	uuid: string | null;
	action: string | null;
	value: string | null;
}
