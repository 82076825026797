import React from 'react';
import styled, { css } from 'styled-components/macro';
import { StyledContainerProps, StyledTooltipWrapperProps, TooltipWithClickProps } from './TooltipWithClick.types';
import useClickOutside from '../../../hooks/useClickOutside/useClickOutside';
import Icon from '../Icon/Icon';

const TooltipWithClick: React.FC<TooltipWithClickProps> = (props) => {
	const [showText, setShowText] = React.useState(false);

	// Holds a reference to the tooltip text
	const tooltipTextRef = React.useRef(null);

	// Holds a reference to the tooltip icon
	const tooltipIconRef = React.useRef(null);

	// Check if clicked outside of the tooltip text
	const { isClickedOutside, resetIsClickedOutside } = useClickOutside([tooltipTextRef, tooltipIconRef]);

	/**
     * Hide the tooltip text if clicked outside of it
     */
	React.useEffect(() => {
		if(isClickedOutside) {
			setShowText(false);
			resetIsClickedOutside();
		}
	}, [isClickedOutside, resetIsClickedOutside]);

	return (
		<ScWrapper>
			<ScTooltipIconWrapper ref={tooltipIconRef}>
				<ScTooltipIcon
					onClick={() => setShowText(!showText)}
					color="white"
					icon={props.icon}
				/>
			</ScTooltipIconWrapper>
			<ScTooltipWrapper
				ref={tooltipTextRef}
				showText={showText}
				isRight={props.isRight}
				isLeft={props.isLeft}
			>
				<ScContainer
					isRight={props.isRight}
					isLeft={props.isLeft}
					width={props.width}
				>
					{props.children}
				</ScContainer>
			</ScTooltipWrapper>
		</ScWrapper>
	);
};

export default TooltipWithClick;

const ScWrapper = styled.div`
	position: relative;
	display: inline-block;
`;

const ScTooltipIconWrapper = styled.div`
	display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const ScTooltipWrapper = styled.div<StyledTooltipWrapperProps>`
	position: absolute;
	display: ${props => props.showText ? 'block' : 'none'};
	top: 0;

	${(props) => props.isRight && css`
		left: 36px;
	`}

	${(props) => props.isLeft && css`
		right: 24px;
	`}
`;

const ScContainer = styled.div<StyledContainerProps>`
	position: relative;
	z-index: 1000;
	display: inline-block;
	background: #333;
	padding: 4px 8px;

	border-radius: 4px;
	padding: 8px;
	font-size: 12px;
	line-height: 12px;
	font-weight: 600;
	text-align: left;
	color: #fafafa;
	width: ${props => props.width ? props.width : '200px'};

	:after {
		content: ' ';
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-width: 8px;


		${(props) => props.isRight && css`
			top: 5%;
			right: 100%;
			border-width: 4px;
			margin-top: -4px;
			border-right-color: #333;
		`}

		${(props) => props.isLeft && css`
			top: 5%;
			left: 100%;
			border-width: 4px;
			margin-top: -4px;
			border-left-color: #333;
		`}
	}
`;

const ScTooltipIcon = styled(Icon)`
	color: black;
	padding: 2px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	margin-left: 8px;
`;