import React from 'react';
import styled from 'styled-components/macro';
import { DateTime } from 'luxon';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeading,
	TableRow
} from '../../../components/Table';
import { Icon } from '../../../components/UI';
import {
	Setting,
	SettingInputTypes
} from '../../../store/types/ControlPanel/SettingsTypes';
import { NewMediaFile } from '../../../definitions/Media';

/**
 * Renders a level component (ScLevel).
 *
 * @param {Map<string, Setting>} tree The tree from where to get children objects.
 * @param {Setting} item The item to render.
 */
export const renderSubLevelComponent = (
	tree: Map<string, Setting>,
	item: Setting,

	hasAdvancedPrivilege: boolean,
	collapsedItems: Array<string>,

	rowClickCb: (itemKey: string | number) => void,
	toggleCollapsableItem: (key: string) => void
): React.ReactNode => {
	return (
		item.children

			// replace childrens (strings) with their object representaion.
			.reduce((acc: Array<Setting>, itemKey) => {
				const value = tree.get(itemKey);
				if(!!value) acc.push(value);

				return acc;
			}, [])

			// Filter out Settings and only keep those that are Categories
			//	Those Setting with childrens.
			.filter((child) => child.children.length > 0)

			// Map Category to it's component representation (ScLevel)
			.map((child) => {
				const levelKey = `Level_${child.display_name}`;
				const isLevelCollapsed = collapsedItems.includes(levelKey);

				const levelContent = (
					<>
						{renderTableRowWithTable(
							tree,
							child,
							rowClickCb,
							hasAdvancedPrivilege
						)}
						{renderSubLevelComponent(
							tree,
							child,

							hasAdvancedPrivilege,
							collapsedItems,

							rowClickCb,
							toggleCollapsableItem
						)}
					</>
				);

				return (
					<ScLevel
						key={levelKey}
						onClick={(ev) => {
							ev.stopPropagation();
							toggleCollapsableItem(levelKey);
						}}
					>
						<ScSectionTitle>
							<ScCollapse>
								<Icon
									icon={[
										'fal',
										isLevelCollapsed
											? 'chevron-up'
											: 'chevron-down'
									]}
								/>
							</ScCollapse>
							{child.display_name}
						</ScSectionTitle>

						{!isLevelCollapsed && levelContent}
					</ScLevel>
				);
			})
	);
};

/**
 * Render a Table with Rows, one Row is a Setting.
 *
 * @param tree
 * @param item
 */
export const renderTableRowWithTable = (
	tree: Map<string, Setting>,
	item: Setting,
	rowClickCb: (itemKey: string | number) => void,
	hasAdvancedPrivilege: boolean
): React.ReactNode => {
	const settings = item.children
		// replace childrens (strings) with their object representaion.
		.reduce((acc: Array<Setting>, itemKey) => {
			const value = tree.get(itemKey);
			if(!!value) acc.push(value);

			return acc;
		}, [])

		// Only keep settings that have an id
		//  only options/settings have an id, categories does not have one.
		.filter((child) => !!child.id)

		// Map Setting to it's component representation (TableRow)
		.map((child) => {
			return renderRowComponent(child, rowClickCb, hasAdvancedPrivilege);
		});

	// wrap TableRows in a Table
	// 	only wrap in table if there are any children to skip an empty TableHead being rendered.
	if(settings.length > 0)
		return (
			<Table isTableFixed>
				<TableHead>
					<div style={{ display: 'table-row' }}>
						<TableHeading isSortable={false}>
							Name
						</TableHeading>
						<TableHeading isSortable={false}>
							Description
						</TableHeading>
						<TableHeading isSortable={false}>
							Default value
						</TableHeading>
						<TableHeading isSortable={false}>
							Value
						</TableHeading>
						{hasAdvancedPrivilege && (
							<TableHeading isSortable={false}>
								Easy access
							</TableHeading>
						)}
					</div>
				</TableHead>

				<TableBody>
					{settings}
				</TableBody>
			</Table>
		);

	return null;
};

/**
 * Render a table row component.
 *
 * @param {Setting} item The setting to check
 * @return {React.ReactNode} A TableRow component with children.
 */
export const renderRowComponent = (
	item: Setting,
	clickCb: (itemKey: string | number) => void,
	hasAdvancedPrivilege: boolean
): React.ReactNode => {
	let value = null;

	// Show diferent values depending on the setting type.
	switch(item.type) {
		case SettingInputTypes.DATE:
			// use default null if we don't have a value
			if(!item.value) break;

			value = DateTime.fromISO(item.value as string)
				.toLocal()
				.setLocale('sv-SV')
				.toString();

			break;

		case SettingInputTypes.MEDIA:
			const media = item.value as NewMediaFile | null;

			value = media?.props?.name ?? null;
			break;

		case SettingInputTypes.BOOL:
			value = item.value ? 'Ja' : 'Nej';
			break;

		default:
			value = item.value;
			break;
	}

	return (
		<TableRow
			key={`Setting_${item.name}`}
			name={item.name}
			clicked={clickCb}
		>
			<TableCell isSingleRow>
				{hasAdvancedPrivilege ? item.name : item.display_name}
			</TableCell>
			<TableCell isSingleRow>
				{item.comment}
			</TableCell>
			<TableCell isSingleRow>
				{`${item.default}`}
			</TableCell>
			<TableCell isSingleRow>
				{`${value}`}
			</TableCell>
			{hasAdvancedPrivilege && (
				<TableCell isSingleRow>
					{`${item.general}`}
				</TableCell>
			)}
		</TableRow>
	);
};

/**
 * Extract the first level or root items for the table.
 *
 * @param tree	The tree data to search root elements for.
 * @return Array<Setting>
 */
export const getTreeRootItems = (tree: Map<string, Setting>) => {
	const result: Array<Setting> = [];

	// iterate all entries and only return those that are root (is_root == true)
	for(const setting of tree.values()) {
		if(setting.is_root) result.push(setting);
	}

	return result;
};

const ScSectionTitle = styled.div`
	display: inline-block;
	font-size: 14px;
	margin-bottom: 8px;
	font-weight: 600;
	cursor: pointer; ;
`;

const ScCollapse = styled.div`
	margin-right: 8px;
	display: inline-block;
`;

const ScLevel = styled.div`
	padding: 0 16px;
	margin-bottom: 16px;
	margin-top: 8px;

	:last-child {
		margin-bottom: 0;
	}
`;
