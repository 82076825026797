import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import axios from '../../../utils/oc-axios';

import Tree from '../../../components/Tree/Tree';

/**
 *
 * @param {string[]} mediaItems			The media item's uuid to move.
 * @param {array} mediaFolders       	Array of folders to populate Tree.
 * @param {string[]} disabledFolders	The folders to disable so files are not moved to the same folder they currently are in.
 *
 * @param {function} moveCompleted		Function to call when the move has been succesfull.
 */
const MoveMediaModal = (props) => {
	const { mediaItems, mediaFolders, disabledFolders, moveCompleted } = props;

	/**
	 *
	 * @param {Event} ev		The click event...
	 * @param {object} item 	The selected navitem.
	 */
	const moveMediaHandler = (ev, targetNavItem) => {
		axios
			.put(`/media/files`, {
				target_folder: targetNavItem.uuid,
				items: mediaItems,
			})
			.then(moveCompleted)
			.catch((err) => {
				console.warn('[Could not move files to target folder]', err);
			});
	};

	// TODO waiting for ticket to be completed https://cityweb-team.monday.com/boards/432838565/pulses/467432610
	return (
		<ScWrapper className="multiSelectBarModal">
			<Tree
				scope="media-archive-move-files"
				payload={mediaFolders}
				clicked={moveMediaHandler}
				preExpanded={['r4']}
				disabledItems={disabledFolders}
			/>
		</ScWrapper>
	);
};

MoveMediaModal.propTypes = {
	mediaFolders: PropTypes.object,
};

export default MoveMediaModal;

const ScWrapper = styled.div`
	position: relative;
	padding: var(--modal-padding);
	border-top: 1px solid var(--border-grey-color);
`;
