import React from 'react';
import * as Sentry from '@sentry/browser';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from '../components/Login/Login';
import { AuthContext } from '../auth/context/AuthContext';
import {
	Credentials,
	AuthUser,
	RequestBody,
	AllowedMethods
} from '../auth/context/types';
import useModal from '../hooks/Modal/useModal';
import axios from '../utils/oc-axios';
import { CloseModal } from '../hooks/useModal/useModal.model';
import useAlert, { AlertPriorityTypes } from '../hooks/useAlert';

// eslint-disable-next-line @typescript-eslint/naming-convention
const withAuthentication = <T extends object>(WrappedComponent: React.ComponentType<T>) => {

	const Wrapped: React.ComponentType<T> = (props) => {
		// Used to trigger a generic modal if a session runs out in the CMS
		const { open, close, getAsComponent } = useModal();

		// Makes sure only one auth modal is open
		const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);

		// Whether the entire window should be loading
		const [appIsLoading, setAppIsLoading] = React.useState<boolean>(true);

		// Is provided as a prop to using component, will show the loading state of any specific request made here
		const [authLoader, setAuthLoader] = React.useState<boolean>(false);

		// Used to store info whether the user authentication was checked upon page load
		const [checkedAuth, setCheckedAuth] = React.useState<boolean>(false);

		// Hold the object of the authenticaed user
		const [authUser, setAuthUser] = React.useState<AuthUser | null>(null);

		// Notification used to inform user about 403 Forbidden
		const notification = useAlert()[1];

		// This hook returns the current 'location' object
		const location = useLocation();

		// This hook returns a function that lets you navigate programmatically
		const navigate = useNavigate();

		/**
		 * Helper function to trigger requests
		 * All request patterns in this file are idententical which led to a lot of repetitive code
		 * This function will handle all the requests necessary in a generic manner
		 *
		 * @template T
		 * @param {AllowedMethods} method
		 * @param {string} url
		 * @param {number} expect
		 * @param {RequestBody} [body]
		 * @returns {Promise<{ status: number; data: T }>}
		 */
		const triggerRequest = React.useCallback(
			async <T extends object>(
				method: AllowedMethods,
				url: string,
				expect: number,
				body?: RequestBody
			): Promise<{ status: number; data: T }> => {
				let status = 500;
				let data;

				try {
					const response = await axios.request({
						method: method,
						url: url,
						data: body
					});

					if(response.status !== expect) throw response;

					status = response.status;
					data = response.data;
				} catch(err) {
					const error = err as AxiosError;
					status = error?.response?.status ?? 500;
				}

				return { status, data };
			},
			[]
		);

		const authContext = React.useMemo(
			() => ({
				authLoader: authLoader,
				authUser: authUser,
				lostCredentials: async (email: string) => {
					setAuthLoader(true);

					const payload = {
						email
					};

					const { status } = await triggerRequest(
						AllowedMethods.POST,
						'/auth/password',
						204,
						payload
					);

					setAuthLoader(false);
					return status;
				},
				setPass: async (hash: string | null, password: string) => {
					setAuthLoader(true);

					const payload = {
						hash,
						password
					};

					const { status } = await triggerRequest(
						AllowedMethods.PUT,
						'/auth/password',
						204,
						payload
					);

					setAuthLoader(false);
					return status;
				},
				signIn: async (credentials: Credentials) => {
					setAuthLoader(true);
					const { username: login_name, password } = credentials;

					const payload = {
						login_name,
						password
					};

					const { status, data } = await triggerRequest<AuthUser>(
						AllowedMethods.POST,
						'/auth/login',
						200,
						payload
					);

					if(status === 200) {
						setModalIsOpen(false);
						setAuthUser(data);

						close();
					}

					setAuthLoader(false);
					return status;
				},
				signOut: async () => {
					setAppIsLoading(true);

					const { status } = await triggerRequest(
						AllowedMethods.GET,
						'/auth/logout',
						204
					);

					if(status === 204) {
						// Unassign the current log-in user from Sentry.
						Sentry.configureScope((scope) => scope.setUser(null));

						setAuthUser(null);
						setModalIsOpen(false);

						close();
						navigate('/');
					}

					setAppIsLoading(false);
					return status;
				}
			}),
			[authLoader, authUser, close, navigate, triggerRequest]
		);

		React.useEffect(() => {
			const authInterceptor = axios.interceptors.response.use(
				(res) => {
					return res;
				},
				(err) => {
					const status = err?.response?.status;

					if(!modalIsOpen && status === 401 && authUser) {
						open({
							title: 'Hoppsan, din session är inte längre aktiv!',
							position: 'center',
							width: '1024px',
							isDismissable: false,
							isBackdropBlurred: true,
							actions: [
								{
									text: 'Logga ut helt',
									isDefault: true,
									action: (
										_oState: null,
										_cState: null,
										closeModal: CloseModal
									) => {
										// Unassign the current log-in user from Sentry.
										Sentry.configureScope((scope) =>
											scope.setUser(null));

										setAuthUser(null);
										navigate('/');
										closeModal();
									}
								}
							],
							state: null
						});

						setModalIsOpen(true);
					}

					if(status && status === 403) {
						// @ts-ignore
						notification('SHOW', {
							priority: AlertPriorityTypes.error,
							title:
								'Du har inte rättigheter att utföra den här åtgärden'
						});
					}

					return Promise.reject(err);
				}
			);

			return () => {
				axios.interceptors.response.eject(authInterceptor);
			};
		}, [authContext, authUser, modalIsOpen, notification, open, navigate]);

		/**
		 * Used to handle paths for the login screen
		 * If a user loses session within the CMS and reloads the page this effect makes sure the user arrives at the login screen
		 */
		React.useEffect(() => {
			if(checkedAuth) {
				const paths = ['/', '/auth/password', '/lost_password'];
				if(!authUser && !paths.includes(location.pathname)) {
					navigate('/');
				}
			}
		}, [authUser, location, navigate, checkedAuth]);

		/**
		 * This effect is triggered once per site load and will determine whether the user has an active session or not
		 * If an active sessions was found, the authUser state is populated
		 */
		React.useEffect(() => {
			(async () => {
				const { status, data } = await triggerRequest<AuthUser>(
					AllowedMethods.GET,
					'/auth/user',
					200
				);

				if(status === 200) {
					// Add user to Sentry so it can track who was affected by errors.
					Sentry.setUser({
						id: `${data.id}`,
						email: data.email,
						username: data.login_name
					});

					setAuthUser(data);
				}

				setCheckedAuth(true);
				setAppIsLoading(false);
			})();
		}, [triggerRequest]);

		return (
			<AuthContext.Provider value={authContext}>
				<WrappedComponent
					authUser={authUser}
					appIsLoading={appIsLoading}
					authLoader={authLoader}
					{...props}
				/>
				{getAsComponent(<Login isLoading={authLoader} />)}
			</AuthContext.Provider>
		);
	};

	return Wrapped;
};

export default withAuthentication;
