import React from 'react';
import styled from 'styled-components/macro';
import { ItemsWrapperProps, ScItemsWrapperProps } from './model.ItemsWrapper';

const ItemsWrapper: React.FC<ItemsWrapperProps> = (props) => {
	return (
		<ScItemsWrapper isHorizontal={props.isHorizontal}>
			{props.children}
		</ScItemsWrapper>
	);
};

const ScItemsWrapper = styled.div<ScItemsWrapperProps>`
	padding-left: ${(props) => (props.isHorizontal ? '0' : '32px')};
	display: flex;
	flex-direction: ${(props) => (props.isHorizontal ? 'row' : 'column')};
	flex-wrap: wrap;
	margin-bottom: 16px;
`;

export default ItemsWrapper;
