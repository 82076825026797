import React from 'react';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { createEditor, Node } from 'slate';
import { withHistory } from 'slate-history';
import { serialize } from './serialization/serialize/serialize';
import { deserialize } from './serialization/deserialize/deserialize';
import { insertLink, getUriType, stripHref } from './custom/components/LinkButton/links-api';
import withLinkAsInline from './custom/hoc/withLinkAsInline';
import { toggleBlock } from './custom/components/BlockButton/blocks-api';
import { DeleteLinkButton, LinkButton, AlignmentButton, MarkButton, BlockButton, Leaf, Element } from './custom/components';
import {
	BULLETED_LIST,
	HEADING_FOUR,
	HEADING_ONE,
	HEADING_THREE,
	HEADING_TWO,
	ITALIC,
	NUMBERED_LIST,
	STRONG,
	UNDERLINE,
	ALIGN_LEFT,
	ALIGN_CENTER,
	ALIGN_RIGHT,
	LIST_ITEM,
	PARAGRAPH
} from './OC7Editor';
import { isTyping } from '../Builder/helpers/TextEditorUtils';
import Toolbar from '../../components/Builder/BlockTypes/BlockText/Toolbar';

const TextEditor = (props) => {
	let data = props.initialText;

	// If the initial data is a string, then it comes from the database and is ineed of deserialization
	// Slate understands JS objects describing blocks/inline and marks within the editor, HTML won't do it
	if(typeof data === 'string') {
		const document = new DOMParser().parseFromString(data, 'text/html');

		data = deserialize(document.body);
	}

	// State whether the editor is focused or not
	// Mainly used to toggle whether the toolbar should be rendered or not
	const [isFocused, setIsFocused] = React.useState(false);

	// Containing the deserialized data that is interpretable by Slate
	const [value, setValue] = React.useState(data);

	/**
	 * Called by SlateJS renderElement to render an element
	 * https://docs.slatejs.org/concepts/08-rendering
	 *
	 * @param {RenderElementProps} props
	 * @returns {JSX.Element}
	 */
	const renderElement = React.useCallback((props) => {
		return <Element {...props} />;
	}, []);

	/**
	 * Called by SlateJS renderElement to render an element
	 * https://docs.slatejs.org/concepts/08-rendering#leaves
	 *
	 * @param {RenderLeafProps} props
	 * @returns {JSX.Element}
	 */
	const renderLeaf = React.useCallback((props) => {
		return <Leaf {...props} />;
	}, []);

	// Create a new editor object
	// The editor is extended with further functionality by wrapping it in several HOC's
	// The only custom HOC is withLinkAsInline and serves to make sure links are rendereded as inline-elements, for all others it will run Slate native settings
	// https://docs.slatejs.org/api/miscellaneous#createeditor
	const editor = React.useMemo(
		() => withLinkAsInline(withReact(withHistory(createEditor()))),
		[]
	);

	/**
	 * Handler for linking tool
	 * Will open a modal where the user will be able to specify link href and target
	 * Provides a callback which Slate will benefit from in order to add the link
	 * 
	 * @param {Object | null} currentLink // The current link if there is one, otherwise null
	 */
	const openLinkToolHandler = (currentLink) => {
		props.openInputModal(
			{
				type: 'text',
				value: currentLink?.href ? stripHref(currentLink.href) : '',
				new_window: currentLink?.new_window ?? false,
				uri_type: currentLink?.uri_type ?? getUriType(currentLink?.href),
				button_class: currentLink?.button_class ?? undefined
			},
			(link) => {
				if(!link || !link.value) return;
				insertLink(
					editor, 
					link, 
					setTextEditorContentHandler
				);
			}
		);
	};

	/**
     * Callback to set the text editor content
     * 
     * @returns {void}
     */
	const setTextEditorContentHandler = React.useCallback(() => {
		props.setTextEditorContent(
			serialize(editor),
			props.block,
			props.valueProp
		);
	}, [editor, props]);

	return (
		<Slate
			editor={editor}
			value={value}
			
			onChange={(value) => {
				setIsFocused(editor.selection !== null);
				isTyping(editor.selection !== null);
				setValue(value);
			}}
		>
			{!props.toolbarDisabled && isFocused && (
				<Toolbar>
					<MarkButton
						format={STRONG}
						icon={['fas', 'bold']}
					/>
					<MarkButton
						format={ITALIC}
						icon={['fas', 'italic']}
					/>
					<MarkButton
						format={UNDERLINE}
						icon={['fas', 'underline']}
					/>
					<BlockButton
						format={HEADING_ONE}
						icon={['fas', 'h1']}
					/>
					<BlockButton
						format={HEADING_TWO}
						icon={['fas', 'h2']}
					/>
					<BlockButton
						format={HEADING_THREE}
						icon={['fas', 'h3']}
					/>
					<BlockButton
						format={HEADING_FOUR}
						icon={['fas', 'h4']}
					/>

					{(!props.exclude || !props.exclude.includes('anchor')) && (
						<>
							<LinkButton clicked={openLinkToolHandler} />
							<DeleteLinkButton />
						</>
					)}

					{(!props.exclude || !props.exclude.includes('aligns')) && (
						<>
							<AlignmentButton
								format={ALIGN_LEFT}
								icon={['fas', 'align-left']}
							/>
							<AlignmentButton
								format={ALIGN_CENTER}
								icon={['fas', 'align-center']}
							/>
							<AlignmentButton
								format={ALIGN_RIGHT}
								icon={['fas', 'align-right']}
							/>
						</>
					)}

					<BlockButton
						format={NUMBERED_LIST}
						icon={['fas', 'list-ol']}
					/>
					<BlockButton
						format={BULLETED_LIST}
						icon={['fas', 'list-ul']}
					/>
				</Toolbar>
			)}

			<Editable
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				placeholder="Skriv din text här..."
				onBlur={setTextEditorContentHandler}
				onSelect={(ev) => {
					/**
					 * Fix auto scroll
					 * https://github.com/ianstormtaylor/slate/issues/3750#issuecomment-657783206
					 *
					 * Chrome doesn't scroll at bottom of the page. This fixes that.
					 */
					if(!window.chrome) return;
					if(editor.selection == null) return;
					try {
						/**
						 * Need a try/catch because sometimes you get an error like:
						 *
						 * Error: Cannot resolve a DOM node from Slate node: {"type":"p","children":[{"text":"","by":-1,"at":-1}]}
						 */

						const domPoint = ReactEditor.toDOMPoint(
							editor,
							editor.selection.focus
						);
						const node = domPoint[0];
						if(node == null) return;

						const element = node.parentElement;

						if(element == null) return;
						element.scrollIntoView({
							behavior: 'smooth',
							block: 'nearest'
						});
					} catch(e) {
						/**
						 * Empty catch. Do nothing if there is an error.
						 */
					}
				}}
				onKeyDown={(ev) => {
					// Soft break
					if(ev.shiftKey && ev.key === 'Enter') {
						ev.preventDefault();
						return editor.insertText('\n');
					}

					// Hard break
					if(ev.key === 'Enter') {
						const selectedElement = Node.descendant(
							editor,
							editor.selection.anchor.path.slice(0, -1)
						);

						if(
							selectedElement &&
							selectedElement.type === LIST_ITEM
						) {
							return editor.insertNode('');
						}

						ev.preventDefault();

						editor.insertBreak();

						//make sure a hard break will allways create a new paragraph
						return toggleBlock(editor, PARAGRAPH);
					}
				}}
			/>
		</Slate>
	);
};

export default TextEditor;
