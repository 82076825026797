/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import styled from 'styled-components/macro';
import { Cell, Row } from 'react-table';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SeoAltTexts, SeoFilters, SeoHeading, SeoPage, SeoPages, SeoTablePage } from './Seo.types';
import SeoPageDetails from './SeoPageDetails';
import { SeoPageDetailsState } from './SeoPageDetails.types';
import axios from './../../../utils/oc-axios';
import ReactTable from '../../../components/GenericTable/ReactTable/ReactTable';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import Search from '../../../components/Search/Search';
import { Select } from '../../../components/Forms';
import { SUPPORTED_LANGUAGES } from '../../../settings';
import { ModalProps } from '../../../hooks/useModal/useModal.model';
import useModal from '../../../hooks/Modal/useModal';
import useFormValidation from '../../../hooks/useFormValidation/useFormValidation';
import useSeoPages from '../hooks/useSeoPages';
import { SkeletonTable } from '../../../components/Skeletons';
import { Message } from '../../../components/UI';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import TreeCategory from '../../../components/CheckboxTree/TreeCategory/TreeCategory';
import ItemsWrapper from '../../../components/CheckboxTree/ItemsWrapper/ItemsWrapper';
import TreeItem from '../../../components/CheckboxTree/TreeItem/TreeItem';
import ControlPanelHeading from '../../../components/ControlPanel/ControlPanelHeading/ControlPanelHeading';
import useHomePages from '../../../hooks/useHomePages/useHomePages';

const Seo: React.FC = () => {
	const formValidation = useFormValidation();

	// Method to check if there is any gaps between the home pages' publish dates
	const { checkHomePages } = useHomePages();

	// Modal to edit a page
	const seoPageDetailsModal = useModal();

	// Holds the filters used to filter the web pages
	const [filters, setFilters] = React.useState<SeoFilters>({
		lang: '',
		needle: ''
	});
	
	// Holds the columns to show in the table
	const [columns, setColumns] = React.useState(initialColumns);

	// A method to trigger a notification
	const notification = useAlert()[1] as AlertDispatch;

	const { data: pagesData, isLoading: isLoadingPages, isFetching: isFetchingPages, isError: hasPagesError, refetch: refetchPages } = useSeoPages(filters);

	// Holds the query client from react-query
	const queryClient = useQueryClient();

	// Modify the data to fit the table
	const seoPages = React.useMemo(() => {
		if(!pagesData) return null;

		return Object.values(pagesData).reduce((prevPages: SeoTablePage[], currentPages: SeoPage[]) => {
			const modifiedPages = currentPages.map(page => {
				// Translate boost_value from english to swedish
				let boostValue = page.boost_value;
				if(page.boost_value === 'high') boostValue = 'Hög';
				if(page.boost_value === 'default') boostValue = 'Medel';
				if(page.boost_value === 'low') boostValue = 'Låg';

				// Replace null values and objects with approtiate readable values
				// The word "Saknas" has to be wrapped in parentheses to be able to be sorted correctly
				let modifiedPage: SeoTablePage = {
					...page,
					h1: headingValueHelper(page.h1),
					h2: page.h2 && page.h2.length > 0 ? 'Ja' : '(Saknas)',
					missing_alt_texts: altTextValueHelper(page.missing_alt_texts),
					publish_date: page.publish_date ? new Date(page.publish_date).toLocaleString('sv-SE') : '-',
					unpublish_date: page.unpublish_date ? new Date(page.unpublish_date).toLocaleString('sv-SE') : '-',
					seo_title: page.seo_title ?? '(Saknas)',
					seo_canonical_url: page.seo_canonical_url ?? '-',
					seo_description: page.seo_description ?? '(Saknas)',
					seo_long_description: page.seo_long_description ?? '-',
					seo_keywords: page.seo_keywords ?? '-',
					seo_hidden: page.seo_hidden ? 'Ja' : 'Nej',
					created_at:  new Date(page.created_at).toLocaleString('sv-SE'),
					last_saved: new Date(page.last_saved).toLocaleString('sv-SE'),
					boost_value: boostValue
				};
	
				return modifiedPage;
			});

			return prevPages.concat(modifiedPages);
		}, []);
	}, [pagesData]); 

	// Set how many pages to show on each page of the table
	const [resultsPerPage, setResultsPerPage] = React.useState(25);

	// Calculate the amount of table pages based on the total amount of web pages and 
	// how many to display on each page in the table
	const amountPages = React.useMemo(() => {
		if(!seoPages) return 0;
		
		return Math.ceil(seoPages.length / resultsPerPage);
	}, [resultsPerPage, seoPages]);

	// React Query mutation to trigger a PUT request to update the given page
	const updatePage = useMutation(({ page, alertId }: { page: SeoPage, alertId: string }) => {
		return axios.put(`/navigations/settings/pages/${page.page_uuid}`, page);
	}, {
		onSuccess: (_data, variables) => {
			notification('MODIFY', {
				alertID: variables.alertId,
				priority: AlertPriorityTypes.success,
				title: 'Sida sparad',
				children: `Sidan "${variables.page.title}" har sparats.`
			});

			checkHomePages();
			optimisticUpdate(variables.page);
		},
		onError: (_error, variables) => {
			notification('MODIFY', {
				alertID: variables.alertId,
				priority: AlertPriorityTypes.error,
				title: 'Ett eller flera fel hittades',
				children: `Det gick inte att spara sidan "${variables.page.title}".`
			});
		}
	});

	/**
	 * Optimistic update of the pages state when a page is saved
	 * 
	 * @param {SeoPage} updatedPage
	 * @returns {Promise<void>}
	 */
	const optimisticUpdate = React.useCallback(async(updatedPage: SeoPage): Promise<void> => {
		// Cancel any outgoing refetches
	   // (so they don't overwrite our optimistic update)
	   await queryClient.cancelQueries({ queryKey: ['seo-pages', filters] });

	   // Snapshot the previous value
	   const previousData = queryClient.getQueryData(['seo-pages', filters]);

	   // Optimistically update to the new values
	   queryClient.setQueryData(['seo-pages', filters], () => {
			const oldData = previousData as SeoPages;

			// Get all pages with the same page_uuid
			const oldPages = oldData[updatedPage.page_uuid];
			
			const newPages = oldPages.map(oldPage => {
				let newPage = oldPage;
				
				// If the page is the same language as the updated page
				if(oldPage.lang === updatedPage.lang) {
					newPage = {
						...updatedPage,
						last_saved: new Date().toISOString()
					};
				}
				
				// If the page is not the same language as the updated page
				if(oldPage.lang !== updatedPage.lang) {
					// Only update unpublish_date and publish_date since these props are shared between all languages
					newPage = {
						...oldPage,
						unpublish_date: updatedPage.unpublish_date,
						publish_date: updatedPage.publish_date
					};
				}
				
				return newPage;
			});

			   const newData = {
				...oldData,
				[updatedPage.page_uuid]: newPages
			   };

		   return newData;
	   });
	}, [filters, queryClient]);

	/**
	 * Reset all states to their default values and refetch pages
	 * 
	 * @returns {void}
	 */
	const resetModuleHandler = React.useCallback((): void => {
		setFilters({
			lang: '',
			needle: ''
		});
		refetchPages();
	}, [refetchPages]);

	/**
	 * Return red as highlight color for specific cell values
	 * 
	 * @param {Cell<{}, any>} cell
	 * @returns {string|undefined}
	 */
	const getHighlightColorHandler = React.useCallback((cell: Cell<{}, any>): string | undefined => {
		if(!pagesData) return;
		const cellValue = String(cell.value);
		
		// Get the original page values associated with the cell
		const originalPage = Object.values(pagesData).reduce((prevPages: SeoPage[], currentPages: SeoPage[]) => {
			const cellValues = cell.row.original as SeoPage;

			const page = currentPages.filter(page => page.page_uuid === cellValues.page_uuid && page.lang === cellValues.lang);

			return prevPages.concat(page);
		}, [])[0];

		// If the unpublish date is in the past
		if(cell.column.id === 'unpublish_date') {
			const todaysDate = new Date();
			const unpublishDate = new Date(cellValue);

			if(unpublishDate < todaysDate) return 'var(--red-color)';
		}

		// If there are no h1 or more than one h1
		if(cell.column.id === 'h1') {
			if((originalPage.h1 && originalPage.h1.length > 1) || !originalPage.h1) return 'var(--red-color)';
		}

		// If the column seo_title is missing a value
		if(cell.column.id === 'seo_title' && !originalPage.seo_title) return 'var(--red-color)';

		// If the column seo_description is missing a value
		if(cell.column.id === 'seo_description' && !originalPage.seo_description) return 'var(--red-color)';
	}, [pagesData]);

	/**
	 * Update the filters state when the language select is changed
	 * 
	 * @param {React.ChangeEvent<HTMLSelectElement>} event
	 * @returns {void}
	 */
	const languageChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>): void => {
		setFilters(state => {
			return {
				...state,
				lang: event.target.value
			};
		});
	}, []);
	
	/**
	 * Update the filters state when the search input is changed
	 * 
	 * @param {React.MutableRefObject<HTMLInputElement>} searchValue
	 * @returns {void}
	 */
	const searchHandler = React.useCallback((searchValue: React.MutableRefObject<HTMLInputElement>): void => {
		if(!searchValue) {
			setFilters(state => {
				return {
					...state,
					needle: ''
				};
			});

			return;
		}

		const value = searchValue.current?.value;
		setFilters(state => {
			return {
				...state,
				needle: value
			};
		});
	}, []);

	/**
	 * Opens a modal with page data of the clicked row
	 * 
	 * @param {Row<SeoPage>} row
	 * @returns {void}
	 */
	const rowClickedHandler = React.useCallback((row: Row<SeoPage>): void => {
		if(!pagesData) return;
		
		// Get the original page associated with the selected row
		const page = Object.values(pagesData).reduce((prevPages: SeoPage[], currentPages: SeoPage[]) => {
			const page = currentPages.filter(page => page.page_uuid === row.original.page_uuid && page.lang === row.original.lang);

			return prevPages.concat(page);
		}, [])[0];

		seoPageDetailsModal.open({
			title: 'Redigerar sidan',
			position: 'center',
			width: '60%',
			isDismissable: false,
			actions: [
				{
					text: 'Avbryt',
					isDefault: true,
					action: async (
						originalState: SeoPageDetailsState,
						currentState: SeoPageDetailsState,
						closeModal: () => void
					) => {
						closeModal();
						formValidation.resetErrors();
					}
				},
				{
					text: 'Spara',
					isDefault: false,
					action: async (
						originalState: SeoPageDetailsState,
						currentState: SeoPageDetailsState,
						closeModal: () => void
					) => {
						const page = currentState.page;

						let alertId = notification('SHOW', {
							priority: AlertPriorityTypes.loading,
							title: 'Sparar sida',
							children: `Sparar sida "${page.title}"...`
						});

						updatePage.mutate({ page, alertId });

						closeModal();
					}
				}
			],
			state: {
				page,
				columns: initialColumns
			}
		});
	}, [formValidation, notification, pagesData, seoPageDetailsModal, updatePage]);

	/**
	 * Handles when a column-checkbox is clicked
	 * 
	 * @param {React.MouseEvent<HTMLElement>} event
	 * @param {string} pageID
	 * @returns {void}
	 */
	const columnCheckboxChangedHandler = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, categoryID: string): void => {
		// Add all columns to the state if this checkbox is checked
		if(categoryID === 'all') {
			setColumns(initialColumns);
			return;
		}

		// Add the column to the state if the checkbox is checked
		if(event.target.checked) {
			setColumns(state => {
				// Remove all items that's not included in the columns state from the initial columns array, 
				// except the column that's being added. Then find the index of the column that's being added in the initialColumns array.
				const filteredInitialColumns = initialColumns.filter(column => column.accessor === categoryID || state.find(stateColumn => stateColumn.accessor === column.accessor));
				const index = filteredInitialColumns.findIndex(column => column.accessor === categoryID);

				const column = initialColumns.find(column => column.accessor === categoryID);
				
				if(!column) return state;

				// add the column to the same position as it has in the initialColumns array
				const newArray = [...state.slice(0, index), column, ...state.slice(index)];

				return newArray;
			});
			return;
		}

		// Remove the column from the state if the checkbox is unchecked
		setColumns(state => {
			return state.filter(column => column.accessor !== categoryID);
		});
	}, []);

	return (
		<ModuleContainer header="SEO">
			<ErrorBoundary resetted={resetModuleHandler}>
				{seoPageDetailsModal.getAsComponent(
					<SeoPageDetails
						modal={{} as ModalProps<SeoPageDetailsState>}
						formValidation={formValidation}
					/>
				)}
				<ScFiltersContainer>
					{SUPPORTED_LANGUAGES && SUPPORTED_LANGUAGES.length > 1 && (
						<Select changed={languageChangedHandler}>
							<option value="">
								Alla språk
							</option>
							{SUPPORTED_LANGUAGES.map(language => (
								<option
									key={language.code}
									value={language.code}
								>
									{language.name}
								</option>
							))}
						</Select>
					)}
					<Search
						hasButton
						searchPlaceholder="Navigationstitel, url, titel..."
						searchBtnClicked={searchHandler}
						cleared={searchHandler}
						isDisabled={isLoadingPages || isFetchingPages}
						enableSearchOnEnter
					/>
				</ScFiltersContainer>

				<ScColumnsWrapper>
					<ScColumnTitle>
						Kolumner
					</ScColumnTitle>
					<TreeCategory
						id="all"
						name="Alla"
						isChecked={columns.length === initialColumns.length}
						isEnabled
						checkboxChanged={columnCheckboxChangedHandler}
					>
						<ItemsWrapper isHorizontal={true}>
							{initialColumns.map((initialColumn) => {
								// Check if the initalColumn is stored in the columns state, this means it should be checked
								const selectedColumn = columns.find(column => column.Header === initialColumn.Header);

								return (
									<ScTreeItem
										key={initialColumn.accessor}
										id={initialColumn.accessor}
										text={initialColumn.Header}
										isChecked={!!selectedColumn}
										isEnabled
										checkboxChanged={columnCheckboxChangedHandler}
									/>
								);
							})}
						</ItemsWrapper>
					</TreeCategory>
				</ScColumnsWrapper>
										
				<ScTableContainer>
					{(isLoadingPages || isFetchingPages) && <SkeletonTable />}

					{!isFetchingPages && hasPagesError && (
						<ScMessage
							size="16"
							icon={['fal', 'redo']}
							pressed={refetchPages}
						>
							Ett fel inträffade, ladda om
						</ScMessage>
					)}

					{!isLoadingPages && !seoPages && !hasPagesError && !isFetchingPages && (
						<div>
							Kunde inte hitta några sidor...
						</div>
					)}

					{!isLoadingPages && !hasPagesError && !isFetchingPages && seoPages && (
						<ScReactTable
							overflowXScroll
							nowrap
							maxWidthCell
							isNotFixed
							columns={columns}
							data={seoPages}
							totalResults={seoPages.length}
							amountPages={amountPages}
							resultsPerPage={resultsPerPage}
							hasNativeSorting
							hasNativePagination
							sortableColumns={columns.map(column => column.accessor)}
							getHighlightColor={getHighlightColorHandler}
							rowClickedCallback={rowClickedHandler}
							paginationSelectOptions={['10', '25', '50', '100']}
							resultsPerPageChanged={(ev) => {
								const value = Number(ev.target.value);
								setResultsPerPage(value);
							}}
						/>
					)}
				</ScTableContainer>
			</ErrorBoundary>
		</ModuleContainer>
	);
};

export default Seo;

/**
 * Returns an approtiate h1 value for the table
 * 
 * @param {SeoHeading[] | null} page 
 * @returns {string}
 */
const headingValueHelper = (heading: SeoHeading[] | null): string => {
	// if h1 is null, return '(Saknas)'. It has to be wrapped in parentheses to be able to be sorted correctly
	if(!heading || heading.length <= 0) return '(Saknas)';
	
	// If h1 length is larger than 1, set the new h1 prop to '+' and the exceeded number of h1's
	if(heading.length > 1) return `+${heading.length - 1}`;
	
	return heading[0].text;
};

/**
 * Returns an approtiate alt-text value for the table
 * 
 * @param {SeoAltTexts[] | null} altTexts
 * @returns {string}
 */
const altTextValueHelper = (altTexts: SeoAltTexts[] | null): string => {
	if(!altTexts) return '-';

	const text = altTexts.reduce((prev, current) => {
		if(prev) return prev.concat(', ', current.file_name);
		return current.file_name;
	}, '');
	
	return text;
};

// Columns used in the table
const initialColumns = [
	{
		Header: 'ID',
		accessor: 'navigation_id' // accessor is the "key" in the data
	},
	{
		Header: 'Prioritet',
		accessor: 'boost_value'
	},
	{
		Header: 'Språk',
		accessor: 'lang'
	},
	{
		Header: 'Navigationstitel',
		accessor: 'title'
	},
	{
		Header: 'Skapad datum',
		accessor: 'created_at'
	},
	{
		Header: 'Senast ändrad',
		accessor: 'last_saved'
	},
	{
		Header: 'Publicering',
		accessor: 'publish_date'
	},
	{
		Header: 'Slutpublicering',
		accessor: 'unpublish_date'
	},
	{
		Header: 'H1:a',
		accessor: 'h1'
	},
	{
		Header: 'H2:a',
		accessor: 'h2'
	},
	{
		Header: 'Senast ändrad av',
		accessor: 'last_edited_by'
	},
	{
		Header: 'URL',
		accessor: 'slug'
	},
	{
		Header: 'Titel',
		accessor: 'seo_title'
	},
	{
		Header: 'Cannonical URL',
		accessor: 'seo_canonical_url'
	},
	{
		Header: 'Beskrivning',
		accessor: 'seo_description'
	},
	{
		Header: 'Lång beskrivning',
		accessor: 'seo_long_description'
	},
	{
		Header: 'Nyckelord',
		accessor: 'seo_keywords'
	},
	{
		Header: 'Dold för sökmotorer',
		accessor: 'seo_hidden'
	},
	{
		Header: 'Bild(er) som saknar alt-text',
		accessor: 'missing_alt_texts'
	}
];

const ScTableContainer = styled.div`
	margin-bottom: 24px;
`;

const ScFiltersContainer = styled.div`
	display: flex;
	gap: 16px;
`;

const ScMessage = styled(Message)`
	margin-bottom: 24px;
	margin-top: 16px;
`;

const ScReactTable = styled(ReactTable)`
	margin-top: 16px;
`;

const ScTreeItem = styled(TreeItem)`
	margin-right: 16px;
`;

const ScColumnsWrapper = styled.div`
	margin: 16px 0 32px;
`;

const ScColumnTitle = styled(ControlPanelHeading)`
	margin-bottom: 16px;
`;