import React from 'react';
import BlockVideo from './BlockVideo';
import BlockVideoSettings from './Settings/BlockVideoSettings';
import { BlockVideoType } from './BlockVideo.types';
import { VIDEO_PLATFORMS } from '../../../../settings';
import { BuilderBlock } from '../../Block.types';
import { CloseModal, ModalConfig, ModalProps } from '../../../../hooks/useModal/useModal.model';
import { FormValidation } from '../../../../hooks/useFormValidation/types';

/**
 * Return an array with the opts (option buttons when hovering) to show for this block.
 *
 * @param {useModal} modal 				        The useModal instance used to open and show this block's settings.
 * @param {function} changed 				    The function used to notify Builder when this block changes.
 * @param {function} removeBlock			    A function used to notify Builder to remove this block.
 * @param {useFormValidation} formValidation 	The useFormValidation instance used to validate this modals settings inputs.
 */
export const generateOpts = (
	openModal: (config: ModalConfig<BuilderBlock<BlockVideoType>>) => void,
	closeModal: CloseModal,
	changed: (blockState: BuilderBlock<BlockVideoType>) => void,
	removeBlock: (blockState: BuilderBlock<BlockVideoType>) => void,
	formValidation: FormValidation
) => {
	return [
		{
			icon: ['fal', 'gear'],
			action: (blockData: BuilderBlock<BlockVideoType>) => {
				const modalActions = [
					{
						text: 'Avbryt',
						isDefault: true,
						action: (originalState:  BuilderBlock<BlockVideoType>, currentState:  BuilderBlock<BlockVideoType>, closeModal: CloseModal) => {
							// Reset builder block settings
							formValidation.resetErrors();
							changed(blockData);
							closeModal();
						}
					},
					{
						text: 'Klar',
						action: (originalState: BuilderBlock<BlockVideoType>, currentState: BuilderBlock<BlockVideoType>, closeModal: CloseModal) =>
							// Only close the modal if the required forms are valid.
							formValidation.submit(() => {
								// Notify builder to update all block's atributes before closing the modal
								changed(currentState);
								closeModal();
							})
					}
				];

				openModal({
					title: 'Redigerar blockVideo',
					actions: modalActions,
					height: false,
					position: 'right',
					width: '416px',
					hideBackdrop: true,
					isPositionable: true,
					isDismissable: false,
					isDraggable: false,
					state: blockData
				});
			}
		},
		{
			icon: ['fal', 'trash'],
			action: (blockData: BuilderBlock<BlockVideoType>) => {
				// Tell the builder to remove the block
				removeBlock(blockData);
				closeModal();
			}
		}
	];
};

const baseStruct = {
	baseStruct: {
		key: null,
		data: {
			autoplay: false,
			autoplay_playlist: false,
			caption: '',
			caption_active: false,
			media: {
				src: null,
				uuid: null,
				thumbnail: null
			},
			mobile_media: {
				src: null,
				uuid: null,
				thumbnail: null
			},
			mute: false,
			provider: VIDEO_PLATFORMS.length > 0 ? VIDEO_PLATFORMS[0] : VIDEO_PLATFORMS,
			show_info: false,
			thumbnail_active: false,
			type: 'video',
			video_id: ''
		}
	},
	builder: {
		component: <BlockVideo />,
		setOpts: generateOpts
	},
	settings: {
		component: <BlockVideoSettings
			modal={{} as ModalProps<BuilderBlock<BlockVideoType>>}
			formValidation={{} as FormValidation}
		           />
	}
};

export default baseStruct;