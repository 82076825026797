import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import PublishDateSelector from './../../../../components/UI/PublishDateSelector';
import useModal from '../../../../hooks/Modal/useModal';
import TabContainer from '../../../../components/Tabs/TabContainer';
import Tab from '../../../../components/Tabs/Tab';
import AdvancedSettings from '../Page/AdvancedSettings';
import {
	Button,
	Message,
	ImageThumbnail,
	MediaPlaceholder,
	Icon,
	TooltipWithClick
} from '../../../../components/UI';
import { TextInput, CheckItem, Textarea, Select } from '../../../../components/Forms';
import MediaArchive from '../../../MediaArchive/MediaArchive';
import { fetchPage } from '../../../../store/thunks/thunk-cache';
import { SkeletonSettings } from '../../../../components/Skeletons';
import Label from '../../../../components/Forms/Label/Label';
import useAlert, { AlertPriorityTypes } from '../../../../hooks/useAlert';
import usePageLanguage from '../../../../hooks/usePageLanguage/usePageLanguage';
import { SUPPORTED_LANGUAGES, SHOW_SEO_LONG_DESCRIPTION } from '../../../../settings';
import { SEO_TITLE_MAX_LENGTH, SEO_DESCRIPTION_MAX_LENGTH } from '../../../../modules/Seo/containers/Seo.types';
import { getSeoInfoText } from '../../../../modules/Seo/containers/SeoPageDetails';
import GeneralNavItemSettings from '../../../../components/GeneralNavItemSettings/GeneralNavItemSettings';

/**
 *
 * @param templates			Array of available templates
 * @param modal				Object with useModal state, setState, etc
 */
const PageSettings = (props) => {
	// redux dispatcher
	const dispatch = useDispatch();

	// Returns the active page language
	const { activeLanguage } = usePageLanguage();

	// This component is intended to be used inside a Modal, data is coming from modal's state
	const { currentState, updateState } = props.modal;

	// fetch the actual tree data structure
	const navigationItems = useSelector(
		(state) => state.cache.navigation.items
	);

	const [dataFetched, setDataFetched] = useState(false);

	// create a new modal instance for the Media Archive
	const mediaArchiveModal = useModal();
	const openMediaArchiveModal = mediaArchiveModal.open;
	const closeMediaArchiveModal = mediaArchiveModal.close;
	const getAsComponentMediaArchiveModal = mediaArchiveModal.getAsComponent;

	const formValidation = props.formValidation;

	const alert = useAlert()[1];

	const openMediaArchive = useCallback(
		() =>
			openMediaArchiveModal({
				position: 'center',
				width: '80%',
				height: '80%',
				isDismissable: true,
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						isVisible: false,
						action: (originalState, currentState, closeModal) =>
							closeModal()
					}
				]
			}),
		[openMediaArchiveModal]
	);

	/**
	 * Updates state when something changes it's value.
	 *
	 * @param {string} property				The property to change
	 * @param {*} newValue					The new value
	 * @param {boolean} isLangugageSpecific					
	 */
	const stateChangedHandler = React.useCallback((property, newValue, isLangugageSpecific) => {
		if(isLangugageSpecific && SUPPORTED_LANGUAGES.length > 0) {
			updateState({
				page: {
					[property]: { $set: newValue },
					l10n: {
						[activeLanguage]: {
							[property]: { $set: newValue }
						}
					} 
				}
			});

			return;
		}

		updateState({
			page: {
				[property]: { $set: newValue }
			}
		});
	}, [activeLanguage, updateState]);

	/**
	 * Triggered when publish or unpublish date-time changes.
	 *
	 * @param {string} propName 		publish_date or unpublish_date depending on what changed
	 * @param {string|null} value 		The new value
	 */
	const publishingChangedHandler = useCallback(
		(propName, value) => {
			const notPublished = propName === 'publish_date' && value === null;
			const changingUnpublishDate = propName === 'unpublish_date';

			// remove the unpublish date from state if the page is not published at all.
			let unpublishValue = null;
			switch(notPublished) {
				case true:
					unpublishValue = null;
					break;

				default:
					unpublishValue = changingUnpublishDate
						? value
						: currentState.page.unpublish_date;
					break;
			}

			updateState({
				page: {
					[propName]: {
						$set: value
					},

					// remove the unpublish date from state if the page is not published at all.
					unpublish_date: {
						$set: unpublishValue
					}
				}
			});
		},
		[currentState.page.unpublish_date, updateState]
	);

	/**
	 * Media Archive returns an array of objects
	 * Uses the first object (as ImageBlock only allows one image)
	 * And updates the state and closes the modal
	 *
	 * @param {array} files		Array of ItemManager item that where selected.
	 */
	const imageSelectedHandler = useCallback(
		(files) => {
			const file = files[0];

			updateState({
				page: {
					media: {
						uuid: {
							$set: file.id
						},
						src: {
							$set: file.src
						},
						thumbnail: {
							$set: file.thumbnail
						}
					}
				}
			}).then(() => {
				closeMediaArchiveModal();
			});
		},
		[closeMediaArchiveModal, updateState]
	);

	const imageThumbnailActions = useMemo(
		() => [
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'gear']}
				           />,
				action: openMediaArchive
			},
			{
				component: <Icon
					color={'#fafafa'}
					icon={['fal', 'trash']}
				           />,
				action: () => {
					imageSelectedHandler([{ uuid: null }]);
				},
				styles: { hover: { backgroundColor: '#DA534D' }}
			}
		],
		[imageSelectedHandler, openMediaArchive]
	);

	/**
	 * Fetch page's data, will remove loader when populated.
	 */
	useEffect(() => {
		if(!dataFetched)
			dispatch(fetchPage(currentState.page.uuid)).then((data) => {
				const rootNavigation =
					navigationItems[currentState.rootNavigationId];

				let templates = Object.values(props.templates)

				// Add enabled prop to templates to be able to show them as disabled
				//	in a dropdown, the template is enabled if it's in the allowances.
					.map((t) => ({
						...t,

						enabled: rootNavigation.allowances.templates.includes(
							t.id
						)
					}))

				// Filter out templates that are not allowed
				//	Leave template that are not allowed but currently as the selected template for the page
					.filter(
						(template) =>
						// only leave allowed templates
							rootNavigation.allowances.templates.includes(
								template.id
							) ||
							// leave the currently in use template, even if not allowed
							template.id === data.template_id
					);

				// Handle the default tempalte if none given/null
				const fallbackTemplateId = Object.values(props.templates).find(
					(template) => {
						return template.fallback;
					}
				).id;

				// The used template for the page, if null, use the fallback template
				updateState({
					page: {
						$set: {
							...data,
							template_id: data.template_id || fallbackTemplateId
						}
					},
					navItemParent: { $set: navigationItems[data.parent] },

					templates: { $set: templates },

					//the data for the page has been fetched from back-end
					dataFetched: { $set: true }
				});

				setDataFetched(true);
			});
	}, [
		dataFetched,
		currentState.page.uuid,
		dispatch,
		updateState,
		navigationItems,
		props.templates,
		currentState.rootNavigationId
	]);

	return (
		<>
			{SUPPORTED_LANGUAGES.length > 0 && (
				<ScLanguage>
					{SUPPORTED_LANGUAGES.find(language => language.code === activeLanguage).name}
				</ScLanguage>
			)}
			
			{getAsComponentMediaArchiveModal(
				<MediaArchive
					mediaChosen={imageSelectedHandler}
					maxSelectionAmount={1}
					fileShowOnly={['image']}
				/>
			)}

			{/* Show a skeleon if no data is set yet...have to wait for useEffect to fetch and populate data */}
			{!dataFetched ? (
				<SkeletonSettings />
			) : (
				<TabContainer
					disableAllTabsButActive={formValidation.formHasErrors()}
				>
					<Tab title="Grundläggande">
						<TextInput
							label="Navigationstitel"
							description="Namnet på sidan när den visas i navigationen."
							isRequired
							name="title"
							id="title"
							value={currentState.page.title || ''}
							formValidationUnregister={
								formValidation.unregisterElement
							}
							error={formValidation.errors['title']}
							changed={(ev, ...data) => {
								formValidation.watch(
									ev,
									(ev) =>
										stateChangedHandler(
											ev.target.name,
											ev.target.value,
											true
										),
									data
								);
							}}
							inputRef={(ref) =>
								formValidation.registerElement(ref, {
									required: true
								})}
						/>

						<TextInput
							label="Unik adress (URL)"
							description="Den unika adressen till sidan."
							isRequired
							name="slug"
							id="slug"
							value={currentState.page.slug || ''}
							formValidationUnregister={
								formValidation.unregisterElement
							}
							error={formValidation.errors['slug']}
							changed={(ev, ...data) => {
								formValidation.watch(
									ev,
									(ev) =>
										stateChangedHandler(
											ev.target.name,
											ev.target.value,
											true
										),
									data
								);
							}}
							inputRef={(ref) =>
								formValidation.registerElement(ref, {
									required: true,
									validation: 'slug'
								})}
						/>
						{SUPPORTED_LANGUAGES.length > 0 && (
							<CheckItem
								type="checkbox"
								title="Markera sidan som utkast"
								description="Ändringar sparas men sidan för det valda språket kommer ej publiceras."
								changed={(ev) =>
									stateChangedHandler(
										ev.target.name,
										ev.target.checked,
										true
									)}
								name="is_draft"
								checked={currentState.page.is_draft}
							/>
						)}

						<GeneralNavItemSettings label="Inställningar nedan appliceras på alla översättningar för sidan">

							<CheckItem
								type="checkbox"
								title="Markera som startsida"
								changed={(ev) =>
									stateChangedHandler(
										ev.target.name,
										ev.target.checked
									)}
								name="homepage"
								checked={currentState.page.homepage}
							/>
							<PublishDateSelector
							// Publish date
								publish={currentState.page.publish_date}
								publishChanged={(newValue) =>
									publishingChangedHandler(
										'publish_date',
										newValue
									)}
							// Un-publish/end datetime
								unpublish={currentState.page.unpublish_date}
								unpublishChanged={(newValue) =>
									publishingChangedHandler(
										'unpublish_date',
										newValue
									)}
								onError={() =>
									alert('SHOW', {
										priority: AlertPriorityTypes.warn,
										title: 'Publiceringsdatum',
										children:
										'Slut datumet kan inte vara samma eller innan publicerings-datumet'
									})}
							/>

							<Button
								onClick={async (ev) => {
									ev.stopPropagation();
									await props.duplicate(currentState.page);
									props.modal.close();
								}}
							>
								Duplicera sida
							</Button>

						</GeneralNavItemSettings>
					</Tab>

					<Tab title="SEO">
						<Message
							style={{ marginBottom: 16 }}
							isSmall
						>
							Sökmotoroptimering (SEO) gör det möjligt för dig att förbättra din 
							ranking i sökresultat. Använd dessa funktioner för att göra det 
							lättare för användare att hitta din sida när de söker efter den.
						</Message>

						<TextInput
                            //show an info box with the total amount of characters and the recommended length of the title.
							info={getSeoInfoText(currentState.page.seo_title, SEO_TITLE_MAX_LENGTH)}
							label="SEO Titel"
							changed={(ev) =>
								stateChangedHandler(
									ev.target.name,
									ev.target.value,
									true
								)}
							name="seo_title"
							value={currentState.page.seo_title || ''}
						/>

						<TextInput
							label="Canonical URL"
							changed={(ev) =>
								stateChangedHandler(
									ev.target.name,
									ev.target.value,
									true
								)}
							name="seo_canonical_url"
							value={currentState.page.seo_canonical_url || ''}
						/>

						<Textarea
							label="SEO Beskrivning"
                            //show an info box with the total amount of characters and the recommended length of the description.
							info={getSeoInfoText(currentState.page.seo_description, SEO_DESCRIPTION_MAX_LENGTH)}
							changed={(ev) =>
								stateChangedHandler(
									ev.target.name,
									ev.target.value,
									true
								)}
							name="seo_description"
							value={currentState.page.seo_description || ''}
							resizeVertical
						/>

						{SHOW_SEO_LONG_DESCRIPTION && (
							<Textarea
								label="SEO Lång beskrivning"
								changed={(ev) =>
									stateChangedHandler(
										ev.target.name,
										ev.target.value,
										true
									)}
								name="seo_long_description"
								value={currentState.page.seo_long_description || ''}
								resizeVertical
							/>
						)}

						<ScLabel htmlFor="boost_value">
							Prioriet
							<TooltipWithClick
								isRight
								icon={['fal', 'question-circle']}
							>
								<p>
									Låg = FAQs, föråldrad information, gamla pressmeddelanden, helt statiska sidor som fortfarande är tillräckligt relevanta för att inte raderas helt.
								</p>
								<ScTooltipText>
									Mellan = Nyhetsartiklar, vissa vädertjänster, blogginlägg, sidor som ingen sida skulle vara komplett utan.
								</ScTooltipText>
								<p>
									Hög = Hemsida, produktinformation, landningssidor.
								</p>
							</TooltipWithClick>
						</ScLabel>

						<Select
							id="boost_value"
							changed={(ev) => stateChangedHandler(ev.target.name, ev.target.value, true)}
							value={currentState.page.boost_value || 'none'}
							name="boost_value"
						>
							{!currentState.page.boost_value && (
								<option
									value="none"
									disabled
								>
									Ingen prioriet
								</option>
							)}
							<option value="high">
								Hög
							</option>
							<option value="default">
								Mellan
							</option>
							<option value="low">
								Låg
							</option>
						</Select>

						<TextInput
							label="Meta keywords"
							changed={(ev) =>
								stateChangedHandler(
									ev.target.name,
									ev.target.value,
									true
								)}
							name="seo_keywords"
							value={currentState.page.seo_keywords || ''}
						/>
						
						<CheckItem
							type="checkbox"
							title="Dölj för sökmotorsresultat"
							changed={(ev) =>
								stateChangedHandler(
									ev.target.name,
									ev.target.checked,
									true
								)}
							name="seo_hidden"
							checked={currentState.page.seo_hidden}
						/>
					</Tab>

					<Tab title="Media">
						<Label
							label="Utvald bild"
							description="This image is often used for header-area banners or
							as a thumbnail in a list."
						/>
						{currentState.page.media &&
						currentState.page.media.thumbnail ? (
							<ScImageThumbnail
								height="200px"
								width="100%"
								style={{ marginBottom: 16 }}
								actions={imageThumbnailActions}
								thumbnail={currentState.page.media.thumbnail}
							/>
							) : (
								<ScMediaPlaceholder>
									<Button
										onClick={(e) => {
											e.stopPropagation();
											openMediaArchive();
										}}
									>
										Välj bild
									</Button>
								</ScMediaPlaceholder>
							)}
					</Tab>

					<Tab title="Avancerat">
						<AdvancedSettings
							data={currentState.page}
							templates={currentState.templates}
							changed={stateChangedHandler}
							publishingChanged={publishingChangedHandler}
						/>
					</Tab>
				</TabContainer>
			)}
		</>
	);
};

PageSettings.propTypes = {
	modal: PropTypes.object,
	templates: PropTypes.object,
	duplicate: PropTypes.func,
	formValidation: PropTypes.object
};

export default PageSettings;

const ScMediaPlaceholder = styled(MediaPlaceholder)`
	background: var(--bg-bright-color);
	border: 1px solid var(--bg-dark-grey-color);
`;

const ScImageThumbnail = styled(ImageThumbnail)`
	background-color: var(--bg-bright-color);
	padding: 0;

	#ScBorder {
		border: 1px solid var(--bg-dark-grey-color);
	}

	:hover {
		#ScBorder {
			border: 1px solid var(--bg-dark-grey-color);
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

export const ScInfoText = styled.p`
	font-size: 14px;
	align-items: center;
	display: flex;
	background-color: var(--bg-dark-color);
	color: var(--font-bright-color);
	padding: 8px;
`;

const ScLanguage = styled.p`
	position: absolute;
	z-index: 1000;
	top: 12px;
	right: 16px;
	font-size: 14px;
	font-weight: 300;
	color: grey;
`;

export const ScNavItemSettings = styled.div`
	${props => props.hasLanguageSupport && css`
		border-radius: 4px;
		border: 2px solid var(--bg-dark-color);
	`}
`;

export const ScInnerWrapper = styled.div`
	${props => props.hasLanguageSupport && css`
		background-color: var(--bg-bright-color);
		padding: 8px;
		padding-top: 16px;
		border-radius: 4px;
	`}
`;

const ScTooltipText = styled.p`
	margin: 8px 0;
`;

const ScLabel = styled.label`
	display: flex;
	font-size: 14px;
	margin-bottom: 4px;
`;