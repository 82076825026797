import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Cell, HeaderGroup, Row, usePagination, useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import axios from './../../../utils/oc-axios';
import { User, UserListItem, UsersProps } from './model.Users';
import { USERS_LIST_COLUMNS } from './consts.Users';
import { renderOrdersTableCellContent } from './func.Users';
import ModuleContainer from '../../../components/Content/ModuleContainer/ModuleContainer';
import Search from '../../../components/Search/Search';
import {
	Table,
	TableCell,
	TableHead,
	TableHeading,
	TableBody,
	TableRow,
	TablePagination
} from '../../../components/Table/index';
import UserDetails from '../../../components/ControlPanel/Users/UserDetails/UserDetails';
import useModal from '../../../hooks/Modal/useModal';
import { SortingState } from '../../../definitions/Sorting';
import { RootState } from '../../../store/types/RootTypes';
import PaginationInfo from '../../../definitions/PaginationInfo';
import { fetchAllListItemUsers } from '../../../store/thunks/ControlPanel/thunk-users';
import ErrorBoundary from '../../../hoc/ErrorBoundary/ErrorBoundary';
import { SkeletonTable } from '../../../components/Skeletons';
import useAlert, { AlertPriorityTypes } from '../../../hooks/useAlert';
import { AlertDispatch } from '../../../hooks/useAlert/types.useAlert';
import withErrorBoundary from '../../../hoc/withErrorBoundary';
import useFormValidation from '../../../hooks/useFormValidation/useFormValidation';

const Users: React.FC<UsersProps> = (props) => {
	const dispatch = useDispatch<any>();

	const userDetailsModal = useModal();

	const notification = useAlert()[1] as AlertDispatch;

	const formValidation = useFormValidation();

	// A simple counter to update to trigger a refetch of the list, like when remoing a user, and you wanna refetch.
	const [reloadCounter, setReloadCounter] = useState<number>(0);

	// Show skeleton while fetch is loading
	const [isLoading, setLoading] = useState(true);

	const [sort] = useState<SortingState>({
		// the name of a property of UserListItem to sort after.
		sortBy: 'name',

		// ascending or descending
		sortOrder: 'asc'
	});

	const [searchText, setSearchText] = useState<string>('');

	// all the users from the Redux state.
	const users = useSelector(
		(state: RootState) => state.usersManagement.listItemUsers
	) as UserListItem[];

	// information about how to paginate from the Redux state.
	const paginationInfo: PaginationInfo = useSelector((state: RootState) => {
		return state.usersManagement.paginationInfo;
	});

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		// Get the state from the instance
		state: { pageIndex, pageSize }
	} = useTable(
		{
			columns: USERS_LIST_COLUMNS,
			data: users,
			initialState: { pageIndex: 0, pageSize: 50 },
			manualPagination: true,

			// Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: paginationInfo.pageCount
		},

		usePagination
	);

	/**
	 * Opens a modal to create a new user.
	 * 
	 * @return {void}
	 */
	const openNewUserModal = React.useCallback((): void => {
		// Creating a new user
		userDetailsModal.open({
			title: 'Skapar användare',
			width: '98%',
			height: '98%',
			isDismissable: 'true',
			isBackdropBlurred: true,
			actions: [
				{
					text: 'Stäng',
					isDefault: true,
					action: (
						_oState: number,
						_cState: number,
						closeModal: any
					) => {
						formValidation.resetErrors();
						closeModal();
					}
				},
				{
					text: 'Lägg till',
					action: (
						_oState: number,
						newUserData: User,
						closeModal: any
					) => {
						formValidation.submit(() => {
							let alertId = notification('SHOW', {
								priority: AlertPriorityTypes.loading,
								title: 'Skapar användare',
								children: `Skapar användare ${newUserData.name}...`
							});
							axios
								.post('modules/users', newUserData)
								.then(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.success,
										title: 'Användare skapad',
										children: `Användaren ${newUserData.name} har blivit skapad.`
									});
									closeModal();
								})

								.catch(() => {
									notification('MODIFY', {
										alertID: alertId,
										priority: AlertPriorityTypes.error,
										title: 'Ett eller flera fel hittades',
										children: `Det gick inte att skapa användaren ${newUserData.name}.`
									});
								})

								// trigger update list of users
								.finally(() => {
									formValidation.resetErrors();
									setReloadCounter(reloadCounter + 1);
								});
						});
					}
				}
			],
			// send object with id -1 to indicate a new user is being created
			state: {
				id: -1,
				email: '',
				name: '',
				language: 'sv',
				login_name: '',
				enabled: false,
				verified: false,
				privileges: {},
				groups: []
			}
		});
	}, [formValidation, notification, reloadCounter, userDetailsModal]);

	/**
	 * Opens a modal to edit an user.
	 */
	const openEditUserModal = React.useCallback(
		(user: UserListItem) => {
			// Editing a user.
			userDetailsModal.open({
				title: `Redigerar ${user?.name}`,
				width: '98%',
				height: '98%',
				isDismissable: 'true',
				actions: [
					{
						text: 'Stäng',
						isDefault: true,
						action: (
							_oState: number,
							_cState: number,
							closeModal: any
						) => {
							formValidation.resetErrors();

							closeModal();
						}
					},
					{
						text: 'Spara',
						action: (
							_oState: number,
							_cState: User,
							closeModal: any
						) => {
							formValidation.submit(() => {
								const user = _cState;

								let alertId = notification('SHOW', {
									priority: AlertPriorityTypes.loading,
									title: 'Uppdaterar användare',
									children: `Sparar användaren ${user.name}...`
								});

								axios
									.put(`modules/users/${user.id}`, user)
									.then(() => {
										notification('MODIFY', {
											alertID: alertId,
											priority:
												AlertPriorityTypes.success,
											title: 'Användare uppdaterad',
											children: `Användaren ${user.name} har sparats.`
										});
										closeModal();
									})

									.catch(() => {
										notification('MODIFY', {
											alertID: alertId,
											priority: AlertPriorityTypes.error,
											title: 'Ett eller flera fel hittades',
											children: `Det gick inte att uppdatera användaren ${user.name}.`
										});
									})

									// trigger update list of users
									.finally(() => {
										formValidation.resetErrors();

										setReloadCounter(reloadCounter + 1);
									});
							});
						}
					}
				],

				state: {
					id: user.id,
					email: '',
					name: '',
					language: 'sv',
					login_name: '',
					enabled: false,
					verified: false,
					privileges: {},
					groups: []
				}
			});
		},
		[formValidation, notification, reloadCounter, userDetailsModal]
	);

	/**
	 * Opens a modal with the user's details
	 * 
	 * @return {void}
	 */
	const openUserDetails = useCallback(
		(user: UserListItem | null): void => {
			// if editing a user, it should not be null
			if(!user) {
				openNewUserModal();
			} else {
				openEditUserModal(user);
			}
		},

		[openEditUserModal, openNewUserModal]
	);

	/**
	 * Handles when user clicks the button to search.
	 * Will use the current value from the text input and set it to a local state
	 *
	 * @param {HTMLElement} input
	 */
	const searchButtonClickedHandler = useCallback((input: any) => {
		const value = input.current.value;
		gotoPage(0);
		setSearchText(value);
	}, [gotoPage, setSearchText]);

	/**
	 * Clears the search input texts when x button is clicked.
	 */
	const searchClearedHandler = useCallback(() => {
		gotoPage(0);
		setSearchText('');
	}, [gotoPage, setSearchText]);

	/**
	 * Increases the reload counter to triger a refetch by the useEffect.
	 */
	const reloadUsersList = useCallback(() => {
		setReloadCounter(reloadCounter + 1);
	}, [reloadCounter, setReloadCounter]);

	/**
	 * Fetch groups the first time and when searchtext, sorting or page index/size changes.
	 */
	useEffect(() => {
		setLoading(true);
		dispatch(
			fetchAllListItemUsers(
				searchText,
				sort.sortBy,
				sort.sortOrder,
				pageIndex + 1,
				pageSize
			)
		).then(() => setLoading(false));
	}, [
		searchText,
		sort.sortBy,
		sort.sortOrder,
		pageIndex,
		pageSize,
		dispatch,
		reloadCounter
	]);

	return (
		<>
			{userDetailsModal.getAsComponent(
				<UserDetails
					reloadUsersList={reloadUsersList}
					formValidation={formValidation}
				/>
			)}

			<ModuleContainer
				hasButton
				buttonText="Skapa användare"
				buttonIcon={['fal', 'plus']}
				header="Användare"
				buttonOnClick={() => openUserDetails(null)}
			>
				<ScSearch
					hasButton
					searchPlaceholder="Sök på namn, användarnamn eller e-postadress"
					searchBtnClicked={searchButtonClickedHandler}
					isDisabled={isLoading}
					cleared={searchClearedHandler}
					enableSearchOnEnter
				/>

				<ErrorBoundary>
					<TablePagination
						page={page}
						pageIndex={pageIndex}
						pageCount={pageCount}
						totalResults={paginationInfo.totalResults}
						pageOptions={pageOptions}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
						isDisabled={isLoading}
					/>
					<Table
						overFlowScroll
						{...getTableProps()}
					>
						<TableHead>
							{headerGroups.map((headerGroup: HeaderGroup) => (
								<div
									style={{ display: 'table-row' }}
									{...headerGroup.getHeaderGroupProps()}
								>
									{headerGroup.headers.map(
										(column: HeaderGroup) => (
											<TableHeading
												isSortable={false}
												isActive={
													sort.sortBy === column.id
												}
												sortOrder={sort.sortOrder}
												{...column.getHeaderProps()}
											>
												{column.render('Header')}
											</TableHeading>
										)
									)}
								</div>
							))}
						</TableHead>

						<TableBody {...getTableBodyProps()}>
							{page.map((row: Row<any>) => {
								prepareRow(row);

								return (
									<TableRow {...row.getRowProps()}>
										{row.cells.map(
											(cell: Cell<UserListItem>) => {
												return (
													<TableCell
														clicked={() =>
															openUserDetails(
																cell.row
																	.original
															)}
														{...cell.getCellProps()}
													>
														{renderOrdersTableCellContent(
															cell
														)}
													</TableCell>
												);
											}
										)}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</ErrorBoundary>

				<div>
					{isLoading && users.length === 0 && (
						// Use our custom loading state to show a loading indicator
						<SkeletonTable />
					)}
					<TablePagination
						page={page}
						pageIndex={pageIndex}
						pageCount={pageCount}
						totalResults={paginationInfo.totalResults}
						pageOptions={pageOptions}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
						isDisabled={isLoading}
					/>
				</div>
			</ModuleContainer>
		</>
	);
};
export default withErrorBoundary(Users);

const ScSearch = styled(Search)`
	margin-bottom: 24px;
`;
