import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
// Extending the prototype of string
// Do not remove...
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import * as extensions from './utils/extensions';
import { SENTRY_DSN } from './settings';
import App from './App';

// Initialize Sentry before React so it catches errors
if(process.env.REACT_APP_ENV !== 'local') {
	Sentry.init({
		environment: process.env.NODE_ENV,
		dsn: SENTRY_DSN,
		normalizeDepth: 10, // How deep the context will be, specially for Redux.

		ignoreErrors: ['ResizeObserver loop limit exceeded']
	});
}

// if in development send user to subdomain cms.oc.io
// if(process.env.REACT_APP_ENV === 'development' && window.location.hostname === 'localhost') {
// 	window.location.replace("http://cms.oc.io:10005");
// }

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// eslint-disable-next-line @typescript-eslint/naming-convention
	<BrowserRouter future={{ v7_startTransition: true }}>
		<App />
	</BrowserRouter>
);