import { ReactNode } from 'react';
import { Cell } from 'react-table';
import { UserListItem } from './model.Users';
import { USERSLIST_COLUMNS_ENABLED } from './consts.Users';

/**
 *
 * Generates a TableCell with custom content.
 *
 * @param cell 			The cell data to work on.
 * @returns {TableCell}
 */
export const renderOrdersTableCellContent = (cell: Cell<UserListItem>) => {
	let cellContent: string | ReactNode = '';

	// Only work on data if there is any,
	//	otherwise return with the default cellContent of empty string.
	if(!cell.value) return cellContent;

	// id is the same as 'accessor' when defining table columns
	switch(cell.column.id) {
		case USERSLIST_COLUMNS_ENABLED:
			cellContent = cell.value ? 'Ja' : 'Nej';
			break;

		default:
			cellContent = cell.render('Cell');
			break;
	}

	return cellContent;
};
