import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
	TextInputProps,
	ScIconContainerProps,
	StyledInputProps
} from './model.TextInput';
import Label from '../Label/Label';
import Icon from '../../UI/Icon/Icon';

const TextInput: React.FC<TextInputProps> = (props) => {
	const inputRef = React.useRef<HTMLInputElement | null>(null);

	// in case form validation is used fetch the unregister method from the object
	// doing it it this way will help useEffect to be more efficient instead of providing
	// the entire formValidation object in it's depts
	const unregisterElement = props.formValidationUnregister;

	// Determines whether an error message should be shown or not
	const hasError =
		props.error && Array.isArray(props.error) && props.error.length > 0;

	React.useEffect(() => {
		return () => {
			// if form validation is used, then unregister listener when input component unmounts
			if(unregisterElement && props.id) {
				unregisterElement(props.id);
			}
		};
	}, [props.id, unregisterElement]);

	let input = (
		<>
			<ScInputContainer hasMaxWidth={props.hasMaxWidth}>
				{(props.leftIcon || props.rightIcon) && (
					<>
						{props.leftIcon && (
							<ScIconContainer leftIcon={props.leftIcon}>
								<Icon icon={props.leftIcon} />
							</ScIconContainer>
						)}

						{props.rightIcon && (
							<ScIconContainer rightIcon={props.rightIcon}>
								<Icon icon={props.rightIcon} />
							</ScIconContainer>
						)}
					</>
				)}
				<ScInput
					ref={props.inputRef ? props.inputRef : inputRef}
					id={props.id}
					name={props.name}
					type={props.type ? props.type : 'text'}
					min={0}
					className={props.className}
					style={props.style}
					maxLength={props.maxLength}
					disabled={props.isDisabled}
					tabIndex={props.tabIndex}
					readOnly={props.isReadOnly}
					onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
						ev.stopPropagation();
						if(
							props.changed &&
							typeof props.changed === 'function'
						)
							props.changed(ev, props, ev.target, inputRef);
					}}
					onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
						ev.stopPropagation();
						if(
							props.blurred &&
							typeof props.blurred === 'function'
						)
							props.blurred(ev, props, ev.target, inputRef);
					}}
					onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement>) => {
						ev.stopPropagation();
						if(
							props.pressed &&
							typeof props.pressed === 'function'
						) {
							props.pressed(ev, props, ev.target, inputRef);
						}
					}}
					rightIcon={props.rightIcon}
					leftIcon={props.leftIcon}
					preventBuilderPreview={props.preventBuilderPreview}
					hasError={hasError}
					placeholder={props.placeholder}
					placeholderColor={props.placeholderColor}
					value={props.value === null ? '' : props.value}
				/>
				{hasError && (
					<ScError>
						{props.error}
					</ScError>
				)}
				{props.info && (
					<ScInfo>
						{props.info}
					</ScInfo>
				)}
			</ScInputContainer>
		</>
	);

	// if the omitLavel prop is not provided, then the input will be
	// wrapped in a label
	if(!props.omitLabel) {
		input = (
			<ScLabel
				label={props.label}
				description={props.description}
				isRequired={props.isRequired}
				style={props.style}
				className={props.className}
				hasMaxWidth={props.hasMaxWidth}
			>
				{input}
			</ScLabel>
		);
	}

	return input;
};

export default TextInput;

const ScLabel = styled(Label)<StyledInputProps>`
	${props => props.hasMaxWidth && css`
		width: 100%;
	`}
`;

const ScInputContainer = styled.div<StyledInputProps>`
	position: relative;
	flex:1;
	
	${props => props.hasMaxWidth && css`
		width: 100%;
	`}
`;

const ScError = styled.div`
	background: rgba(208, 2, 27, 0.1);
	border: 1px solid #d0021b;
	padding: 8px;
	margin-top: -1px;
	font-size: 12px;
	color: #d0021b;
	width: 100%;
`;

const ScInfo = styled.div`
	background: rgba(169, 169, 169, 0.1);
	border: 1px solid #a9a9a9;
	padding: 8px;
	margin-top: -1px;
	font-size: 12px;
	width: 100%;
`;

const ScInput = styled.input<StyledInputProps>`
	background: var(--bg-bright-color);
	border: 1px solid #a9a9a9;
	box-shadow: 0 0 0 rgba(51, 51, 51, 0);
	flex-grow: 1;
	max-width: 100%;
	padding: 8px;
	height: 40px;
	transition: border 0.2s ease;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	/* margin-bottom: 16px; */
	width: 100%;
	color: var(--font-body-color);

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	-moz-appearance: textfield;

	:focus {
		border-color: var(--dark-color);
		box-shadow: 0 0 4px rgba(51, 51, 51, 0.5);
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}

	${(props) =>
		props.placeholderColor &&
		css`
			&::placeholder {
				/* Chrome, Firefox, Opera, Safari 10.1+ */
				color: ${props.placeholderColor};
				opacity: 1; /* Firefox */
			}
		`}

	${(props) =>
		props.isBright &&
		css`
			border-color: #fafafa;
			box-shadow: 0 0 0px rgba(0, 0, 0, 0);
		`}


	${(props) =>
		props.hasError &&
		css`
			border: 1px solid #d0021b;
			margin-bottom: 0px;
		`}

	${(props) =>
		props.type === 'checkbox' &&
		css`
			display: none;
		`}

	${(props) =>
		props.type === 'radio' &&
		css`
			display: none;
		`}

	${(props) =>
		props.leftIcon &&
		css`
			padding-left: 38px;
		`}

	${(props) =>
		props.rightIcon &&
		css`
			padding-right: 38px;
		`}
`;

const ScIconContainer = styled.div<ScIconContainerProps>`
	position: absolute;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;

	${(props) =>
		props.leftIcon &&
		css`
			left: 16px;
		`}

	${(props) =>
		props.rightIcon &&
		css`
			right: 16px;
		`}
`;
