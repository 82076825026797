import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { determineCapturePositionHelper } from './Settings/BlockImageSettings';
import TextEditor from '../../../../containers/TextEditor/TextEditor';
import BlockEmpty from '../BlockEmpty/BlockEmpty';
import { updateTextContent } from '../../../../store/actions/action-builder';
// import { isTyping } from '../../../../containers/Builder/helpers/TextEditorUtils';

const BlockImage = (props) => {
	// declare redux dispatcher
	const dispatch = useDispatch();

	const hasCaption = props.data.has_caption;
	const imageType = props.data.image_type;
	const linkType = props.data.link_type;

	// poster or inline prefixed
	const imageTypeClass = `--${imageType}`;

	// figure out a valid position for the type
	const contentPosition = determineCapturePositionHelper(
		imageType,
		props.data.content_position
	);

	// (inline specifik) above below prefiex
	const imageContentPositionClass = `--position-${contentPosition}`;

	const imageThemeClass = `--theme-${props.data.theme}`;

	const classes = [
		imageTypeClass,
		imageContentPositionClass,
		imageThemeClass
	];

	// TODO: media & media_mobile are the hardcoded prop names for now until back-end makes variants dinamic
	let imageSrc;

	switch(props.viewInUse) {
		case 'desktop':
			imageSrc = props.data.media ? props.data.media.src : null;
			break;

		default:
			imageSrc = props.data.mobile_media
				? props.data.mobile_media.src
				: null;
	}

	/**
	 * Handles TextEditor content for specifik block
	 * @To-Do: Check if ordinary block content fn can be used instead
	 *
	 * @param {*} content
	 * @param {*} block
	 * @param {*} valueProp
	 */
	const setTextEditorContentHandler = useCallback(
		(content, block, valueProp = 'contents') => {
			// isTyping(false);
			dispatch(updateTextContent(content, block, valueProp));
		},
		[dispatch]
	);

	return (
		<div className="cms-block cms-blockImage">
			{!imageSrc ? (
				<BlockEmpty
					icon={['fal', 'image']}
					title="Här Var det tomt..."
					description="Tryck på kugghjulet för att välja en bild..."
				>
				</BlockEmpty>
			) : (
				<figure className={classes.join(' ')}>
					<img
						src={imageSrc}
						alt={props.alt_text}
					/>
					{(hasCaption || props.data.link_type === 'button') && (
						<figcaption>
							{hasCaption && (
								<TextEditor
									setTextEditorContent={
										setTextEditorContentHandler
									}
									block={props.id}
									valueProp="caption"
									// isTyping={isTyping}
									initialText={props.data.caption}
									exclude={['aligns', 'anchor']}
								/>
							)}
							{imageType === 'poster' &&
								linkType === 'button' && (
									<button className="imageBtn">
										{props.data.link.title}
									</button>
							)}
						</figcaption>
					)}
				</figure>
			)}
		</div>
	);
};

export default BlockImage;
